<template>
  <van-button
    class="com-button"
    :type="type"
    :loading="loading.loading"
    :disabled="disabled"
  >
    <slot />
  </van-button>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { ButtonType } from 'vant'
import { useDelayLoading } from '@/utils'

export default defineComponent({
  name: 'Button',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String as PropType<ButtonType>,
      default: 'primary',
    },
  },
  setup(props) {
    const loading = useDelayLoading(500)
    watch(() => props.loading, loading.set)

    return {
      loading,
    }
  },
})
</script>

<style lang="less" scoped>
.com-button {
  &.van-button--disabled {
    background: #ccc;
    color: white;
    opacity: unset;
    border-color: #ccc;
  }
}
</style>
