<template>
  <div class="goods-swipe">
    <!-- 加载/无图 占位 -->
    <div class="goods-swipe__item" v-if="!list.length">
      <div class="goods-swipe__placeholder" v-if="!loading">该商品暂无图片</div>
    </div>
    <div
      v-if="list.length"
      class="flex"
    >
      <van-swipe
      class="van-swipe"
      ref="el"
      @change="onSwipeChange"
      :key="listtype"
      vertical
      lazy-render
    >
      <van-swipe-item v-for="({ msrc }, index) in list" :key="msrc">
        <div class="goods-swipe__item" @click="openPhotoSwipe(index)">
          <Image
            :src="msrc"
            @dragstart="preventDefault"
            :onLoad="() => updateRect(msrc)"
          />
        </div>

      </van-swipe-item>

      <template #indicator="{ active }">
        <div class="goods-swipe__index goods-swipe__indicator">
          {{ active + 1 }}/{{ list.length }}
        </div>
      </template>
    </van-swipe>
      <div class="img-indicator app-scroll-style">
        <div class="item" v-for="({ msrc }, index) in list" :key="msrc" @click='onswipeTo(index)'>
          <Image
            :class="{'active': swipeIndex === index }"
            :src="msrc"
            :onLoad="() => updateRect(msrc)"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- SKU 选择器占位 -->
  <div
    class="goods-swipe__colors-placeholder"
    v-if="loading && !sku.length"
  ></div>

  <slot></slot>

</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
} from 'vue'
import BetterScroll from '@better-scroll/core'
import { BScrollConstructor } from '@better-scroll/core/dist/types/BScroll'

import Image from '@/components/Image'
import { usePhotoSwipe } from '@/components/PhotoSwipe'

import { device, user, userWatermark } from '@/store'
import { ossResize } from '@/utils'

function preventDefault(e: Event) {
  e.preventDefault()
}

export default defineComponent({
  components: {
    Image,
  },
  props: {
    type: {
      type: String as PropType<'现货' | '期货'>,
      default: '现货',
    },

    data: {
      type: Object as PropType<{
        goods_images?: string[]
        sku_image?: {
          goods_art_no: string
          color: string
          url: string
        }[]
        futures?: {
          skus: {
            color: string
            url: string
          }[]
        }
        video?: string

        bros?: {
          id: number
          color: string
          image_url: string
        }[]
      } | null>,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const el = ref()

    /* 视频 */
    const videoRef = ref<HTMLVideoElement>()

    const video = reactive({
      show: false,
      currentTime: 0,
      open() {
        if (!props.data?.video) return

        video.show = true
        // 恢复播放位置
        nextTick(() => {
          const el = videoRef.value
          if (!el) return

          el.currentTime = video.currentTime
          /**
           * 通过直接设置 cuurentTime 在 Safari 中初次播放时是无效的
           * loadeddata 当第一帧完成加载时触发该事件
           */
          el.addEventListener(
            'loadeddata',
            () => {
              el.currentTime = video.currentTime
            },
            { once: true }
          )

          el.addEventListener('ended', handleEnded)
        })
      },
      close() {
        // 记录播放位置
        if (videoRef.value) {
          video.currentTime = videoRef.value.currentTime ?? 0
        }

        video.show = false
      },
    })

    /* swipe 数据 */
    const sku = computed(() => {
      return props.data?.bros ?? []
    })
    const skuImages = computed(() => {
      return sku.value.map((v) => {
        const url = v.image_url
        return {
          src: ossResize(
            url,
            {},
            {
              watermark: {
                text: userWatermark.value,
              },
            }
          ),
          msrc: ossResize(
            url,
            {
              m: 'fill',
              w: 750,
              h: 750,
            },
            {
              watermark: {
                text: userWatermark.value,
              },
            }
          ),
        }
      })
    })
    const defaultImages = computed(() =>
      (props.data?.goods_images ?? []).map((url) => {
        return {
          src: ossResize(
            url,
            {},
            {
              watermark: {
                text: userWatermark.value,
              },
            }
          ),
          msrc: ossResize(
            url,
            {
              m: 'fill',
              w: 750,
              h: 750,
            },
            {
              watermark: {
                text: userWatermark.value,
              },
            }
          ),
        }
      })
    )
    sku
    /* swipe 图片 url 列表 */
    const listtype = ref<'default' | 'sku'>('default')
    const list = computed(() => {
      switch (listtype.value) {
        case 'sku':
          return skuImages.value
        default:
          return defaultImages.value
      }
    })

    const swipeIndex = ref(0)
    let swipeChanged = false
    function onClickBack() {
      listtype.value = 'default'

      nextTick(() => el.value.swipeTo(0))
    }
    function onSwipeChange(index: number) {
      if (!device.isMobile) {
        swipeChanged = true
        setTimeout(() => {
          swipeChanged = false
        })
      }
      swipeIndex.value =  index
      video.close()
    }
    function onClickColor(index: number) {
      listtype.value = 'sku'

      swipeIndex.value = index
      video.close()
      nextTick(() => el.value.swipeTo(index))
    }

    /* 颜色选择器 */
    const colorsEl = ref<HTMLElement>()
    let colorsBS: BScrollConstructor<{}> | undefined
    watchEffect(() => {
      if (colorsBS) {
        colorsBS?.destroy()
        colorsBS = undefined
      }

      if (!colorsEl.value) return
      colorsBS = new BetterScroll(colorsEl.value!, {
        scrollX: true,
        scrollY: false,
        click: true,
        eventPassthrough: 'horizontal',
      })
    })
    watch(
      () => sku.value.length,
      () => colorsBS?.refresh()
    )
    watch(
      () => [swipeIndex.value, listtype.value],
      ([index, type]) => {
        emit('update:active', {
          type,
          index,
        })
      }
    )

    /* 查看大图 */

    // 记录缩略图的实际尺寸 提供给 photoSwipe 初始化使用
    const iamgeRectMap = new Map<
      string,
      {
        w: number
        h: number
      }
    >()
    function updateRect(src: string) {
      const img = new window.Image()
      img.src = src
      img.onload = () => {
        iamgeRectMap.set(src, {
          w: img.width,
          h: img.height,
        })
      }
    }

    const pswp = usePhotoSwipe()

    // 同步 swipe 的 index
    pswp.useListen('afterChange', () => {
      const currSrc = pswp.instance.value?.currItem.src
      const index = list.value.findIndex(({ src }) => src === currSrc)
      if (~index) {
        swipeIndex.value = index
        el.value.swipeTo(index)
      }
    })

    // 打开查看大图
    function openPhotoSwipe(index: number) {
      if (swipeChanged) {
        swipeChanged = false
        return
      }

      const items = list.value.map(({ src, msrc }) => {
        const rect = iamgeRectMap.get(msrc) ?? {
          w: 750,
          h: 750,
        }

        return {
          src,
          msrc,
          ...rect,
        }
      })

      pswp.open(items, {
        index,
      })
    }

    function openPhoto(
      items: { src: string; msrc?: string; w: number; h: number }[]
    ) {
      pswp.open(items)
    }

    function handleEnded() {
      video.show = false
    }


    function onswipeTo(index) {
     el.value.swipeTo(index)
    }

    return {
      user,
      ...toRefs(props),
      el,
      colorsEl,

      videoRef,
      video,

      listtype,
      list,
      sku,
      onClickColor,
      onClickBack,

      swipeIndex,
      onSwipeChange,

      updateRect,
      openPhotoSwipe,
      openPhoto,

      preventDefault,

      handleEnded,
      onswipeTo
    }
  },
})
</script>

<style lang="less" scoped>
.goods-swipe {
  position: relative;
  width: rem(410);
  height: rem(325);

  .van-swipe {
    height: rem(325);
  }
  .goods-swipe__item {
    width: rem(325);
    height: rem(325);
    position: relative;
    background-color: white;
  }

  .goods-swipe__placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(18);
    color: @c-black-light;
  }

  :deep(.van-swipe-item) {
    .myimage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.goods-swipe__indicator {
  position: absolute;
  bottom: rem(14);

  display: flex;
  align-items: center;
  height: rem(26);
  padding: 0 rem(15);
  flex-shrink: 0;

  border-radius: rem(13);
  font-size: rem(12);
  color: white;
  background-color: rgba(@c-black-heavy, 0.6);
}

.goods-swipe__back {
  left: rem(16);
  cursor: pointer;
}
.goods-swipe__colorname {
  left: 50%;
  transform: translateX(-50%);
}

.goods-swipe__index {
  right: rem(16);
}

.goods-swipe__colors-placeholder {
  height: rem(40);
  margin: rem(16) 0;
}

.goods-swipe__colors-row {
  display: flex;
  align-items: center;
  margin-top: rem(8);
  .goods-swipe__colors-row-button {
    flex-shrink: 0;
    width: rem(50);
    height: rem(50);
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 rem(10) rgba(black, 0.2);
    margin: 0 rem(10);

    .goods-swipe__colors-row-button__icon {
      width: rem(20);
      height: rem(20);
    }

    .goods-swipe__colors-row-button__text {
      font-size: rem(11);
      color: @c-black-heavy;
      white-space: nowrap;
    }
  }
}
.goods-swipe__colors-wrapper {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
  text-align: center;
  cursor: grab;
}
.goods-swipe__colors {
  display: inline-block;
  overflow: hidden;
  padding: rem(8);
  padding-bottom: rem(6);
}
.goods-swipe__color {
  display: inline-block;
  width: rem(40);
  height: rem(40);
  margin: 0 rem(8);
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid rgba(@c-grey-light, 0.15);

  &.active {
    border-color: @c-primary;
  }

  :deep(.myimage) {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    height: 100%;
  }
}

.goods-swipe__video-enter {
  position: absolute;
  left: rem(10);
  bottom: rem(10);

  width: rem(50);
  height: rem(50);
  background-color: rgba(black, 0.3);
  border: rem(1) solid white;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;

  > svg {
    width: rem(12);
    height: rem(18);
    margin-right: rem(-2);
  }
}

.goods-swipe__video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: black;

  .goods-swipe__video-player {
    width: 100%;
    height: 100%;
  }
}

.img-indicator {
  width: rem(75);
  height: rem(325);
  overflow-y: auto;
  margin-left: rem(10);
  overflow-x: hidden;

  .van-image {

    width: rem(65);
    height: rem(65);
    margin-top: rem(10);
    border: 1px solid #fff;
    &:first-child {
      margin-top: 0;
    }
    &.active {
      border-color: #FF7200FF;
    }

  }
}
</style>
