import { AxiosError } from 'axios'
import { Toast } from 'vant'

/**
 * 通用请求错误处理
 */
export function errorProcessor(options: {
  error: Error | null
  toast?: boolean
  throwError?: boolean
}) {
  const { error, toast = true, throwError = true } = options

  if (!error) return

  if (toast && error.message) Toast(error.message)

  if (throwError) throw error
}

/* 超时 */
export class TimeoutError extends Error {}

/* 身份验证错误 */
export class AuthError extends Error {}

export function isAxiosTimeoutError(error: AxiosError) {
  return error.code === 'ECONNABORTED' && error.message.includes('timeout')
}

export function isAxiosCancelError(error: Error) {
  return !!(error as any)?.__CANCEL__
}

/**
 * 判断错误类型是超时
 * @param error
 * @returns {boolean}
 */
export function isTimeoutError(error: Error): error is TimeoutError {
  return error instanceof TimeoutError
}

/**
 * 判断错误类型是超时
 * @param error
 * @returns {boolean}
 */
export function isAuthError(error: Error): error is TimeoutError {
  return error instanceof AuthError
}
