import { http } from './http'
import { IUser } from './types'
import { device, user, user as userStore } from '@/store'
import * as persistedstate from '@/utils/persistedstate'

export async function login(params: {
  username: string
  password: string
  type: string
}) {
  const dingParams: {
    device?: string
    code?: string
  } = {
    device: 'hdb',
  }

  return http.post<any>('/auth/login', {
    ...dingParams,
    ...params,
  })
}

export function logout() {
  return new Promise((resolve, reject) => {
    const _token = user.token ? 'Bearer ' + user.token : undefined
    setTimeout(() => {
      persistedstate.queue.push(() => {
        return http
          .post<void>('/auth/logout', undefined, {
            loading: false,
            headers: {
              Authorization: _token,
            },
          })
          .then(resolve, reject)
      })
      persistedstate.runQueue()
    })
  })
}

export function getLoginUser() {
  return http.get<any>('/auth/user')
}

/* 发送验证码 */
export function sendCode(params: any) {
  return http.post('/auth/send_code', params)
}

/* 忘记密码 身份验证 */
export function codeSubmit(params: any) {
  return http.post('/auth/forget_step_one', params)
}

/* 忘记密码 确认修改 */
export function submitEdit(params: any) {
  return http.post('/auth/forget_step_two', params)
}

/* 修改密码 */
export interface IApiEditPassword {
  token: string
  user: IUser
}

export function editPassword(params: {
  password: string
  new_password: string
  re_password: string
}) {
  return http.post<IApiEditPassword>('/my/edit_pwd', params).then((res) => {
    const { data } = res
    if (data) {
      userStore.setToken(data.token)
    }
    return res
  })
}

/* 修改姓名 */
export function editRealName(params: any) {
  return http.post('/auth/edit_real_name', params)
}

/* 第一次登录修改密码 */
export function firstEdit(params: any) {
  return http.post('/auth/first_edit_password', params)
}

/* 获取用户权限菜单 */
export interface IApiMenus {
  hdb_menu: {
    id: number
    key: string
    name: string
    status: 0 | 1
    _child?: {
      id: number
      key: string
      name: string
      status: 0 | 1
      _child?: {
        id: number
        key: string
        name: string
        status: 0 | 1
      }[]
    }[]
  }[]
}
export function getMenus() {
  return http.get<IApiMenus>('/backend/basic/get_current_menu')
}

/* 用户中心 */
export function getIndex() {
  return http.get<any>('/my/index').then((res) => {
    if (res.data && res.data.user.agent_user?.id) {
      res.data.user.phone = res.data.user.agent_user.phone
      res.data.user.real_name = res.data.user.agent_user.real_name
      res.data.user.company_name = res.data.user.agent_user.brand_company_name
      res.data.user.company_count = res.data.user.agent_user.company_count
    }

    return res
  })
}

/* 用户中心 - 订货会、门店信息 */
export type IParamsFairInfo = {
  fair_id: number
  shop_id: number
}
export type IApiFairInfo = {
  target_order_amount: number
  buy_order_amount: number
  target_order_money: number
  buy_order_money: number
  fair?: {
    id: number
    fair_name: string
  }
  shop?: {
    id: number
    name: string
  }
  is_review: number,
  is_white: boolean
}
export function getFairInfo(params: IParamsFairInfo) {
  return http.get<IApiFairInfo>('/fair/my/fair_info', {
    params,
  })
}

/* 选择组织列表 */
export function getAccountCompany() {
  return http.get('/backend/account/get_account_company').then((res) => {
    if (!res.error && !res.data) {
      res.data = []
    }
    return res
  })
}

/* 选择所属的组织 */
export function selectCompany(params: any) {
  return http.post('/backend/account/select_company', params)
}

/* 选择订货会列表 */
export type IMeeting = {
  id: number
  fair_name: string
  fair_status: 0 | 1 | 2 // 0未开始 1进行中 2已结束
}
export type IApiSelectMeetingList = IMeeting[]
export function selectMeetingList() {
  return http.get<IApiSelectMeetingList>('/fair/chose/fair/list')
}

/* 选择订货会校验 */
export type IParamsSelectMeeting = {
  fair_id: number
}
export function selectMeeting(params: IParamsSelectMeeting) {
  return http.post('/fair/chose/fair', params)
}

/* 选择门店列表 */
export type IShop = {
  id: number
  name: string
  code: string
  address: string
  linkman: string
  linkphone: string
  $info_complete: boolean
}
export type IShopOrigin = {
  id: number
  name: string
  code: string
  company_shop_address: {
    address: string
    linkman: string
    linkphone: string
  }
}
export type IApiSelectShopList = IShop[]
export function selectShopList(params: { fair_id: number }) {
  return http
    .get<IApiSelectShopList>('/fair/chose/shop/list', {
      params,
    })
    .then((res) => {
      res?.data?.forEach((item: any) => {
        Object.keys(item.company_shop_address ?? {})?.forEach((key) => {
          item[key] = item.company_shop_address[key]
        })

        const { linkphone, address, linkman } = item
        item.$info_complete = Boolean(linkphone && address && linkman)
      })

      return res
    })
}

/* 选择订货会校验 */
export type IParamsSelectShop = {
  shop_id: number
}
export function selectShop(params: IParamsSelectShop) {
  return http.post('/fair/chose/shop', params)
}

/* 手机号登录 */
export type IParamsLoginByPhone = {
  phone: string
  fair_id?: number
}
export type IApiLoginByPhone = {
  token: string
  is_set_password: string
  is_need_select_company: string
  is_real_name: string
}
export function loginByPhone(params: IParamsLoginByPhone) {
  return http.post('/fair/login/mobile', params)
}
/* 获取期货下单协议 */
export function getFuturesHelp() {
  return http.get('/my/get_futures_order_help_rticle')
}

/* 确认协议 */
export function readAgreement() {
  return http.get('/my/read_agreement')
}

export type ReviewAnalysis = {
  fair_id: number
  shop_id: string
  groups?: string[]
}
//获取分析属性
export function getReviewAnalysis(params: ReviewAnalysis) {
  return http.post('/fair/review/analysis', params)
}


//获取订单审核状态
export function getOrderStatus(params) {
  return http.get('/fair/my/verify/status', {
    params,
  })
}

//获取订单商品作废通知
export function getCleanGoodsNotice(params) {
  return http.get('/fair/index/get_clean_goods_notice', {
    params,
  })
}
