<template>
  <svg class="icon">
    <use :xlink:href="`#${prefix(use)}`" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue'
import * as icons from './icons'

const PREFIX = 'hqt-'

export function prefix(id: string) {
  return `${PREFIX}${id}`
}

export default defineComponent({
  name: 'Icon',
  props: {
    use: {
      type: String as PropType<keyof typeof icons>,
      required: true,
    },
  },
  setup(props) {
    return {
      ...toRefs(props),
      prefix,
    }
  },
})
</script>

<style lang="less" scoped>
.icon {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}
</style>
