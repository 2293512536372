<template>
  <div class="goods-info">
    <div class="info flex-item mar-lef-10" v-if="skuData.colorActive >= 0">
      <div class="price-main flex between">
        <div class="price">
          <Prices
            class="goods-card__prices"
            :value="goodsActive.real_price"
            size="goods-futures"
            bold
            color="inherit"
            rangeStyle="min"
          />
          <span class="data_list_price" v-if="goodsActive.list_price">
                    原价<Prices
            :value="goodsActive.list_price"
            size="m"
            color="inherit"
            rangeStyle="min"
          />
                  </span>
          <span class=" data_list_price" v-if="goodsActive.retail_price">
                    吊牌价<Prices
            :value="goodsActive.retail_price"
            size="m"
            color="inherit"
            rangeStyle="min"
          />
                  </span>

        </div>
        <div class="sort" v-if="goodsActive.goods_son_class  && goodsActive.sort">
          <div class="flex left">{{goodsActive.goods_son_class}}排行：{{ goodsActive.sort}}</div>
        </div>
      </div>
      <div class="goods_title chaochu">
          {{goodsActive.goods_title}}
          <span class="tag">{{goodsActive.tag}}</span>
      </div>
      <div class="goods_imgae app-scroll-style">
        <div class='scroll  flex left'>
          <div class="item"
               :class="key == skuData.colorActive ? 'active' : ''"
               v-for="(item,key) in skuData.data?.list" :key='key'
               @click="toggle(key)"
          >
            <Image
              :src="getOssImgUrl(item.image_url)"
            />
              <div class="chaochu c-333">{{item.color}}-{{item.inside}}</div>
              <div class="chaochu c-333">{{item.goods_art_no}}</div>
          </div>
        </div>
      </div>

      <div class="goods-min_num flex left" >
        <div v-if='goodsActive.min_order_nums > 0' style='margin-right: 10px'>
          <van-icon name="info-o" /> 该颜色您所有门店订货数至少满{{goodsActive.min_order_nums}}件<span   v-if="skuData.goods[skuData.colorActive][skuData.inileActiveIndex].is_multiply">，且为{{ skuData.goods[skuData.colorActive][skuData.inileActiveIndex].min_order_nums }}的倍数。</span>

        </div>

        <div class='count c-666'  v-if='skuData.data.is_all_rank'>
          全国已订 {{ skuData.goods[skuData.colorActive][skuData.inileActiveIndex].all_order_count }}
        </div>
      </div>

      <div class=" flex between">
        <di class="store">
          <div class="chaochu">
            <div class="flex left"><img src="@/assets/images/dianpu.svg">{{ skuData.data.shop_name }}</div>
          </div>
        </di>
        <div class="whole flex left">
          <div class="list flex left">
            <div
              class="item"
              @click="whole('reduce')"
              v-log="{ describe: { action: '点击减少一手' } }"
            >
              -
            </div>
            <div class="item item-whole c-333">整手</div>
          </div>
          <div
            class="item"
            @click="whole('add')"
            v-log="{ describe: { action: '点击了加一手' } }"
          >
            +
          </div>
        </div>
      </div>


    </div>
  </div>

</template>


<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
} from 'vue'

import Prices from '@/components/Prices'
import Image from '@/components/Image'

import { device, user, userWatermark } from '@/store'
import { ossResize } from '@/utils'

export default defineComponent({
  components: {
    Image,
    Prices,
  },
  props: {

    goodsData: {
      type: Object,
      default: ()=>{},
    },
    skuData: {
      type: Object,
      default: ()=>{},
    },
    goodsActive: {
      type: Object,
      default: ()=>{},
    },

  },
  setup(props, { emit }) {

    let getOssImgUrl = function(url){
      return  ossResize(
        url,
        { preset: 'mini' },
        {
          watermark: {
            text: userWatermark.value,
            },
         }
        )
      }


    const toggle = function(key){
      emit('toggle',key)
    }
    const whole = function(key){
      emit('whole',key)
    }
    return {
      getOssImgUrl,
      whole,
      toggle,
    }
  },
})
</script>

<style lang="less" scoped>
.goods-info {
  width:100%;
  .price-main {
    background: url('@/assets/images/sku_bg.png');
    padding:0 rem(15);
    color: #fff;
    height: rem(45);
    line-height: rem(45);
    font-size: rem(15);
    margin-right: rem(40);
  }
  .goods_title {
      height: rem(40);
    line-height: rem(40);
    font-size: rem(17);
  }

  .goods_imgae {
    width: rem(550);
    height: rem(152);
    overflow-x: auto;
    overflow-y: hidden;
      .scroll {
        height: rem(152);
        line-height: rem(20);
        text-align: center;
        white-space: nowrap;

      }

      .item {
        width:rem(100);
        height: rem(152);
        box-sizing: border-box;
        overflow: hidden;
        flex: none;
        margin-left: rem(10);
        background: #F2F2F2FF;
        border: 1px solid #fff;

        &:first-child { margin-left: 0; }
        &.active { border-color:@c-primary-heavy;}

        .van-image {
          width:rem(100);
          height:rem(100);
        }
      }
  }

  .goods-min_num {
    color: #FF7200;
    font-size: rem(12);
    line-height: rem(20);
    height: rem(20);
    margin-top: rem(10);
  }


  .store {
    min-width: rem(250);
    border: 1px solid #C3C3C3;
    border-radius: rem(5);
    height: rem(40);
    line-height: rem(40);
    padding: 0 rem(10);
    color: #333;
    margin-top: rem(10);
    font-size: rem(15);
    margin-bottom: rem(5);
    img {
      width:rem(15);
      height:rem(15);
      display: block;
      float: left;
      margin-right: rem(5);
    }
    .sort {
      color: #F55F5F;
    }
  }
    .whole {
      .item {
        color: #333;
        min-width: rem(35);
        height: rem(40);
        line-height: rem(40);
        text-align: center;
        font-size: rem(30);
        margin: 0 rem(3);
        background:@c-primary-heavy;
        color: #fff;
        border-radius: rem(4);
      }

      .item-whole {
        font-size: rem(16);
        padding: 0 rem(10);
        border-radius: rem(4);
        background: #efefef;
        color: #333;
      }
    }

}
</style>
