import { readonly } from 'vue'
import { persistedstate } from '@/utils'

const state = persistedstate(
  'cacheConfig',
  {
    /* 扫码盘点 */
    scanCheckRepeat: true, // 重复追加
    scanCheckContinuous: true, // 连续扫码
    scanCheckRepeatAlert: true, // 重复警告,
    setScanCheck(config: {
      scanCheckRepeat?: boolean
      scanCheckContinuous?: boolean
      scanCheckRepeatAlert?: boolean
    }) {
      state.scanCheckRepeat = config.scanCheckRepeat ?? state.scanCheckRepeat
      state.scanCheckContinuous =
        config.scanCheckContinuous ?? state.scanCheckContinuous
      state.scanCheckRepeatAlert =
        config.scanCheckRepeatAlert ?? state.scanCheckRepeatAlert
    },
  },
  (state) => {
    const { scanCheckRepeat, scanCheckContinuous, scanCheckRepeatAlert } = state
    return { scanCheckRepeat, scanCheckContinuous, scanCheckRepeatAlert }
  }
)

export const cacheConfig = readonly(state)
