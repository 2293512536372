import { http, IAxiosRequestConfig } from './http'

/* 商品列表 */
export type IParamsGoodsList = {
  fair_id: number
  shop_id: number
  sort_by?:
  | 'sort_by_index_asc'
  | 'sort_by_index_desc'
  | 'sort_by_order_count_asc'
  | 'sort_by_order_count_desc'
  | 'sort_by_order_money_asc'
  | 'sort_by_order_money_desc'
  is_order?: '1' | '2'
  groups?: string
  [key: string]: any
}
export type IGoodsListItem = {
  id: number
  goods_number: string
  goods_art_no: string
  goods_title: string
  color: string
  color_code: string
  image_url: string
  tag?: string
  min_order_nums: number
  is_multiply: number
  has_order_count: number | string
  goods_index: number

  trade_price?: string | string[]
  retail_price?: string | string[]
  fixed_price?: string | string[]
  real_price?: string | string[]
  list_price?: string | string[]

  band?: string
}
export type IApiGoodsList = {
  list: IGoodsListItem[]
  page: {
    current: number
  }
  metal?: {
    order_amount: string
    goods_count: string
    order_money: string
    total_order_money: string
    percent: string
  }
}
export function goodsList(
  params: IParamsGoodsList,
  options: IAxiosRequestConfig = {}
) {
  return http.post<IApiGoodsList>('/fair/goods/list', params, options)
}

/* 商品列表 筛选项 */
export type IParamsGoodsListScreen = {
  fair_id: number
  shop_id: number
}
export function getGoodsListScreen(
  params: IParamsGoodsListScreen,
  options?: IAxiosRequestConfig
) {
  return http.get('/fair/goods/screens', { params, ...options })
}

/* 商品分组 */
export type IParamsGoodsGroups = {
  fair_id: number
  shop_id: number
}
export type IApiGoodsGroups = string[]
export function goodsGroups(params: IParamsGoodsGroups) {
  return http.get<IApiGoodsGroups>('/fair/goods/groups', { params })
}

/* 商品分区 */
export type IParamsGoodsZones = {
  fair_id: number
  shop_id: number
}
export type IApiGoodsZones = string[]
export function goodsZones(params: IParamsGoodsZones) {
  return http.get<IApiGoodsZones>('/fair/goods/zones', { params })
}

/* 商品详情 */
export type IParamsGoodsDetail = {
  fair_id: number
  shop_id: number
  fair_goods_id: string
}
export type IApiGoodsDetail = {
  id: number
  goods_title: string
  goods_number: string
  goods_images: string[]

  colors: string
  sizes: string
  attributes: {
    top_class: string
    mid_class: string
    bottom_class: string
    groups: string
    band: string
    tag: string
    year: string
    season: string
    gender: string
    unit: string
    fabric: string
    lining: string
    inside: string
    heel: string
    headform: string
    func: string
    heel_height: string
    sole: string
    insole: string
    closing_mode: string
    style: string
    features: string
    remark: string
    factory_art_no: string
    goods_son_class: string
    new_product_ownership: string
    research_production_unit: string
    supplier_name: string
    tube_height: string
  }

  bros: {
    id: number
    goods_number: string
    goods_art_no: string
    color: string
    color_description: string
    color_code: string
    trade_price: string
    retail_price: string
    fixed_price: string
    real_price: string
    list_price: string
    min_order_nums: null
    is_multiply: number
    has_order_count: number
    image_url: string
  }[]
}
export function goodsDetail(params: IParamsGoodsDetail) {
  return http.get<IApiGoodsDetail>('/fair/goods/detail', { params })
}

//根据货号获取SKU
export function getGoodsSku(params: any) {
  return http.get('/fair/goods/sku', { params })
}

//根据货号获取款号数据
export function getGoodsSkuByNumber(params: any) {
  return http.get('/fair/goods/sku/by_number', { params })
}

/* 样品评审 列表 */
export function reviewList(params: any) {
  return http.get('/fair/review/index', { params })
}

/* 样品评审 提交分数 */
export function addReview(params: any) {
  return http.post('/fair/review/add_review', params)
}

/* 样品评审 详情 */
export function reviewDetail(params: any) {
  return http.get('/fair/review/detail', { params })
}

/* 扫码盘点 列表 */
export function scanCheckList(params: any) {
  return http.get('/fair/inventory/index', { params, loading: false })
}

/* 扫码盘点 详情 */
export function scanCheckDetail(params: any) {
  return http.get('/fair/inventory/detail', { params })
}

/* 扫码盘点 新建 */
export function createScanCheck(params: any) {
  return http.post('/fair/inventory/create', params, {
    loading: false,
  })
}

/* 扫码盘点 删除 */
export function removeScanCheck(params: any) {
  return http.post('/fair/inventory/delete', params)
}

/* 扫码盘点 修改货号 */
export function editScanCheckDetail(params: any) {
  return http.post('/fair/inventory/save_detail', params, {
    loading: false,
  })
}

/* 扫码盘点 删除行 */
export function removeScanCheckDetail(params: any) {
  return http.post('/fair/inventory/delete_detail', params)
}

//根据楦号获取同款
export function getGoodsBySameShoeBlock(params: any) {
  return http.get('/fair/goods/list_by_shoe_block_txt', { params })
}


/* 一键从当前订货会中移除 */
export function delFromFair(params: any) {
  return http.post('/fair/inventory/del_from_fair', params)
}



/* 增量添加到当前订货会 */
export function addToFair(params: any) {
  return http.post('/fair/inventory/add_to_fair', params)
}

//获取AI创款商品
export function getAiGoodsList(params: any) {
  return http.get('/fair/goods/get_ai_goods_list', { params })
}

/* 生成ai图 */
export function createAiGoods(params: any) {
  return http.post('/fair/goods/create_ai_goods_image', params)
}
//ai图详情列表
export function getAiGoodsDetailList(params: any) {
  return http.get('/fair/goods/get_ai_goods_detail_list', { params, loading: false })
}

//ai图详情列表
export function getAiGoodsDetail(params: any) {
  return http.get('/fair/goods/get_ai_goods_detail', { params })
}

/* 评论 */
export function commentAi(params: any) {
  return http.post('/fair/ai_creation/comment', params)
}
//扫码后通过编号获取货号
export function getGoodsArtNo(params: any) {
  return http.get('/fair/goods/get_goods_art_no', { params })
}
