/**
 * oss resize 参数
 * 详情见 https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.6.751.7434663c1Ne07e
 *
 * oss watermark 参数
 * 详情见 https://help.aliyun.com/document_detail/44957.html
 *
 * resize 参数被提到最外层（兼容 + 使用最频繁）
 * 其他（如 watermark）使用对象
 */

import encBase64Url from 'crypto-js/enc-base64url'
import encUtf8 from 'crypto-js/enc-utf8'

const PRESET = {
  mini: {
    m: 'fill',
    w: '120',
    h: '120',
  },
  s: {
    m: 'fill',
    w: '200',
    h: '200',
  },
  m: {
    m: 'fill',
    w: '400',
    h: '400',
  },
  l: {
    m: 'fill',
    w: '750',
    h: '750',
  },
  pad: {
    m: 'pad',
    w: '400',
  },
}

export type IOSSResize =
  | {
      m: 'lfit' | 'mfit' | 'fixed' | 'fixed' | 'fill' | 'pad'
      w?: number
      h?: number
      l?: number
      s?: number
      color?: string
    }
  | {
      preset?: keyof typeof PRESET
      p?: number
    }

export type IOSSOptions = {
  watermark?: IOSSWatermark
  quality?: IOSSQuality
} & {
  [key: string]: object
}

export type IOSSWatermark = {
  t?: number
  g?: 'nw' | 'north' | 'ne' | 'west' | 'center' | 'east' | 'sw' | 'south' | 'se'
  x?: number
  y?: number
  voffset?: number

  // 图片
  image?: string
  P?: number

  // 文字
  text?: string
  type?: string
  size?: number
  shadow?: number
  rotate?: number
  fill?: 0 | 1

  // 混合
  order?: 0 | 1
  align?: 0 | 1 | 2
  interval?: number
} & (
  | {
      image: string
      // text?: string
    }
  | {
      // image?: string
      text: string
    }
)
export type IOSSQuality = {
  q?: number
  Q?: number
}

function createParams(key: string, options: Record<string, any>) {
  const list = [key]
  Object.keys(options).forEach((key) => {
    const value = options[key]
    if (value !== undefined && value !== null) {
      list.push(`${key}_${value}`)
    }
  })

  return list.join(',')
}

export function ossResize(
  url?: string,
  resize: IOSSResize = {},
  options: IOSSOptions = {}
) {
  if (!url) return ''
  try {
    const target = new URL(url)
    const parmas = target.searchParams

    const { watermark, quality, ...otherOptions } = options
    const list = ['image']

    // 尺寸调整
    if ('m' in resize || 'p' in resize) {
      list.push(createParams('resize', resize))
    } else if ('preset' in resize) {
      const _resize = PRESET[resize.preset!]
      if (_resize) {
        list.push(createParams('resize', _resize))
      }
    }

    // 水印
    if (watermark) {
      list.push(
        createParams('watermark', {
          type: 'ZmFuZ3poZW5na2FpdGk',
          t: 10,
          rotate: 20,
          color: '888888',
          fill: 1,
          size: 28,
          ...watermark,
        })
      )
    }

    // 图片质量
    list.push(
      createParams(
        'quality',
        quality ?? {
          Q: 80,
        }
      )
    )

    // 其他
    Object.keys(otherOptions).forEach((key) => {
      list.push(createParams(key, otherOptions[key]))
    })

    parmas.set('x-oss-process', list.join('/'))

    return target.toString()
  } catch (error) {
    console.error(error)
    return url
  }
}

export function createWaterMarkText(text: string) {
  return encBase64Url.stringify(encUtf8.parse(text))
}

export function splitText(text: string) {
  if (!text) return ''
  const reg = /^[\u0000-\u00ff]$/
  const len = text.length
  let result = ''
  let count = 0
  for (let i = 0; i < len; i += 1) {
    const char = text.charAt(i)
    count += reg.test(char) ? 1 : 2

    if (count === 32 && i === len - 1) {
      result += char
      break
    } else if (count > 29) {
      result += '...'
      break
    } else {
      result += char
    }
  }

  return result
}
