<template>
  <Base :props="props.props" v-if="list.length">
    <div class="custom-layout-image">
      <div
        v-for="item in list"
        class="custom-layout-image__item"
        :style="{
          width: `${item.width}%`,
          paddingBottom: `${item.height}%`,
        }"
        @click="navigateTo(item.url)"
      >
        <Image class="custom-layout-image__image" :src="item.src" />
      </div>
    </div>
  </Base>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'

import Image from '@/components/Image'
import Base, { IBaseProps } from './Base.vue'

import { ossResize, useCustomRouter, ICustomRoute } from '@/utils'

export interface IImageProps extends IBaseProps {
  list: {
    image: string
    width: number
    height: number
    weapp_href?: ICustomRoute
  }[]
}

const IMAGE_SIZE_BASE = 1080

const props = defineProps({
  props: {
    type: Object as PropType<IImageProps>,
    default: {},
  },
})

const list = computed(() => {
  const list = props.props?.list ?? []
  let total = 0
  list.forEach(({ width }) => {
    total += width
  })

  return list.map((v) => {
    const width = (100 * v.width) / total
    return {
      url: v.weapp_href,
      width,
      height: (width / v.width) * v.height,
      src: v.image
        ? ossResize(v.image, {
            m: 'mfit',
            w: Math.round((width * IMAGE_SIZE_BASE) / 100),
          })
        : v.image,
    }
  })
})

const customRouter = useCustomRouter()
function navigateTo(to?: ICustomRoute) {
  customRouter.push(to)
}
</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomLayoutImageGroup',
})
</script>

<style lang="less" scoped>
.custom-layout-image {
  display: flex;
  font-size: 0;

  &__item {
    position: relative;
    height: 0;
  }

  &__image {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
