<template>
  <svg
    class="spinner-pull"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="circle"
      :r="r"
      stroke="currentColor"
      cx="11"
      cy="11"
      stroke-width="1.3"
      stroke-linecap="round"
      :stroke-dasharray="strokeDasharray"
      :opacity="1 - opacity"
    />
    <path
      :class="{ rotate: loading }"
      d="M11 1.00002C13.565 1.00002 16.0318 1.98561 17.8906 3.75302C19.7495 5.52043 20.8581 7.93452 20.9873 10.4962C21.1165 13.0579 20.2565 15.5713 18.5849 17.5168C16.9134 19.4623 14.5582 20.6911 12.0063 20.9492C9.4544 21.2073 6.90086 20.475 4.87363 18.9036C2.84639 17.3322 1.50048 15.0419 1.11413 12.5063C0.727778 9.97059 1.33054 7.3834 2.7978 5.27957C4.06626 3.4608 5.89697 2.12351 7.99345 1.46267C8.32202 1.3591 7.66665 3.75302 7.66665 3.75302"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
      :transform="!loading ? `rotate(${rotate}, 11, 11)` : undefined"
      :opacity="opacity"
    />

    <path
      v-show="!loading"
      d="M11.529 8V15.0588"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
    />
    <path
      v-show="!loading"
      d="M8 11.5293C8.96299 12.4923 10.7142 14.2435 11.3181 14.8474C11.4352 14.9645 11.6235 14.9646 11.7406 14.8475C12.3483 14.2398 14.1174 12.4707 15.0588 11.5293"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue'

export default defineComponent({
  name: 'SpinnerPull',
  props: {
    percent: {
      type: Number,
      default: 0.91,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const percent = computed(() => {
      return Math.max(0, props.percent)
    })

    const r = 10
    const perimeter = 2 * Math.PI * r
    const strokeDasharray = computed(() => {
      return `${percent.value * perimeter},${perimeter}`
    })

    const rotate = computed(() => {
      return 17 + 360 * percent.value
    })

    const opacity = computed(() => {
      const start = 0.95
      if (percent.value < start) return 0

      return (percent.value - start) / (1 - start)
    })

    return {
      ...toRefs(props),
      percent,
      r,
      strokeDasharray,

      rotate,
      opacity,
    }
  },
})
</script>

<style lang="less" scoped>
.spinner-pull {
  display: inline-block;
  vertical-align: middle;
  vertical-align: -0.125em;
  height: 1em;
}
.circle {
  transform: rotate(-90deg);
  transform-origin: center;
}

.rotate {
  animation: rotatev 1s infinite linear both;
  transform-origin: center;
}

@keyframes rotatev {
  0% {
    transform: rotate(17deg);
  }
  100% {
    transform: rotate(17deg + 360deg);
  }
}
</style>
