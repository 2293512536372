<template>
  <van-notice-bar
    class="noticebar"
    v-bind="$attrs"
    :text="text"
    wrapable
    :scrollable="false"
  >
    <template #default v-if="$slots.default">
      <slot name="default" />
    </template>

    <template #left-icon>
      <slot name="default" v-if="$slots['left-icon']" />
      <svg
        class="icon"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-else
      >
        <g
          id="待分配"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <path
            d="M8,0.848 C8.992,0.848 9.92266667,1.03733333 10.792,1.416 C11.6613333,1.79466667 12.4186667,2.30666667 13.064,2.952 C13.7093333,3.59733333 14.2213333,4.35733333 14.6,5.232 C14.9786667,6.10666667 15.168,7.04 15.168,8.032 C15.168,9.024 14.9786667,9.95466667 14.6,10.824 C14.2213333,11.6933333 13.7093333,12.4506667 13.064,13.096 C12.4186667,13.7413333 11.6613333,14.2533333 10.792,14.632 C9.92266667,15.0106667 8.992,15.2 8,15.2 C7.008,15.2 6.07466667,15.0106667 5.2,14.632 C4.32533333,14.2533333 3.56533333,13.7413333 2.92,13.096 C2.27466667,12.4506667 1.76266667,11.6933333 1.384,10.824 C1.00533333,9.95466667 0.816,9.024 0.816,8.032 C0.816,7.04 1.00533333,6.10666667 1.384,5.232 C1.76266667,4.35733333 2.27466667,3.59733333 2.92,2.952 C3.56533333,2.30666667 4.32533333,1.79466667 5.2,1.416 C6.07466667,1.03733333 7.008,0.848 8,0.848 Z M8.864,7.04 C8.864,6.784 8.78133333,6.568 8.616,6.392 C8.45066667,6.216 8.24533333,6.128 8,6.128 C7.75466667,6.128 7.54666667,6.216 7.376,6.392 C7.20533333,6.568 7.12,6.784 7.12,7.04 L7.12,12.4 C7.12,12.656 7.20533333,12.8613333 7.376,13.016 C7.54666667,13.1706667 7.75466667,13.248 8,13.248 C8.24533333,13.248 8.45333333,13.168 8.624,13.008 C8.79466667,12.848 8.88,12.64 8.88,12.384 L8.864,7.04 Z M8,5.056 C8.30933333,5.056 8.57333333,4.94666667 8.792,4.728 C9.01066667,4.50933333 9.12,4.24533333 9.12,3.936 C9.12,3.62666667 9.01066667,3.36266667 8.792,3.144 C8.57333333,2.92533333 8.30933333,2.816 8,2.816 C7.69066667,2.816 7.424,2.92533333 7.2,3.144 C6.976,3.36266667 6.864,3.62666667 6.864,3.936 C6.864,4.24533333 6.976,4.50933333 7.2,4.728 C7.424,4.94666667 7.69066667,5.056 8,5.056 Z"
            fill="currentColor"
          ></path>
        </g>
      </svg>
    </template>

    <template #right-icon v-if="$slots['right-icon']">
      <slot name="right-icon" />
    </template>
  </van-notice-bar>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import Icon from '@/components/Icon'

export default defineComponent({
  name: 'NoticeBar',
  components: {
    Icon,
  },
  props: {
    text: String,
  },
  setup(props) {
    return {
      ...toRefs(props),
    }
  },
})
</script>

<style lang="less" scoped>
.noticebar {
  .icon {
    width: rem(15);
    margin-right: rem(8);
  }
}
</style>
