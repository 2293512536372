<template>
  <Skeleton :show="skeleton">
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="banner" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="image-group" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="image-group" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="text" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="banner" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="image-group" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="image-group" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="text" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="banner" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="image-group" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="image-group" />
    </Base>
    <Base
      :props="{ page_padding: true, margin_top: true, margin_bottom: true }"
    >
      <SkeletonItem type="text" />
    </Base>
  </Skeleton>

  <List
    :loading="fetching"
    :error="error"
    :finished="index >= template.length"
    @load="fetchMore"
    @update:error="fetchMore"
    ref="listRef"
  >
    <component
      v-for="item in list"
      :key="item.id"
      :is="componentsMap[item.name]"
      :props="{ ...item.props }"
      :ref="(el) => setChildRef(item.id, el)"
    />
  </List>
</template>

<script lang="ts" setup>
import { computed, DefineComponent, PropType, ref, watch, nextTick } from 'vue'

import List from '@/components/List'
import Base from './components/Base.vue'
import Banner from './components/Banner.vue'
import Hairline from './components/Hairline.vue'
import ImageGroup from './components/ImageGroup.vue'
import GoodsList from './components/GoodsList.vue'
import Live from './components/Live.vue'
import Skeleton from './components/Skeleton.vue'
import ImageArea from './components/ImageArea.vue'
import QuickLink from './components/QuickLink.vue'
import Video from './components/Video.vue'

export interface ICustomLayoutTemplate {
  id: number
  name:
    | 'c-carousel'
    | 'c-nav'
    | 'c-hdbgoods_page'
    | 'c-divider'
    | 'c-live'
    | 'c-hot_zone'
    | 'k-nav'
    | 'c-video'
  text: string
  type: number
  props: {
    page_padding: boolean
    margin_bottom: boolean
    margin_top: boolean
    [key: string]: any
  }
}

/* 存在异步数据的组件 */
const asyncLayout = ['c-hdbgoods_page', 'c-live']

const props = defineProps({
  template: {
    type: Array as PropType<ICustomLayoutTemplate[]>,
    default: [],
  },
  skeleton: {
    type: Boolean,
    default: false,
  },
})

const template = computed(() => {
  return (
    props.template.map((v) => ({
      ...v,
    })) ?? []
  )
})

const componentsMap: Record<
  ICustomLayoutTemplate['name'],
  DefineComponent<any, any, any>
> = {
  'c-carousel': Banner,
  'c-nav': ImageGroup,
  'c-hdbgoods_page': GoodsList,
  'c-divider': Hairline,
  'c-live': Live,
  'c-hot_zone': ImageArea,
  'k-nav': QuickLink,
  'c-video': Video,
}

/* 数据管理 */
const childRef = new Map<number, any>()
function setChildRef(id: number, el: any) {
  childRef.set(id, el)
}

const listRef = ref<any>()

const index = ref(0)
const list = ref<ICustomLayoutTemplate[]>([])

const fetching = ref(false)
const error = ref(false)

function isSame(a: any, b: any) {
  try {
    if (~asyncLayout.indexOf(b?.name)) return false
    return JSON.stringify(a) === JSON.stringify(b)
  } catch (error) {
    return false
  }
}
function init() {
  const newList: ICustomLayoutTemplate[] = []
  for (let i = 0; i < template.value.length; i += 1) {
    const oldChild = list.value[i]
    const newChild = template.value[i]
    if (isSame(oldChild, newChild)) {
      newList.push(oldChild)
    } else {
      break
    }
  }

  newList.forEach((child) => {
    if (child?.name !== 'c-hdbgoods_page') {
      const component = childRef.get(child.id)
      component?.fetch?.()
    }
  })

  childRef.clear()

  index.value = Math.max(newList.length - 1, 0)
  list.value = newList

  fetchMore()
}

watch(() => template.value, init, { immediate: true })

async function fetchMore() {
  fetching.value = true
  try {
    error.value = false
    await _fetchMore()
  } catch (err) {
    error.value = true
  } finally {
    fetching.value = false
  }
}

async function _fetchMore() {
  return new Promise((resolve, reject) => {
    const child = template.value[index.value]
    if (!child) return

    if (!list.value.find((v) => v.id === child.id)) {
      list.value.push(child)
    }

    nextTick(async () => {
      try {
        const component = childRef.get(child.id)

        if (component?.fetch) {
          const { finish } = await component?.fetch()

          index.value += finish ? 1 : 0
        } else {
          index.value += 1
        }

        nextTick(() => {
          resolve({})
          if (index.value < template.value.length) {
            listRef.value?.check?.()
          }
        })
      } catch (err) {
        reject(err)
      }
    })
  })
}
</script>

<script lang="ts">
import { defineComponent } from 'vue'
import SkeletonItem from './components/SkeletonItem.vue'

export default defineComponent({
  name: 'CustomLayout',
  component: {
    SkeletonItem,
  },
})
</script>

<style lang="less" scoped></style>
