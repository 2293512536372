import { createHttp } from './http'

export * from './error'
export * from './types'

export const { http } = createHttp({
  baseURL: '__API__',
})

export const { http: httpPay } = createHttp({
  baseURL: '__API_PAY__',
})
