import { user } from '@/store/user'

const THOUSAND_SPEPARATOR = ','

function thousandSeparator(n: number) {
  const symbol = n < 0 ? '-' : ''
  n = Math.floor(Math.abs(n))
  let count = 0
  let ans = ''
  do {
    let cur = n % 10
    n = Math.floor(n / 10)
    ans += cur.toString()
    ++count
    if (count % 3 == 0 && n) {
      ans += THOUSAND_SPEPARATOR
    }
  } while (n)
  return symbol + ans.split('').reverse().join('')
}

/**
 * 格式化价格
 * @param n 价格
 * @param fractionDigits 保留小数位数
 * @returns 如 123,456.00 的字符串
 */
export function formatPrice(
  n: number | string,
  options?: {
    fractionDigits?: number
    skipAuth?: boolean
    removeTailZero?: boolean
  }
) {
  const { fractionDigits = 2, skipAuth, removeTailZero } = options ?? {}
  if (!skipAuth && !user.userData?.is_see) return '--'

  if (typeof n === 'string') n = Number(n)
  if (Number.isNaN(n)) n = 0

  const intger = thousandSeparator(n)
  if (fractionDigits === 0) return intger
  let fraction = n.toFixed(fractionDigits).slice(-fractionDigits)
  if (removeTailZero) {
    fraction = fraction.replace(/0+$/, '')
  }

  return fraction ? `${intger}.${fraction}` : intger
}

export function formatByLength(
  value: string,
  splitLength: number[],
  separator = ' '
) {
  let last = value
  let res = []
  const _splitLength = splitLength.concat()

  while (last) {
    const length = _splitLength.shift()
    if (length) {
      res.push(last.substr(0, length))
      last = last.substr(length)
    } else {
      res.push(last)
      last = ''
    }
  }

  return res.join(separator)
}
