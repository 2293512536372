<template>
  <div class="index-page">
    <MyHeader hidden-title>
      <router-link
        class="index-search"
        :to="{
          name: 'Search',
        }"
      >
        <div class="seat"></div>
        <Search
          theme="primary"
          placeholder="输入关键词、款号、货号、序号"
          disabled
        />
      </router-link>
    </MyHeader>

    <PullRefresh
      :model-value="!isFirstRefetch && response.loading"
      @refresh="refetch"
      :disabled="response.loading"
    >
      <CustomLayout
        :template="template"
        :skeleton="response.loading && !template?.length"
      />
    </PullRefresh>

    <MyNavbar />
  </div>
  <OrderGoods />
  <ScrollToTop />
</template>

<script lang="ts">
import { user, order } from '@/store'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Home',
  beforeRouteEnter(to, from, next) {
    // Do somethings
    if (order.pass || !user.fairData?.is_white) {
      next({
        name: 'OrderList',
      })
    } else {
      next()
    }
  },
})
</script>


<script lang="ts" setup>
import { Dialog } from 'vant'
import { useRouter } from 'vue-router'
import { useBackgroundColor, user, order } from '@/store'

import * as api from '@/api'

import MyHeader from '@/components/MyHeader'
import MyNavbar from '@/components/MyNavbar'
import Search from '@/components/Search'
import PullRefresh from '@/components/PullRefresh'
import CustomLayout from '@/components/CustomLayout'
import OrderGoods from '@/views/Home/components/OrderGoods.vue'

import ScrollToTop from '@/views/components/ScrollToTop.vue'

const router = useRouter()

/* 页面主要内容 */
const data = ref<any>(null)
const response = api.useQuery(
  () =>
    api.other.layout({
      fair_id: user.selectMeeting!,
      shop_id: user.selectShop!,
      type: 'is_home_page',
    }),
  {
    immediate: false,
    onError(error) {
      if (api.isAuthError(error)) {
        api.errorProcessor({ error })
      } else {
        Dialog.confirm({
          title: '提示',
          message: (error as any)?.message ?? '网络异常',
          cancelButtonText: '返回',
          confirmButtonText: '重新请求',
          closeOnClickOverlay: false,
        })
          .then(() => {
            response.refetch()
          })
          .catch(() => {
            router.back()
          })
      }
    },
  }
)
watch(
  () => response.data,
  () => {
    if (!response.loading) {
      data.value = response.data
    }
  }
)

const template = ref([])
watch(
  () => data.value,
  (data) => {
    template.value = data.template ?? []
  }
)

const isFirstRefetch = ref(true) // 只有首次不使用 PullRefresh 的加载动画
function refetch() {
  response.refetch().finally(() => {
    isFirstRefetch.value = false
  })
}

let lastShowTime = 0
onActivated(async () => {
  const last = lastShowTime
  const now = Date.now()
  const diff = now - last

  lastShowTime = now

  // 距离上次访问首页超过15分钟，重新请求
  if (diff > 15 * 60 * 1000) {
    // 此处两个 nextTick 是为了在路由滚动位置恢复之后再执行
    await nextTick()
    await nextTick()

    window.scrollTo({ top: 0 })
    refetch()
  }
})
onDeactivated(() => {
  lastShowTime = Date.now()
})

/* 设置背景颜色 */
const backgroundColor = computed(() => data.value?.background_color)
useBackgroundColor(backgroundColor)
</script>

<style lang="less" scoped>
.index-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  :deep(.pull-refresh) {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    .van-pull-refresh__track {
      flex-grow: 1;

      display: flex;
      flex-direction: column;

      .com-empty {
        flex-grow: 1;
        justify-content: center;
      }
    }
  }
}

.index-search {
  display: block;
  padding: rem(7) rem(10);
  position: relative;
  .seat {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}
</style>
