import { createApp } from 'vue'
import 'normalize.css'
import './index.less'
import AppSuspense from './AppSuspense.vue'
import router from './router'
import { device } from './store'
import { lissenLog } from '@/utils/log'
import { Lazyload } from 'vant'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import './styles/vant.theme.less'
import '@/utils/weapp'

import smoothscroll from 'smoothscroll-polyfill'

if (!device.isMobile) {
  import('@vant/touch-emulator')
}

const html = document.getElementsByTagName('html')?.[0]
if (device.isAndroid) html?.classList.add('app-android')
if (device.isIos) html?.classList.add('app-ios')
smoothscroll.polyfill()
const app = createApp(AppSuspense)

lissenLog(app)

app.use(router)
// 注册时可以配置额外的选项
app.use(Lazyload, {
  lazyComponent: true,
})
app.mount('#app')
