<template>
  <div :class="['search', `search--${theme}`]">
    <div class="search-left">
      <div class="search-icon">
        <Icon use="search" />
      </div>

      <slot name="left" />
    </div>
    <div class="search-input">
      <form @submit="FormSubmit" action="">
        <Input
          :modelValue="modelValue"
          @update:model-value="onUpdate"
          :placeholder="placeholder"
          @focus="(e) => $emit('focus', e)"
          @blur="(e) => $emit('blur', e)"
          @change="(e) => $emit('change', e)"
          @clear="() => $emit('clear')"
          @keyup.enter="submit"
          :type="type"
          :disabled="disabled"
          :autofocus="autofocus"
          :clearable="clearable"
        />
      </form>
    </div>
    <div class="search-right">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue'
import Input from '../Input'
import Icon from '../Icon'

export type ISeachTheme = 'default' | 'primary'

export default defineComponent({
  name: 'Search',
  components: { Input, Icon },
  props: {
    /* 输入框的值 */
    modelValue: String,

    /* 输入框为空时的内容 */
    placeholder: String,

    /* 主题，可使用的值： default, primary */
    theme: {
      type: String as PropType<ISeachTheme>,
      default: 'default',
    },

    /* 输入框启禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /* 输入框自动聚焦 */
    autofocus: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: 'search',
    },
  },
  emits: ['update:modelValue', 'submit', 'focus', 'blur', 'change', 'clear'],
  setup(props, { emit }) {
    function onUpdate(value: string) {
      emit('update:modelValue', value)
    }

    function submit() {
      emit('submit')
    }

    function FormSubmit(e: any) {
      e.preventDefault()
    }

    return {
      ...toRefs(props),
      onUpdate,
      submit,
      FormSubmit,
    }
  },
})
</script>

<style lang="less" scoped>
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: rem(14);
  line-height: rem(32);
  border-radius: rem(16);
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  overflow: hidden;
  height: rem(32);

  :deep(.van-cell) {
    background-color: transparent;
  }

  &-input {
    flex-grow: 1;
    padding-right: rem(10);
    :deep(.van-cell) {
      font-size: rem(15);
      padding: 0;
    }
  }

  &-icon,
  &-left,
  &-right,
  &-clear {
    flex-shrink: 0;
    flex-grow: 0;
  }

  &-icon {
    width: 0.68rem;
    font-size: rem(14);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-left,
  &-right {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &--default {
    border-color: @c-grey-light;
    color: @c-grey;
  }

  &--primary {
    border-color: @c-primary;
    color: @c-grey;
  }
  &--gray {
    border: none;
    background: #f6f6f6;
    .search-icon {
      color: #bbbbbb;
    }
  }
}
</style>
