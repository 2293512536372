import { Router } from 'vue-router'
import { device } from '@/store'

/**
 * 设置页面标题
 * @param router
 */
export function setTitle(router: Router) {
  router.afterEach((to) => {
    device.setTitle(to.meta.title as string)
  })
}
