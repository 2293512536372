<template>
  <div class="scroll-to-top" @click="scrollToTop" title="回到顶部">
    <ScrollToTopSvg class="scroll-to-top__icon" />
  </div>
</template>

<script lang="ts" setup>
import ScrollToTopSvg from '@/assets/scroll-to-top.svg?component'

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
</script>

<style lang="less" scoped>
.scroll-to-top {
  position: fixed;
  right: rem(12);
  bottom: rem(100);

  cursor: pointer;

  &:active {
    opacity: 0.8;
  }

  .scroll-to-top__icon {
    width: rem(24);
    height: rem(24);
  }
}
</style>
