<template>
  <div class="com-result">
    <template v-if="$slots.icon">
      <slot name="icon"></slot>
    </template>
    <template v-else-if="icon">
      <IconSuccess class="icon" v-if="icon === 'success'" />
      <IconError class="icon" v-else-if="icon === 'failure'" />
      <IconWait class="icon" v-else-if="icon === 'wait'" />
      <img class="icon" :src="icon" v-else />
    </template>
    <img class="icon" :src="icon" v-else-if="icon" />

    <div class="title" v-if="title">{{ title }}</div>
    <div class="description" v-if="description">{{ description }}</div>
    <div class="append" v-if="$slots.default">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'Result',
})
</script>

<script lang="ts" setup>
import IconSuccess from '@/assets/result-success.svg?component'
import IconError from '@/assets/result-error.svg?component'
import IconWait from '@/assets/result-wait.svg?component'

defineProps<{
  icon?: 'success' | 'failure' | 'wait' | string
  title?: string
  description?: string
}>()
</script>

<style lang="less" scoped>
.com-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: rem(20) rem(20);

  .icon {
    width: rem(100);
    height: rem(100);
  }

  .title {
    margin-top: rem(12);

    font-size: rem(15);
    color: @c-black-heavy;
  }
  .description {
    margin-top: rem(10);

    font-size: rem(12);
    color: @c-grey;
  }

  .append {
    margin-top: rem(30);
  }
}
</style>
