import { computed, onUnmounted, readonly, ref, watch } from 'vue'

export interface IUseCountdownOptions {
  format?(value: number): number | string
  interval?: number
}

export function useCountdown(options: IUseCountdownOptions = {}) {
  const { format = (value) => value, interval = 1000 } = options

  const countdown = ref(0)
  const value = computed(() => format(countdown.value))
  const endQueue: Function[] = []

  let timer: number | undefined
  let lastUpdateTime = 0

  watch(
    () => countdown.value,
    (value, oldValue) => {
      if (timer) clearTimeout(timer)
      if (value <= 0) {
        if (oldValue > value) {
          endQueue.forEach((callback) => callback())
        }

        return
      }

      const now = Date.now()
      const delay = Math.min(
        Math.max(lastUpdateTime + interval - now, 0),
        interval
      )

      lastUpdateTime = now + delay
      timer = setTimeout(() => {
        countdown.value = Math.max(countdown.value - interval, 0)
      }, delay)
    }
  )
  onUnmounted(() => {
    if (timer) clearTimeout(timer)
  })

  /**
   * 运行倒计时
   * @param value 倒计时 单位毫秒
   */
  function run(value: number) {
    countdown.value = Math.floor(value)
  }

  /**
   * 停止倒计时
   */
  function stop() {
    if (timer) clearTimeout(timer)
  }

  /* 回调 */

  const onCountdownEnd = (callback: Function) => {
    endQueue.push(callback)
  }

  return readonly({
    value,
    run,
    stop,
    onCountdownEnd,
  })
}
