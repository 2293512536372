import {
  RouteLocationNormalized,
  RouteLocationRaw,
  useRouter,
} from 'vue-router'

export type ICustomRoute =
  | {
      type: 'route'
      raw: RouteLocationNormalized
      url: string
      weapp_url: string
    }
  | string

const HTTP_REGEXP = /^http(s?):\/\//

/**
 * 自定义路由（处理后台定义的跳转信息）
 */
export function useCustomRouter() {
  const router = useRouter()

  function push(to?: ICustomRoute) {
    resolve('push', match(to))
  }

  function replace(to?: ICustomRoute) {
    resolve('replace', match(to))
  }

  function resolve(type: 'push' | 'replace', to: RouteLocationRaw | void) {
    if (!to) return

    if (typeof to === 'string' && HTTP_REGEXP.test(to)) {
      /* 超链接 */
      switch (type) {
        case 'push':
          location.href = to
          break
        case 'replace':
          location.replace(to)
          break
      }
    } else {
      /* 其他 */
      router[type](to)
    }
  }

  /* 匹配路由 */
  function match(to?: ICustomRoute): RouteLocationRaw | void {
    try {
      // 可能为 JSON 字符串
      to = parseJSON(to)

      if (!to) return

      // 兼容旧的小程序路径
      if (typeof to === 'string') {
        return matchByUrl(to, 'weapp')
      }

      switch (to.type) {
        case 'route':
          return (
            matchByName(to) ||
            matchByUrl(to.url, 'browser') ||
            matchByUrl(to.weapp_url, 'weapp') ||
            to.url
          )
      }
    } catch (error) {
      console.error(error)
    }
  }
  function matchByName(to: ICustomRoute) {
    const raw = (to as any).raw

    if (raw.name && router.hasRoute(raw.name)) {
      return raw
    }
  }
  function matchByUrl(to: string, type: 'browser' | 'weapp') {
    // 不匹配外链
    if (HTTP_REGEXP.test(to)) return

    try {
      // 补充成完整的 url 用于 new URL
      const url = `http://a.com${to.charAt(0) === '/' ? '' : '/'}${to}`

      const proxyUrl = new URL(url)
      const path = proxyUrl.pathname.replace(/^\//, '')

      const find = router.getRoutes().find((v) => {
        switch (type) {
          case 'browser':
            return path === v.path
          case 'weapp':
            return path === v.meta?.weappPath
        }
      })

      const query: Record<string, any> = {}
      proxyUrl.searchParams.forEach((value, key) => {
        query[key] = value
      })

      if (find) {
        return {
          name: find.name,
          query,
        }
      }
    } catch (error) {}
  }

  return {
    push,
    replace,
    match,
  }
}

function parseJSON(value: any) {
  try {
    return JSON.parse(value)
  } catch (error) {
    return value
  }
}
