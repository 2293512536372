import WeappOnly from '@/views/Home/WeappOnly.vue'

export default [
  {
    path: '/live',
    name: 'Live',
    component: () => import('@/views/Live/Index.vue'),
    meta: {
      title: '直播中心',
      weapp: '/pages/online/index',
    },
  },
  {
    path: '/live-player',
    name: 'LivePlayer',
    component: () => import('@/views/Live/LivePlayer.vue'),
    meta: {
      title: '直播',
      weapp: '/pages/online/online/index',
    },
  },
  {
    path: '/live-replay',
    name: 'LiveReplay',
    component: () => import('@/views/Live/LiveReplay.vue'),
    meta: {
      title: '回放',
      weapp: '/pages/online/onlinereplay/index',
    },
  },
  {
    path: '/live-status',
    name: 'LiveStatus',
    component: () => import('@/views/Live/LiveStatus.vue'),
    meta: {
      title: '直播',
    },
  },
]
