import {  persistedstate } from '../utils'

const state = persistedstate(
  'shortKey',
  {
    shortKeyData: [],
    setshortKeyData(value) {

      let data = Array.isArray(state.shortKeyData) ? state.shortKeyData : []
      let index = data.indexOf(value)
      if(index >= 0 ){
        data.unshift(value)
        data.splice(index+1,1)
      }else{
        data.unshift(value)
        if( data.length >=4){
          data.splice(data.length-1,1)
        }
      }
      state.shortKeyData =  data;
    },
    //门店数据
    targetShopId: "",
    setTargetShopId(value?: string) {
      state.targetShopId = value
    },
    //内里提示
    showInsidetips: true,
    setShowInsidetips(value?: Boolean) {
      state.showInsidetips = value
    },

  },
  (state) => {
    const {
      shortKeyData,
      targetShopId,
      showInsidetips,
    } = state
    return {
      shortKeyData:shortKeyData,
      targetShopId:targetShopId,
      showInsidetips:showInsidetips,
    }
  }
)


export const shortKey = readonly(state)
