<template>
  <div class="goods-info">
    <div class="title">商品信息</div>

    <div class="table clearfix">
      <div class="td f-left" v-for="item,index in moreInfos" :key="index">
        <div class="label">{{item.label}}</div>
        <div class="value"><div class="chaochu">{{item.value}}</div></div>
      </div>

    </div>
  </div>

</template>


<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue'

import Prices from '@/components/Prices'
import Image from '@/components/Image'


export default defineComponent({
  components: {
    Image,
    Prices,
  },
  props: {

    data: {
      type: Object,
      default: ()=>{},
    },

  },
  setup(props, { emit }) {

    const moreInfos = computed(() => {
      const attrs = props.data?.attributes
      return [
        {
          label: '分类',
          value: [attrs?.top_class, attrs?.mid_class, attrs?.bottom_class]
            .filter((v) => !!v)
            .join('/'),
        },
        {
          label: '商品子类',
          value: attrs?.goods_son_class,
        },
        {
          label: '分组',
          value: attrs?.groups,
        },
        {
          label: '波段',
          value: attrs?.band,
        },
        {
          label: '标签',
          value: attrs?.tag,
        },
        {
          label: '年份',
          value: attrs?.year,
        },
        {
          label: '季节',
          value: attrs?.season,
        },
        {
          label: '适合性别',
          value: attrs?.gender,
        },
        {
          label: '单位',
          value: attrs?.unit,
        },
        {
          label: '工厂货号',
          value: attrs?.factory_art_no,
        },
        {
          label: '面料',
          value: attrs?.fabric,
        },
        {
          label: '里料',
          value: attrs?.lining,
        },
        {
          label: '内里',
          value: attrs?.inside,
        },
        {
          label: '跟型',
          value: attrs?.heel,
        },
        {
          label: '跟高',
          value: attrs?.heel_height,
        },
        {
          label: '筒高',
          value: attrs?.tube_height,
        },
        {
          label: '头型',
          value: attrs?.headform,
        },
        {
          label: '功能',
          value: attrs?.func,
        },
        {
          label: '鞋底材质',
          value: attrs?.sole,
        },
        {
          label: '鞋垫材质',
          value: attrs?.insole,
        },
        {
          label: '闭合方式',
          value: attrs?.closing_mode,
        },
        {
          label: '风格',
          value: attrs?.style,
        },
        {
          label: '商品特征',
          value: attrs?.features,
        },
        {
          label: '供应商',
          value: attrs?.supplier_name,
        },
        {
          label: '研采单位',
          value: attrs?.research_production_unit,
        },
        {
          label: '新品归属',
          value: attrs?.new_product_ownership,
        },
        {
          label: '备注',
          value: attrs?.remark,
        },
      ].filter((v) => !!v.value)
    })

    return {
      moreInfos,
    }
  },
})
</script>

<style lang="less" scoped>
@border: 1px solid #eeeeee;; // 边框
.goods-info {
  .title { font-size: rem(16); font-weight: bold;}
  .table { margin-top: rem(10)}
  .td {
    width: rem(320);
    height: rem(30);
    line-height: rem(30);
    box-sizing: border-box;
    border-bottom:@border;
    .label { width: rem(120);
      padding-left: rem(15);
      height: rem(30);
      line-height: rem(30);
      overflow: hidden;
      display: inline-block;  border-left:@border; box-sizing: border-box;}
    .value {
      width: rem(200);
      height: rem(30);
      line-height: rem(30);
      overflow: hidden;
      padding-left: rem(15);
      display: inline-block;
      border-left:@border;
      box-sizing: border-box;
    }
    &:last-child  .value{ border-right:@border;}
    &:nth-child(3n)  .value{ border-right:@border;}

    &:nth-child(1) { border-top:@border;}
    &:nth-child(2) { border-top:@border;}
    &:nth-child(3) { border-top:@border;}

  }

}
</style>
