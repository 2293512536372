<template>
  <teleport to="#svg-register">
    <svg style="display: none">
      <template v-for="id in ids" :key="id">
        <component :is="id" :id="id" />
      </template>
    </svg>
  </teleport>
</template>

<script lang="ts">
import { Component, defineComponent } from 'vue'
import * as icons from './icons'
import { prefix } from './Icon.vue'

const iconsComponents: Record<string, Component> = {}
for (let id in icons) {
  iconsComponents[prefix(id)] = (icons as Record<string, Component>)[id]
}

export default defineComponent({
  name: 'IconRegister',
  components: iconsComponents,
  setup() {
    const ids = Object.keys(icons) as (keyof typeof icons)[]
    const prefixIds = ids.map((id) => prefix(id))

    return { ids: prefixIds }
  },
})
</script>
