<template>
  <van-loading v-bind="$attrs" color="#fff"  text-color="#fff"   vertical></van-loading>
  <span class="text">请稍候</span>
<!--  <span class="spinner-point" v-bind="$attrs">
    <span class="spinner-point__item"></span>
    <span class="spinner-point__item"></span>
    <span class="spinner-point__item"></span>
  </span>-->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {  Loading } from 'vant'

export default defineComponent({
  name: 'SpinnerPoint',
  components: {
    VanLoading: Loading,
  },
})
</script>

<style lang="less" scoped>
.text { padding-top: rem(15); font-size: rem(14); display: block;}
.spinner-point {
  display: flex;
  justify-content: center;
  align-items: center;
  &__item {
    width: 1em;
    height: 1em;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;

    @delay: -1.4s;
    animation-delay: @delay;

    &:nth-child(1) {
      animation-delay: -0.32s + @delay;
    }
    &:nth-child(2) {
      animation-delay: -0.16s + @delay;
    }
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
