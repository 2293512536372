const state = reactive({
  IMkey: 0,
  IMType: 0,
  pushGoods: [],
  /* 扫一扫 或 PC端订单页添加商品 */
  setPushGoods(value?: string) {
    state.pushGoods = value
  },
  setIMType(value: string | number) {
    state.IMType = value
  },
  updateImKey() {
    if (state.IMkey < Number.MAX_SAFE_INTEGER) {
      state.IMkey += 1
    } else {
      state.IMkey = 0
    }
  },
})

export const live = readonly(state)
