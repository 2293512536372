<template>
  <VanImage
    class="myimage"
    v-bind="attrs"
    ref="el"
    :src="src"
    :alt="alt"
    :fit="fit"
    :lazy-load="lazyLoad"
    @load="onLoad"
  >
    <template #loading>
      <Loading v-if="src" />
      <Placeholder class="placeholder" v-else />
    </template>

    <template #error>
      <slot name="error" v-if="$slots.error" />
      <Placeholder class="placeholder" v-else />
    </template>
  </VanImage>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  onMounted,
  reactive,
} from 'vue'
import { Image as VanImage, Loading } from 'vant'
import Placeholder from '@/assets/image_placeholder.svg?component'
import { IOSSResize, IOSSOptions, ossResize } from '@/utils'

/**
 * 请务必给该组件确定的宽高
 */
export default defineComponent({
  name: 'Image',
  inheritAttrs: false,
  components: {
    VanImage,
    Loading,
    Placeholder,
  },
  props: {
    src: String,
    alt: String,
    fit: {
      type: String as PropType<import('vant/lib/image').ImageFit>,
      default: 'cover',
    },

    /* 懒加载，需配合 Vant 的 LazyLoad 指令 */
    lazyLoad: {
      type: Boolean,
      default: false,
    },

    /**
     * OSS 的图片尺寸
     */
    ossResize: {
      type: Object as PropType<
        IOSSResize & {
          w?: number | 'auto'
          h?: number | 'auto'
        }
      >,
    },

    /**
     * OSS 其他参数
     */
    oss: Object as PropType<IOSSOptions>,

    onLoad: Function,
  },
  setup(props, { attrs }) {
    const el = ref()

    const rect = reactive({
      w: 0,
      h: 0,
    })
    onMounted(() => {
      rect.w = el.value?.$el?.offsetWidth || 100
      rect.h = el.value?.$el?.offsetHeight || 100
    })
    const src = computed(() => {
      if (!rect.w || !rect.h) return

      if (!props.src) return

      if (props.ossResize || props.oss) {
        const options = {
          ...props.ossResize,
        } as NonNullable<typeof props.ossResize>

        if ('w' in options && options.w === 'auto') {
          options.w = Math.floor(rect.w * devicePixelRatio)
        }
        if ('h' in options && options.h === 'auto') {
          options.h = Math.floor(rect.h * devicePixelRatio)
        }

        return ossResize(props.src, options as IOSSResize, props.oss)
      } else {
        return props.src
      }
    })

    function onLoad(e: Event) {
      props.onLoad?.()
    }

    return {
      el,
      ...toRefs(props),
      attrs,
      src,

      onLoad,
    }
  },
})
</script>

<style lang="less" scoped>
.myimage {
  :deep(.van-image__loading) {
    display: block;

    width: 100%;
    height: 100%;

    .van-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  :deep(.van-image__error) {
    display: block;

    width: 100%;
    height: 100%;
  }

  :deep(.van-image__img) {
    pointer-events: none;
  }
}
.placeholder {
  background-color: #efedee;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
