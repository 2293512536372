<template>
  <a class="callme" :href="href" @click="toggleShow" v-bind="attrs">
    <slot name="default"></slot>
  </a>

  <ActionSheet
    v-model:show="show"
    :actions="actions"
    @select="onSelect"
    cancel-text="取消"
    close-on-click-action
    theme="ios"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import ActionSheet from '@/components/ActionSheet'

import { device } from '@/store'

export default defineComponent({
  name: 'CallMe',
  components: {
    ActionSheet,
  },
  props: {
    call: String,
  },
  setup(props, { attrs }) {
    const href = computed(() => {
      return `tel:${props.call}`
    })

    const actions = computed(() => {
      return [{ name: `拨打电话 ${props.call}` }]
    })

    const show = ref(false)
    function toggleShow(e: Event) {
      if (device.isMobile) return
      e.preventDefault()
      show.value = !show.value
    }

    function onSelect() {
      location.replace(href.value)
    }

    return {
      attrs,

      href,
      actions,

      show,
      toggleShow,
      onSelect,
    }
  },
})
</script>

<style lang="less" scoped>
.callme {
  cursor: pointer;
}
</style>
