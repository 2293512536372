export enum LOGIN_PAGE {
  LOGIN = 'login',
  FORGOT = 'forgot',
}

export enum LOGIN_MODE {
  PASSWORD = '0', // 同登录接口 type 密码登录
  SMS = '1', // 同登录接口 type 短信登录
}

export const SMS_TIME_INTERVAL = 60 * 1000
