/**
 * 复制于 vant/src/composables/use-visibility-change.ts
 * 移除了 IntersectionObserver 中的第二个参数
 */
import {
  Ref,
  onDeactivated,
  onBeforeUnmount,
  nextTick,
  onMounted,
  onActivated,
} from 'vue'

// @Experimental
export function useVisibilityChange(
  target: Ref<Element | undefined>,
  onChange: (visible: boolean) => void
) {
  // compatibility: https://caniuse.com/#feat=intersectionobserver
  if (!window?.IntersectionObserver) {
    return
  }

  const observer = new IntersectionObserver((entries) => {
    // visibility changed
    onChange(entries[0].intersectionRatio > 0)
  })

  const observe = () => {
    if (target.value) {
      observer.observe(target.value)
    }
  }

  const unobserve = () => {
    if (target.value) {
      observer.unobserve(target.value)
    }
  }

  onDeactivated(unobserve)
  onBeforeUnmount(unobserve)
  onMountedOrActivated(observe)
}

export function onMountedOrActivated(hook: () => any) {
  let mounted: boolean

  onMounted(() => {
    hook()
    nextTick(() => {
      mounted = true
    })
  })

  onActivated(() => {
    if (mounted) {
      hook()
    }
  })
}
