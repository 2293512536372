import { http } from './http'

export type IParamsLiveStatus = {
  fair_id: number
  shop_id: number
}
export type IApiLiveStatus = {
  id: number
  title: string
  cover: string
  live_status: 0 | 1 | 2 //0：未开始：1：进行中，2：已结束
  live_count: number
}
export function liveStatus(params: IParamsLiveStatus) {
  return http.get<IApiLiveStatus>('/fair/goods/is_living', {
    params,
    loading: false,
  })
}

export type IParamsMyDepartments = {
  fair_id: number
  shop_id: number
}
export type IApiMyDepartments = {
  id: number
  name: string
}[]
export function myDepartments(params: IParamsMyDepartments) {
  return http.get<IApiMyDepartments>('/fair/my_departments', {
    params,
  })
}

export type IParamsHomeBanner = {
  fair_id: number
  shop_id: number
}
export type IApiHomeBanner = {
  image: string
  href: string
}
export function getHomeBanner(params: IParamsHomeBanner) {
  return http.get<IApiHomeBanner[]>('/fair/index/index', {
    params: {
      ...params,
      type: 'banner',
    },
    loading: false,
    cancelRepeatKey: 'banner',
  })
}

export type IParamsHomeZone = {
  fair_id: number
  shop_id: number
}
export type IApiHomeZone = {
  image: string
  title: string
}
export function getHomeZone(params: IParamsHomeZone) {
  return http.get<IApiHomeZone[]>('/fair/index/index', {
    params: {
      ...params,
      type: 'special_zone',
    },
    loading: false,
    cancelRepeatKey: 'zone',
  })
}

export type IParamsHomeLive = {
  fair_id: number
  shop_id: number
}
export function getHomeLive(params: IParamsHomeLive) {
  return http.get<{
    data: {
      live_id: number
      cover: string
      title: string
      status: 0 | 1 | 2
      start_time: string
    }[]
  }>('/fair/index/get_live', {
    params,
    loading: false,
  })
}

export function layout(params: {
  id?: number
  preview?: number
  type?: string
  fair_id: number
  shop_id: number
}) {
  return http.get('/fair/index/home', {
    params,
  })
}
