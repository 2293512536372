<template>
  <div class="goods-keyword">
    <div class="goods-keyword_table">
        <div class="tr thead flex left">
          <div class="th desc">颜色</div>
          <div class="th desc">内里</div>
          <div class="th" v-for="item,index in skuData.data.sizes" :key="index">
              {{item}}
          </div>
          <div class="th desc">小计</div>
        </div>
        <div class="tr  flex left"  :class="key === skuData.colorActive ? 'active' : ''" v-for="(item,key) in skuData.goods" :key='key'>
          <div class="td desc">{{ item[0].color }}</div>
          <div class="td desc">{{ item[0].inside }}</div>
          <div class="td" v-for="items,indexs in skuData.data.sizes" :key="index">

            <div class="input" v-if="item[0].sizesObj.hasOwnProperty(items)">{{ item[0].sizesObj[items].count }}</div>
            <div class="input disabled"  v-else></div>

          </div>
          <div class="td desc">1010</div>
        </div>
    </div>

    <div class="c-666 total flex left">
      <div>
        <span class="c-333">合计:</span>
        <Prices
          :value="skuData.price"
          size="m"
          decimalStyle="all"
        />
      </div>
      <div class="c-999">
        <span>&nbsp共{{ skuData.amount }}件</span>
      </div>
    </div>
  </div>

</template>


<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
} from 'vue'

import Prices from '@/components/Prices'
import Image from '@/components/Image'

import { device, user, userWatermark } from '@/store'
import { ossResize } from '@/utils'

export default defineComponent({
  components: {
    Image,
    Prices,
  },
  props: {

    goodsActive: {
      type: Object,
      default: ()=>{},
    },
    skuData: {
      type: Object,
      default: ()=>{},
    },

  },
  setup(props, { emit }) {

    return {
    }
  },
})
</script>

<style lang="less" scoped>
.goods-keyword {
    .goods-keyword_table {
      .tr {
        height: rem(40);
        border-bottom: 1px solid rgba(216, 216, 216, .2);
        padding: rem(5) 0;
        text-align: center;
      }
      .thead { background: #F2F2F2FF}
      .td,.th {
        width: rem(70);
        text-align: center;
      }
      .th {
        padding: 0 rem(5);
        &.desc {
          padding: 0;

          width:rem(60);
        }
      }
      .td {
        width:rem(70);
        height: rem(38);
        padding: 0 rem(5);
        line-height: rem(38);
        text-align: center;
        background: #FFFFFF;
        .input {

          height: rem(38);
          border: 1px solid #CCCCCC;

          &.disabled {
              background: #F2F2F2FF;
          }
        }
        &.desc {
          border: none;

          padding: 0;
          width:rem(60);
        }
      }
    }
}
</style>
