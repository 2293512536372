import { http, IAxiosRequestConfig } from './http'

//获取列表
export function getOrderList(params: any, loading?: boolean) {
  return http.get('/fair/order/order_list', {
    params: {
      from: 'h5',
      ...params,
    },
    loading,
  })
}

//更新订单
export function updateOrder(params: any) {
  return http.post('/fair/order/update_order', params)
}

//创建订单
export function createOrder(params: any) {
  return http.post('/fair/order/create', params)
}

//创建订单
export function createOrderNew(params: any) {
  return http.post('/fair/order/create_new', params)
}

export type IParamsCopyOrder = {
  fair_id: number
  shop_id: number
  from_shop_id: number
  to_shop_ids: number[]
}
export function copyOrder(params: IParamsCopyOrder) {
  return http.post('/fair/order/copy_order', params)
}

export type IParamsCommitOrder = {
  fair_id: number
  shop_id: number
  is_update: number
}
export function commitOrder(params: IParamsCommitOrder) {
  return http.get('/fair/order/commit_order', { params })
}

//移除订单
export function removeOrder(params) {
  return http.get('/fair/order/remove_order', { params })
}

/* 获取专属账户 */
export function getOffline(params: IParamsCommitOrder) {
  return http.get('/fair/offline_payment/get_offline_account', { params })
}

/* 获取专属账户 */
export function getBankList(params: IParamsCommitOrder) {
  return http.get('/fair/offline_payment/bank_list', { params })
}

//一键订样
export function orderToSamplingOrder(params: any) {
  return http.post('/fair/order/order_to_sampling_order', params)
}

//获取大类指标
export function getTopClassList(params: any) {
  return http.get('/fair/order/top_class_list', { params })
}

//大家都有订列表
export function getReportRank(params: any) {
  return http.get('/fair/report/order_ranking', { params })
}

//获取订货会配置
export function getReportRankConfig(params: any) {
  return http.get('/fair/report/ranking_config', { params })
}
