<template>
  <Base :props="props.props">
    <div
      class="custom-layout-hairline"
      :style="{
        height: `${height}rem`,
      }"
    ></div>
  </Base>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'

import Base, { IBaseProps } from './Base.vue'

export interface IHairlineProps extends IBaseProps {
  height: number
}

const props = defineProps({
  props: {
    type: Object as PropType<IHairlineProps>,
    default: {},
  },
})

const height = computed(() => {
  return (props.props?.height ?? 30) * 0.02
})
</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomLayoutHairline',
})
</script>

<style lang="less" scoped>
.custom-layout-hairline {
}
</style>
