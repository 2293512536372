<template>
  <Base :props="props.props" v-if="response.data?.list?.length">
    <div class="custom-layout-goods-list">
      <div class="custom-layout-goods-list__container">
        <GoodsCard
          class="custom-layout-goods-list__item"
          v-for="item in response.data.list"
          :key="item.id"
          :cover="item.image_url"
          :tag="item.tag"
          :title="item.goods_title"
          :price="item.real_price"
          :listPrice="item.list_price"
          :other="item.band"
          :color="item.color"
          :count="item.has_order_count"
          :number="item.goods_art_no"
          :index="item.goods_index"
          :to="{
            name: 'GoodsDetail',
            query: {
              goods_art_no: item.goods_art_no,
            },
          }"
        />
      </div>
    </div>
  </Base>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import * as api from '@/api'
import { user } from '@/store'

import Base, { IBaseProps } from './Base.vue'
import GoodsCard from '@/views/components/GoodsCard.vue'

import { ossResize } from '@/utils'

export interface IGoodsListProps extends IBaseProps {
  name?: string
  sex?: string
  type?: number
  groups?: string
  season?: string
  top_class?: string
  mid_class?: string
  bottom_class?: string
  special_zone?: string
  series?: string
}

const props = defineProps({
  props: {
    type: Object as PropType<IGoodsListProps>,
    default: {},
  },
})

let current = 0
let finished = false
const response = api.useQuery(
  () => {
    const _props = props.props ?? {}
    return api.goods
      .goodsList(
        {
          keyword: _props.name,
          top_class: _props.top_class,
          mid_class: _props.mid_class,
          bottom_class: _props.bottom_class,
          groups: _props.groups,
          sex: _props.sex,
          season: _props.season,
          special_zone: _props.special_zone,
          series: _props.series,

          fair_id: user.selectMeeting!,
          shop_id: user.selectShop!,
          current: current + 1,
          size: 12,
        },
        {
          loading: false,
        }
      )
      .then((res) => {
        res.data?.list?.forEach((v: any) => {
          v.src = v.image
            ? ossResize(v.image, {
                m: 'fill',
                w: 336,
                h: 336,
              })
            : ''
        })
        return res
      })
  },
  {
    updateQuery(prev, cur) {
      const { page, list } = cur

      current = page.current

      if (!list?.length) {
        finished = true
      }

      if (!prev) return cur

      prev.list = prev.list.concat(list)
      prev.page = page
      return prev
    },
    onError(error) {
      api.errorProcessor({ error })
    },
    immediate: false,
  }
)

defineExpose({
  async fetch() {
    await response.fetchMore()

    return {
      finish: finished,
    }
  },
})
</script>

<script lang="ts">
import { defineComponent } from 'vue'
import { goods } from '@/api'

export default defineComponent({
  name: 'CustomLayoutGoodsList',
})
</script>

<style lang="less" scoped>
@goods-space: rem(10);

.custom-layout-goods-list {
  .custom-layout-goods-list__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: rem(14);
    grid-column-gap: rem(14);

    @media (min-width: 750px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__item {
    overflow: hidden;
  }
}
</style>
