<template>
  <div class="custom-layout-skeleton">
    <Transition
      leave-from-class="fade-leavel-from"
      leave-active-class="fade-leave-active"
      leave-to-class="fade-leave-to"
    >
      <div class="box" v-if="realShow.loading">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, Transition } from 'vue'

import Image from '@/components/Image'

import { useDelayLoading } from '@/utils'

export default defineComponent({
  components: {
    Transition,
    Image,
  },
  props: {
    show: Boolean,
  },
  setup(props) {
    const realShow = useDelayLoading(300)
    watch(() => props.show, realShow.set, {
      immediate: true,
    })

    return {
      realShow,
    }
  },
})
</script>

<style lang="less" scoped>
.custom-layout-skeleton {
  position: relative;
  .box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    background-color: @c-background;
    &.fade-leave-active {
      transition: all 0.3s ease;
    }

    &.fade-leavel-from {
      opacity: 1;
    }

    &.fade-leave-to {
      opacity: 0;
    }
  }
}
</style>
