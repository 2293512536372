import { IAxiosRequestConfig } from './types'
import { device } from '@/store/device'

/* 取消重复请求 */
export class Loading {
  map = new Map<string, true>()
  count = 0

  show(config: IAxiosRequestConfig) {
    if (!config?.loading) return
    this.count += 1
    device.setLoading(true)
  }

  hide(config: IAxiosRequestConfig) {
    if (!config?.loading) return
    this.count -= 1

    setTimeout(() => {
      if (this.count === 0) {
        device.setLoading(false)
      }
    })
  }

  clear() {
    this.map.clear()
    device.setLoading(false)
  }
}

export const loading = new Loading()
