import { Dialog, Toast } from 'vant'
import { http } from '@/api/http'
import { user } from '@/store/user'
import { device } from '@/store/device'
import router from '@/router/index'

import { setLogInfo } from './log'

/* 需要使用的 鉴权 jsapi */
const jsApiList = ['scanQRCode']

const Expiration = 60 * 60 * 1000

let configedTime = 0 // 鉴权时间
let currentConfigPromise: Promise<void> | null = null // 鉴权时的 Promise

function getUrl() {
  const { origin, pathname, search } = location
  return `${origin}${pathname}${search}`
}
const enterUrl = getUrl()

/**
 * 鉴权
 * config().then(() => { 此处调用需要鉴权的 api })
 */
export function config() {
  if (currentConfigPromise) return currentConfigPromise

  currentConfigPromise = new Promise<void>(async (resolve, reject) => {
    if (configedTime + Expiration > Date.now()) return resolve()

    getConfig().then((res) => {
      const data = res.data!

      wx.error(function (res: any) {
        console.log('wx.config:error', res)
        configedTime = 0
        setLogInfo( {
          action: '微信鉴权失败',
          message: res?.errMsg,
          data,
          navigator: navigator.userAgent,
        })
      })

      wx.config({
        debug: __IS_SERVE_DEV__,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,

        // 需要使用的 jsapi 列表
        jsApiList,
      })

      configedTime = Date.now()
      resolve()
    }, reject)
  })

  currentConfigPromise.finally(() => {
    currentConfigPromise = null
  })

  return currentConfigPromise
}

function getConfig() {
  return http.get<{
    appId: string
    timestamp: number
    nonceStr: string
    signature: string
    url: string
  }>('/fair/my/jssdk', {
    params: {
      url: device.isIos ? enterUrl : getUrl(),
      fair_id: user.selectMeeting,
      shop_id: user.selectShop,
      token: user.token,
      jsApiList,
    },
  })
}

export function scanQRCode(options: any) {
  return config().then(() => {
    wx.ready(() => {
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ['qrCode'],
        ...options,
        fail(error: any) {
          console.error('scanQRCode:error', error)

          Dialog({
            title: '扫一扫调起失败,',
            message: '请重新点击扫一扫，错误代码:' + JSON.stringify(error),
          })

          setLogInfo({
            action: '扫一扫调起失败',
            message: error?.errMsg,
          })

          options?.fail?.(error)
        },
      })
    })
  })
}
