<template>
  <teleport to="body">

    <van-popup
      class="sku_popup"
      :show="propsData.show"
      :lock-scroll="true"
      :close-on-popstate="false"
      :closeable="true"
      @close="closeSku"
    >
      <div class="wrap" @click.stop=""  v-if="goodsData.data.id && skuData.data.list?.length >0 ">
        <div class="flex goods-top top">
          <GoodsSwipe ref="swipeRef" :data="goodsData.data" />
           <GoodsArtInfo
              :goodsActive='goodsActive'
              :skuData="skuData"
              @toggle="ontoggle"
              @whole="whole"
            />
        </div>

        <div class="goods-keyword">
          <div class="goods-keyword_table">
            <div class="tr thead flex left">
              <div class="th desc">颜色</div>
              <div class="th desc">内里</div>
              <div class="th" v-for="item,index in skuData.data.sizes" :key="index">
                {{item}}
              </div>
              <div class="th desc">小计</div>
            </div>
            <div class="tr  flex left"  :class="key == skuData.colorActive ? 'active' : ''" v-for="(item,key) in skuData.goods" :key='key'>
              <div class="td desc color"   @click="ontoggle(key)">{{ item[0].color }}</div>
              <div class="td desc inside"   @click="ontoggle(key)">{{ item[0].inside }}</div>
              <div class="td" v-for="items,indexs in skuData.data.sizes" :key="index" >

                <input class="input"
                     :class="{ active: key == skuData.colorActive && (batch || items == skuData.index) }"
                     @click="ontoggle(key,items,)"
                     @change='keyup($event,item,items)'
                     @keyup="keyup($event,item,items  )"
                     :disabled='!skuData.goods[skuData.colorActive][skuData.inileActiveIndex].is_self_order'
                     max="999"
                     min="0"
                     type="number"
                     v-if="item[0].sizesObj.hasOwnProperty(items)"
                     :value="item[0].sizesObj[items].count"
                />
                <input class="input disabled" v-else/>

              </div>
              <div class="td desc">{{ goodsColor[key].count }}</div>
            </div>
          </div>

          <div class="c-333 total flex right goods-keyword_total">
            <div >
              <span>&nbsp共{{ skuData.amount }}件</span>
            </div>
            <div class="mar-lef-10">
              <span class="c-333">合计:</span>
              <Prices
                :value="skuData.price"
                size="inherit"
                color='inherit'
                decimalStyle="all"
              />
            </div>

            <template   v-if='skuData.goods[skuData.colorActive][skuData.inileActiveIndex].is_self_order'>
              <Button type="default" class="mar-lef-10" @click="keyword('empty')">清空</Button>
              <Button type="default" class="mar-lef-10" @click="closeSku">取消下单</Button>
              <Button type="primary" class="mar-lef-10" @click="keyword('save')" > 立即下单</Button>

            </template>
            <template   v-else>
              <Button type="default" disabled class="mar-lef-10" @click="closeSku">不可订</Button>

            </template>
          </div>


        </div>



        <GoodsInfo
          :data="goodsData.data"
        />
      </div>
    </van-popup>



    <van-popup
      class="sku_popup_keyword"
      position="bottom"
      :round="false"
      :overlay="false"
      :lock-scroll="true"
      :close-on-popstate="false"
      :closeable="true"
      @close="closeKeyword"
    >
      <div class="keyword_wrap flex">


        <div class="keyword" >
          <div>
            <div class="num key batch"  @click="onBatch"><div>批量订货</div></div>
            <div class="num" @click="keyword(1)"><div>1</div></div>
            <div class="num" @click="keyword(2)"><div>2</div></div>
            <div class="num" @click="keyword(3)"><div>3</div></div>
            <div class="num" @click="keyword(4)"><div>4</div></div>
            <div class="num" @click="keyword(5)"><div>5</div></div>
            <div class="num key del" @click="keyword('del')"><div>删除</div></div>
          </div>
          <div>
            <div class="num key ten" @click="keyword('10')"><div>+10件</div></div>
            <div class="num" @click="keyword(6)"><div>6</div></div>
            <div class="num" @click="keyword(7)"><div>7</div></div>
            <div class="num" @click="keyword(8)"><div>8</div></div>
            <div class="num" @click="keyword(9)"><div>9</div></div>
            <div class="num zero" @click="keyword(0)"><div>0</div></div>
            <div class="num key save" @click="keyword('save')"><div>立即下单</div></div>
          </div>
        </div>
      </div>

    </van-popup>

  </teleport>
</template>

<script>
import { reactive, ref, watch, computed, nextTick } from 'vue'
import mitt from 'mitt';
import { getGoodsSkuByNumber as getGoodsSku,goodsDetail } from '@/api/goods'
import { createOrderNew as createOrder } from '@/api/order'
import Prices from '@/components/Prices'
import Image from '@/components/Image'
import { user, userWatermark, shortKey,settings,   order as orderStore } from '@/store'
import * as api from '@/api'
import { ossResize,floatAdd } from '@/utils'
import { Dialog, Popup } from 'vant'
import GoodsSwipe from './GoodsSwipe.vue'
import GoodsArtInfo from './GoodsArtInfo.vue'
import GoodsKeyWord from './GoodsKeyWord.vue'
import GoodsInfo from './GoodsInfo.vue'
import Button from '@/components/Button'

// 初始化一个 mitt 实例



export const SKUBUS = mitt();

export default {
  name: 'Sku',
  components: {
    Prices,
    Popup,
    Button,
    GoodsSwipe,
    GoodsArtInfo,
    GoodsKeyWord,
    GoodsInfo,
    Image,
  },
/*  props: {
    show: {
      type: Boolean,
      default: false,
    },
    goodsArtNo: {
      type: String,
      default: '',
    },
  },
  emits: {
    closeSku: null,
    updateOrder: null,
  },*/
  setup(props, { emit }) {
    const goodsArtNo = ref('')
    const propsData = reactive({
      show:false,
      goodsArtNo:"",
      liveId:""
    })

    const skuData = reactive({
      data: {},
      goods:{},
      colorActive:'',
      isshowInside:false,
      showInsidetips:false,
      inileActiveIndex:0,
      amount:0,
      price:0,
      index: -1
    })


    //提交的时候 goods数据
    let saveGoods = []
    //提交的时候 order数据
    let saveOrderData = {}


    const goodsActive = computed(() => {
      if(skuData.colorActive  ===  '')  return  {}
      return  skuData.goods[skuData.colorActive][skuData.inileActiveIndex]
    })


    const goodsColor = computed(() => {
      let colorObj = {}
      let allCount = 0 ;
      let allPrice = 0 ;
      saveGoods = []
      saveOrderData = {}
      Object.keys(skuData.goods).map(color=>{
        let count = 0;
        colorObj[color] = {}
        skuData.goods[color].map((item,index)=>{
          let goods_count = 0

          let params = {
            goods_art_no:item.goods_art_no,
            goods_id:item.goods_id,
            sizes:{}
          }
          Object.keys(item.sizesObj).map(key=>{
            let sizes = item.sizesObj[key]
            count += Number(sizes.count)
            goods_count += Number(sizes.count)
            params.sizes[sizes.goods_column] =  Number(sizes.count)
          })
          saveOrderData[item.goods_id] = JSON.parse(JSON.stringify(item.sizes))
          params.goods_count = goods_count;
          saveGoods.push(params)
          colorObj[color][index] = goods_count
          allPrice = floatAdd(allPrice,goods_count * item.real_price)
        })
        colorObj[color].count = count
        allCount += count;
      })
      skuData.amount = allCount
      skuData.price = allPrice

      return colorObj
    })

    const setOssResize = (src)=>{
      return src
        ? ossResize(
          src,
          {
            preset: 'mini',
          },
          {
            watermark: {
              text: userWatermark.value,
            },
          }
        )
        : ''
    }

    const shortKeyData = computed(() => {
      return shortKey.shortKeyData
    })

    const coverOss = computed(() => {
      return skuData.goods[skuData.colorActive][skuData.inileActiveIndex].image_url
        ? ossResize(
          skuData.goods[skuData.colorActive][skuData.inileActiveIndex].image_url,
          {
            preset: 's',
          },
          {
            watermark: {
              text: userWatermark.value,
            },
          }
        )
        : ''
    })


    // 发开SKU
    SKUBUS.on('openSkuPC', (params) => {
      if(params){

        if(typeof params === 'object'){
          propsData.show = true;
          propsData.goodsArtNo = params.goodsArtNo;
          if(params.liveId){
            propsData.liveId = params.liveId;
          }

        }else{
          propsData.show = true;
          propsData.goodsArtNo = params;
          propsData.liveId = ""
        }
      }
    });


    watch(
      () => propsData.show,
      (data) => {
        if (data ) {
          if(propsData.goodsArtNo) {
            getDetail()
            getGoodsDetail()
          }
        }
      }
    )




    //获取SKU
    const getDetail = async function () {
      const { error, data } = await getGoodsSku({
        fair_id: user.selectMeeting,
        shop_id: user.selectShop,
        goods_art_no: propsData.goodsArtNo,
      })
      if (error) {
        closeSku()
      }
   //   api.errorProcessor({ error })
      //inline

      //mock出数据
      let datas ={}
      let isshowInside = true;

      if(data.list && data.list.length >0){
        data.list.map((item,index)=>{

          datas[index] = []
          if(!item.inside){
            item.inside = '原版'
          }
          item.sizesObj = {}
          item.sizes.map(size=>{
            item.sizesObj[size.size] = size
          })


          item.initSizeIndex =  data.sizes[data.sizes.indexOf(item.sizes[0].size)]

          item.colorIndex = index
          if(index  === 0 || propsData.goodsArtNo === item.goods_art_no){
            skuData.colorActive = item.colorIndex
            skuData.inileActiveIndex = datas[item.colorIndex].length
            skuData.index = item.initSizeIndex

          }
          datas[index].push(item)
          if(index > 0  && item.inside !==   data.list[index-1].inside){
            skuData.isshowInside = true
          }
        })
      }

      skuData.goods = datas
      skuData.data = data || {}
      initKey = true
    //  skuData.index = 0
    }


    const goodsData = reactive({
      data: {},
    })

    //获取商品
    const getGoodsDetail = async function () {
      const { error, data } = await goodsDetail({
        fair_id: user.selectMeeting,
        shop_id: user.selectShop,
        goods_art_no:  goodsArtNo.value || propsData.goodsArtNo,
      })
      if (error) {
        closeSku()
      }
      api.errorProcessor({ error })
      //inline
      goodsData.data = data;


    }

    let skuIndex = 0
    let batch = ref(false);
    let initKey = true

    const whole = function (type) {
      let data = skuData.goods[skuData.colorActive][skuData.inileActiveIndex]
      if(!data.is_self_order){
        return;
      }
      switch (type) {
        case 'reduce':
          Object.keys(data.sizesObj).map(key=>{
            let item = data.sizesObj[key]
            item.count = item.count > 0 ? item.count - 1 : 0
          })

          break
        case 'add':
          Object.keys(data.sizesObj).map(key=>{
            let item = data.sizesObj[key]
            item.count = item.count < 999 ? item.count + 1 : item.count
          })

          break
      }
    }

    let keywordShow = ref(false)

    let emptyCount = ()=>{
        Object.keys(skuData.goods).map(color=>{
          skuData.goods[color].map((item,index)=>{
            Object.keys(item.sizesObj).map(key=>{
              let sizes = item.sizesObj[key]
              sizes.count = 0
            })
          })

        })

    }
    const onBatch =function(){
      batch.value = !batch.value
      initKey = true;
    }
    const keyword = function (key,index) {
      let this_index  = index ||  skuData.index

      let data = skuData.goods[skuData.colorActive][skuData.inileActiveIndex]
      //批量订货 + 按下键盘

      if (key === 'empty') {
        //清空
        emptyCount()
        data.sizes.map(item=>{
          item.count = 0
        })
        //   data.sizes[skuData.index].count = 0
        return
      }
      if (key === 'save') {
        //保存
        saveOrder()
        return
      }
      if(batch.value && !index && key !== 'save'){

        data.sizes.map(item=>{
          keyword(key,item.size)
        })
        initKey = false;
        return;
      }




      let value = data.sizesObj[this_index].count + ''
      if (key === 'del') {
        //删除
        if (value.length === 1) {
          data.sizesObj[this_index].count = 0
          return
        }
        data.sizesObj[this_index].count = value.substr(
          0,
          value.length - 1
        )
        return
      }

      if(key == 10){
        value = Number(value ) + Number(key)

      }else{
        if (initKey) {
          value = Number(key)
        } else {
          value = Number(value + key)
        }
      }
      if (value > 999) {
        value = 999
      }
      data.sizesObj[this_index].count = value
      if(!index){
        initKey = false
      }

    }
    const ontoggle = function (colorActive,index) {
      skuData.colorActive = colorActive
      skuData.index = index ||  skuData.goods[skuData.colorActive][skuData.inileActiveIndex].initSizeIndex
      keywordShow.value = true;
      batch.value = false
      initKey = true
      goodsArtNo.value = skuData.goods[colorActive][0].goods_art_no
      getGoodsDetail()
    }
    //保存订单
    const saveOrder = async function () {
      if (shortKey.targetShopId !== user.selectShop) {
        await Dialog.confirm({
          title: '门店选择后首次下单',
          message:
            '注意，您当前所在门店为：' +
            skuData.data.shop_name +
            '，是否继续下单',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
      }
      let  errArrr= []
      if(settings.abnormalStatus){
        //超过下单量

        saveGoods.map(item=>{
          if(item.goods_count > 0){

            let sizeArr =  Object.keys(item.sizes)
            let sizeLength = sizeArr.length
            sizeArr.some((key,index)=>{
              let this_item =  item.sizes[key]
              //单尺码数量大于等于
              if(this_item >= settings.abnormalNum){
                errArrr.push('货号：'+item.goods_art_no+'疑似下单异常')
                return true
              }

              //非头尾  下单量为0的时候
              if(this_item === 0 && index > 0 && index < sizeLength-1){
                //需要前后大于0  当第一个为0的话  忘下追溯
                let leftAbnormal = false
                let rightAbnormal = false

                for(let i = index-1; i>=0;i-- ){
                  if(item.sizes[sizeArr[i]] > 0){
                    leftAbnormal = true;
                  }
                }
                for(let n =index+1;  n<=sizeLength-1; n++){
                  if(item.sizes[sizeArr[n]] > 0){
                    rightAbnormal = true;
                  }
                }
                if(leftAbnormal && rightAbnormal){
                  errArrr.push('货号：'+item.goods_art_no+'疑似下单异常')
                  return true
                }
              }
              return false;
            })
          }
        })

        if(errArrr.length > 0 ){
          errArrr.push('您已配置 下单单个尺码数大于等于'+settings.abnormalNum+'为异常，或尺码存在断码。如需要取消异常检测请在“我的-异常检测”中进行关闭')
          await Dialog.confirm({
            title: '下单疑似异常',
            message:errArrr.join('\n'),
            confirmButtonText: '继续下单',
            cancelButtonText: '取消',
          })
        }


      }

      let ListSaveSkuGoods = {}

      let goods = saveGoods.map(item=>{
        ListSaveSkuGoods[item.goods_art_no] = item.goods_count
          return  {
          goods_id:item.goods_id,
          sizes:item.sizes
        }

      })



      let params = {
        goods:goods,
        fair_id: user.selectMeeting,
        shop_id: user.selectShop,
        live_id: propsData.liveId,
      }
/*      let this_shortKey = []
      skuData.data.sizes.map((item) => {
        params.sizes[item.goods_column] = item.count
        this_shortKey.push(item.count)
      })*/
      const { error, data } = await createOrder(params)
      api.errorProcessor({ error })
      orderStore.updateOrderKey()
   /*   skuData.data.sizes.map((item) => {
        data[item.goods_column] = 1
      })*/

   //   shortKey.setshortKeyData(this_shortKey.join(':'))
      shortKey.setTargetShopId(user.selectShop)
/*      let saveGoodsObj = {}
      saveGoods.map(item=>{
        saveGoodsObj[item.goods_id] = item.sizes
      })*/
      console.log(saveOrderData)
      let order = []
      let listArtNum = {}
      data.map(item=>{
        order.push({
          data:item,
          sizes:saveOrderData[item.goods_id]
        })

      })
      SKUBUS.emit('OrderSaveSku', order)
      SKUBUS.emit('ListSaveSku', ListSaveSkuGoods)
      closeSku()
    }

    //显示门店提示
    const alertStore = function () {
      Dialog.alert({
        title: '提示',
        message: '如您需要切换门店，请前往“我的”进行门店切换',
      })
    }




    const closeSku = function () {
      propsData.show = false
      SKUBUS.emit('closeSku')
      nextTick(() => {
        skuData.isshowInside = false
        skuData.data = {}
        skuData.goods = {}
        skuData.colorActive = ''
        goodsData.data = {}
      })
      closeKeyword()
    }


    const closeKeyword = function () {
      keywordShow.value = false
    }

    const keyup = (event,item,items) => {
      event.currentTarget.value = Number(event.currentTarget.value.replace(
        /[^\d]/g,
        ''
      ))
      if(event.currentTarget.value > 999){
        event.currentTarget.value = 999
      }
      item[0].sizesObj[items].count = event.currentTarget.value
    }
    return {
      closeSku,
      goodsColor,
      propsData,
      shortKeyData,
      coverOss,
      skuData,
      setOssResize,
      alertStore,
      whole,
      keyword,
      ontoggle,
      keywordShow,
      goodsData,
      closeKeyword,
      goodsActive,
      onBatch,
      batch,
      keyup,
    }
  },
}
</script>
<style lang="less">
.sku_popup {

  border-radius: rem(10);
  >.van-popup__close-icon {
     font-size: rem(30);
  }
}
.sku_popup_keyword {
  border-radius: rem(10);
  >.van-popup__close-icon {
    font-size: rem(20);
    color: #000;
    margin-top: rem(-10);
  }
}
</style>
<style lang="less" scoped>
.sku_popup {
  .wrap {
    width: rem(980);
    max-height: 90vh;
    margin: 0 auto;
    overflow-x: hidden;
    padding: rem(15);
    overflow-y: auto;
    font-size: rem(14);

    .close {
      position: absolute;
      width: rem(25);
      height: rem(25);
      right: rem(5);
      top: rem(5);
      img {
        width: rem(25);
        height: rem(25);
        display: block;
      }
    }

    .sku-wrap {
      background: #fff;
      padding: rem(5) rem(10);
      .goods_art_no {
        line-height: rem(20);
        overflow: hidden;
        .img,
        .goods-card__image {
          width: rem(60);
          height: rem(60);
        }
        .info {
          overflow: hidden;
          .data_list_price {
            margin-left: rem(5);
          }
          .price {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: rem(30);
          }
          .goods-card__prices {


            :deep {
              &.my-prices--diff-size .my-prices__decimal {
                font-size: inherit;
              }
            }
          }
          .info_art_color {
            .color_description {
              font-size: rem(14);
              padding: rem(2)  rem(8);
              background: #444444;
              border-radius: rem(4);
              color: #fff;
              margin-left: rem(5);
              line-height: rem(18);
              max-width: 50%;
            }
            .list_price {
              font-size: rem(15);
            }
            .color,.goods_art_no_text{
              font-size: rem(15);
            }

          }
        }
      }
      .item-goods {
        width: 100%;
        line-height: rem(30);
        border-bottom: 1px solid #D8D8D8;
        position: relative;
        padding-left: rem(60);
        .label {
          width: rem(60);
          background: #fff;
          top:2px;
          line-height: rem(52);
          background: #fff;
          left: 0;
          position: absolute;
        }
        .scroll {
          overflow-y: visible;
          overflow-x: auto;
          padding: rem(10) 0;
          width:calc(~'100vw - ' rem(80));

        }
        .warp {
          white-space:nowrap;
        }
        .item {
          display: inline-block;
          background: #F6F6F6;
          border-radius: 4px;
          height: rem(30);
          line-height: rem(30);
          margin-right: rem(15);
          padding: 0 rem(5);
          position: relative;
          border: 1px solid #F6F6F6;
          &.active {
            background: #fff7e5;
            border-color: #ffb500;
          }

          .bq {
            position: absolute;
            background: @c-primary-heavy;
            height: rem(15);
            top:0;
            right:rem(-5);
            line-height: rem(15);
            margin-left: rem(-2);
            margin-top: rem(-5);
            padding: 0 rem(2);
            min-width: rem(10);
            text-align: center;
            color: #fff;
            z-index: 1;
            font-size: 12px;
            border-radius:100%;
          }
        }

        &.item-min_nums {
          border: none;
          min-height: rem(30);
          .label {
            background: none;
            line-height: rem(30);
          }
          .scroll {
            padding: 0;

          }
          .item {
            background: none;
            border: none;
            white-space:nowrap;
            font-size: rem(12);ng

            color: @c-primary-heavy;
          }
        }
      }
      .skus {
        margin-top: rem(5);
        max-height: rem(130);
        overflow-y: auto;
        .item {
          display: inline-block;
          margin-bottom: rem(5);
          width: 20%;
          max-width: rem(80);
          line-height: rem(25);
          text-align: center;
          .top {
            background: #f6f6f6;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: none;
            border-right: none;
          }
          .input {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-right: none;
            line-height: rem(30);
          }

          &.active {
            .input {
              background: #fff7e5;
              border-color: @c-primary;
            }
            + .item {
              .input {
                border-left: 1px solid @c-primary;
              }
            }
          }

          &:nth-child(5n).active + .item {
            .input {
              border-left: 1px solid rgba(0, 0, 0, 0.1);
            }
          }

          &:nth-child(5n).active,
          &:last-child.active {
            .input {
              border-right: 1px solid @c-primary;
            }
          }
          &:nth-child(5n) .input,
          &:last-child .input {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
          &:nth-child(5n) .top,
          &:last-child .top {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    .min_order_nums {
      color: @c-primary-heavy;
      min-height: rem(25);
      font-size: rem(12);
      line-height: rem(25);
      padding: 0 rem(10);
      position: relative;
      top:rem(-10);
      text-align: left;
    }
  }
}
.fs-10 { font-size: rem(10); }
.fs-12 { font-size: rem(12); }

.goods-top-info {
  width: rem(570);
  margin-left: rem(5);
}



.goods-keyword {
  margin-top: rem(15);
  .goods-keyword_table {
    .tr {
      height: rem(40);
      border-bottom: 1px solid rgba(216, 216, 216, .2);
      padding: rem(5) 0;
      text-align: center;
      &.active {
          .color,.inside {
            color: @c-primary-heavy;
          }
      }
    }
    .thead { background: #F2F2F2FF}
    .td,.th {
      width: rem(70);
      text-align: center;
    }
    .th {
      padding: 0 rem(5);
      &.desc {
        padding: 0;

        width:rem(60);
      }
    }
    .td {
      width:rem(70);
      height: rem(38);
      padding: 0 rem(5);
      line-height: rem(38);
      text-align: center;
      background: #FFFFFF;
      .input {

        height: rem(38);
        border: 1px solid #CCCCCC;
        outline:none;
        appearance: none;
        width: 1.4rem;
        box-sizing: border-box;
        text-align: center;
        &.active {
          background: rgba(255,114,0,0.05);
          border: 1px solid @c-primary-heavy;
        }

        &.disabled {
          background: #F2F2F2FF;
        }
      }
      &.desc {
        border: none;

        padding: 0;
        width:rem(60);
      }
    }
  }
  .goods-keyword_total {
    margin-top: rem(5);
    font-size:rem(18);
    .van-button--primary {
      background: @c-primary-heavy;
      border-color:@c-primary-heavy;
    }
    .mar-lef-10 {
      margin-left: rem(10);
    }
  }
}

.keyword_wrap {
  .safe-area-inset-bottom();
  padding-top: rem(30);
  background: #ccced3;

  .keyword {
    padding: 0 rem(10) rem(5) rem(10);
      .num {
        width: rem(160);
        height: rem(45);
        line-height: rem(45);
        text-align: center;
        display: inline-block;
        margin-top: rem(5);
        &.batch {
          width: rem(102);
        }
        &.ten {
          width: rem(132);
        }
        &.del {
          width: rem(100);
        }
        &.zero {
          width: rem(81);
        }
        &.save {
          width: rem(151);
        }

        > div {
          margin-right: rem(5);
          background: #fff;
          font-size: rem(15);
          border-radius: rem(5);
          box-sizing: border-box;
        }


        &.key {
          > div {
            background: rgba(175,180,196,0.8);
            box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.47);
          }
        }
        &:hover {
          > div{
            background: #efefef;
          }
        }
        &.key:hover {
          > div{
            background: rgba(175,180,196,1);
          }
        }
      }
    .right {
      width: 20%;
      .num {
        height: rem(35);
        padding: rem(8) 0;
        margin-top: rem(5);
        line-height: rem(17);
        text-align: center;
        width: 100%;
        border-radius: rem(10);
        font-size: rem(14);
        background: #fff;
      }
      .save {
        height: rem(45);
        line-height: rem(15);
        padding: 0;

        background: @c-primary;
        color: #fff;
      }
    }
  }
}
</style>
