<template>
  <div
    class="fixed"
    :class="[position, safeAreaInsetBottom ? 'safe-area-inset-bottom' : '']"
    :style="{ zIndex }"
    ref="el"
  >
    <slot name="default"></slot>
  </div>
  <div
    class="fixed-holder"
    :class="{ 'safe-area-inset-bottom': safeAreaInsetBottom }"
    v-if="holder"
  >
    <div :style="{ height: `${rect.height}px` }"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { useResizeRect } from '@/utils'

export default defineComponent({
  name: 'Fixed',
  props: {
    position: {
      type: String as PropType<'top' | 'bottom'>,
      default: 'top',
    },
    holder: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: Number,
      default: 1000,
    },
    safeAreaInsetBottom: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const el = ref()
    const rect = useResizeRect(el)

    return {
      el,
      rect,
    }
  },
})
</script>

<style lang="less" scoped>
.fixed {
  position: fixed;
  left: 0;
  right: 0;

  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }

  &.safe-area-inset-bottom {
    .safe-area-inset-bottom();
  }
}

.fixed-holder {
  flex-shrink: 0;
  position: relative;
  z-index: -9999999999;
  visibility: hidden;

  &.safe-area-inset-bottom {
    .safe-area-inset-bottom();
  }
}
</style>
