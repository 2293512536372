<template>
  <Base :props="props.props" v-if="!response.error && list.length">
    <div
      class="custom-layout-live"
      :class="{
        'custom-layout-live--border-radius': props.props.page_padding,
      }"
    >
      <div class="custom-layout-live__header">
        <router-link
          class="custom-layout-live__header-box"
          :to="{ name: 'Live' }"
          ondragstart="return false"
        >
          <ImgTitle class="custom-layout-live__header-title" />

          <div class="custom-layout-live__header-more">
            主播带你在线挑款<Icon use="arrowright" />
          </div>
        </router-link>
      </div>

      <div class="custom-layout-live__main">
        <router-link
          class="custom-layout-live__item"
          v-for="(item, index) in list"
          :to="item.to"
          ondragstart="return false"
        >
          <div class="custom-layout-live__item-box">
            <Image class="custom-layout-live__image" :src="item.cover" />
          </div>

          <!-- 状态 -->
          <div
            class="custom-layout-live__status"
            :class="{
              'custom-layout-live__status--preview': item.status === 0,
              'custom-layout-live__status--living': item.status === 1,
              'custom-layout-live__status--replay': item.status === 2,
            }"
          >
            <template v-if="item.status === 0">
              <template v-if="index === 0">{{
                `预计${dateFormat(item.start_time)}开播`
              }}</template>
              <template v-else> <ImgPlay class="icon--play" />预告 </template>
            </template>
            <template v-if="item.status === 1">
              <div class="icon--living">
                <div class="icon--living-item"></div>
                <div class="icon--living-item"></div>
                <div class="icon--living-item"></div>
                <div class="icon--living-item"></div>
              </div>
              直播中
            </template>
            <template v-if="item.status === 2">
              <ImgPlay class="icon--play" />回放
            </template>
          </div>

          <!-- 标题  -->
          <div class="custom-layout-live__title" v-if="item.title">
            {{ item.title }}
          </div>
        </router-link>
      </div>
    </div>
  </Base>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import * as api from '@/api'
import { shortKey, user } from '@/store'
import { ossResize } from '@/utils'

import Image from '@/components/Image'
import Icon from '@/components/Icon'
import Base, { IBaseProps } from './Base.vue'

import ImgTitle from '@/assets/images/zhibohaohuo.svg?component'
import ImgPlay from '@/assets/images/bifang.svg?component'
import ImgPreview from '@/assets/images/advance.png'
import ImgReplay from '@/assets/images/replay.png'

export interface ILiveProps extends IBaseProps {}

const props = defineProps({
  props: {
    type: Object as PropType<ILiveProps>,
    default: {},
  },
})

const response = api.useQuery(
  () =>
    api.other.getHomeLive({
      fair_id: user.selectMeeting!,
      shop_id: user.selectShop!,
    }),
  {
    onError(error) {
      api.errorProcessor({ error, throwError: false })
    },
    immediate: false,
    keepDataOnRefetch: true,
  }
)

const list = computed(() => {
  if (!response.data?.data?.length) {
    return []
  }

  const list = (response.data?.data ?? []).map((v) => ({
    ...v,
    cover: ossResize(v.cover, {
      preset: 's',
    }),

    to: <RouteLocationRaw>{
      name: {
        0: 'Live',
        1: 'LivePlayer',
        2: 'LiveReplay',
      }[v.status],
      query: {
        id: v.status === 0 ? undefined : v.live_id,
        target_shop_id: shortKey.targetShopId,
      },
    },
  }))
  if (list.length < 2) {
    list.push({
      live_id: 0,
      cover: ImgPreview,
      title: '',
      start_time: '',
      status: 0,
      to: {
        name: 'Live',
        query: {
          target_shop_id: shortKey.targetShopId,
        },
      },
    })
  }
  if (list.length < 3) {
    list.push({
      live_id: 0,
      cover: ImgReplay,
      title: '',
      start_time: '',
      status: 2,
      to: {
        name: 'Live',
        query: {
          target_shop_id: shortKey.targetShopId,
        },
      },
    })
  }

  return list
})

function dateFormat(time: string) {
  let result = time.split('-')
  return result[1] + '/' + result[2]
}

defineExpose({
  async fetch() {
    await response.refetch()

    return {
      finish: true,
    }
  },
})
</script>

<script lang="ts">
import { defineComponent } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'CustomLayoutLive',
  components: { Image, Icon },
})
</script>

<style lang="less" scoped>
@first-item--s: rem(150);
@first-item--l: rem(250);

.custom-layout-live {
  position: relative;

  background-color: white;
  padding: rem(6);

  &.custom-layout-live--border-radius {
    border-radius: rem(10);
  }

  .custom-layout-live__header {
    position: absolute;
    padding: rem(6);
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;

    .custom-layout-live__header-box {
      margin-left: @first-item--s;
      padding: rem(6);
      padding-right: 0;

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      @media (min-width: 750px) {
        flex-direction: row;
        margin-left: @first-item--l;
        padding-top: rem(10);
      }
    }
    .custom-layout-live__header-title {
      height: rem(16);
      width: auto;
      align-self: flex-start;

      @media (min-width: 750px) {
        align-self: auto;
        height: rem(26);
      }
    }

    .custom-layout-live__header-more {
      background: linear-gradient(244deg, #fffaf0 20%, #fff5e1 76%);
      border-radius: rem(99);
      color: #c08203;
      font-size: rem(12);
      padding: rem(2) rem(8);
      align-self: flex-end;
      margin-top: rem(8);

      @media (min-width: 750px) {
        margin-top: auto;
        align-self: auto;
        font-size: rem(14);
        padding: rem(4) rem(14);
      }
    }
  }

  .custom-layout-live__main {
    display: flex;
    align-items: flex-end;

    .custom-layout-live__status {
      display: flex;
      justify-content: center;
      align-items: center;

      height: rem(20);
      padding: 0 rem(6);
      position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;

      font-size: rem(10);
      color: white;
      border-radius: 0 0 rem(8) 0;

      @media (min-width: 750px) {
        font-size: rem(12);
        height: rem(25);
      }

      &--preview {
        background: linear-gradient(180deg, #77faff, #76cb0f);
      }
      &--living {
        background: linear-gradient(232deg, #ff5c35, #ff4040);
      }
      &--replay {
        background: linear-gradient(180deg, #ffa98c, #ff8278);
      }

      .icon--living {
        width: 1.2em;
        height: 1em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-right: rem(4);

        > .icon--living-item {
          flex-grow: 1;
          height: 1em;
          background: #ffffff;
          border-radius: rem(2);
          margin: 0 5%;
          margin-bottom: 0.1em;
          animation: bouncedelay 1s infinite linear both;
          transform-origin: bottom;

          &:nth-child(1) {
            animation-delay: -0.3s;
          }
          &:nth-child(3) {
            animation-delay: -0.7s;
          }
          @keyframes bouncedelay {
            0%,
            100%,
            80% {
              transform: scaleY(0.5);
            }
            40% {
              transform: scaleY(1);
            }
          }
        }
      }
      .icon--play {
        width: 1em;
        height: 1em;
        margin-right: rem(4);
      }
    }

    .custom-layout-live__item {
      position: relative;
      border-radius: rem(8);
      overflow: hidden;

      & + .custom-layout-live__item {
        margin-left: rem(6);
      }

      &:nth-child(1) {
        width: @first-item--s;
        flex-shrink: 0;
        @media (min-width: 750px) {
          width: @first-item--l;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        flex-grow: 1;

        @media (min-width: 750px) {
          padding-right: rem(38);
        }
        .custom-layout-live__title {
          display: none;
        }

        .custom-layout-live__status {
          @media (min-width: 750px) {
            left: initial;
            right: 0;
            bottom: 0;
            height: auto;
            width: rem(38);
            flex-direction: column;
            font-size: rem(16);
            text-align: center;
            line-height: 1.8;

            .icon--living,
            .icon--play {
              margin-right: 0;
              margin-bottom: rem(10);
            }

            .icon--play {
              width: rem(25);
              height: rem(25);
            }
          }
        }
      }
    }
    .custom-layout-live__item-box {
      position: relative;
      height: 0;
      padding-bottom: 100%;
    }

    .custom-layout-live__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .custom-layout-live__title {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      height: rem(40);
      line-height: rem(40);
      padding: 0 rem(10);
      color: white;
      font-size: rem(16);
      background: linear-gradient(360deg, rgba(black, 0.6), rgba(black, 0));

      @media (min-width: 750px) {
        height: rem(50);
        line-height: rem(50);
      }
    }
  }
}
</style>
