<template>
  <div class="mynavbar-main">
    <Fixed position="bottom" :z-index="zIndex" :holder="holder">
      <div class="mynavbar" ref="el">
        <div class="mynavbar-appendix" v-if="$slots.default">
          <slot name="default" />
        </div>

        <van-tabbar
          :model-value="active"
          :fixed="false"
          router
          v-if="!hiddenNavbar"
        >
          <MyNavbarIcon
            v-for="item in items"
            :key="item.label"
            :label="item.label"
            :icon="item.icon"
            :to="item.to"
            :dot="item.dot"
            @click="item.onClick?.()"
          />
        </van-tabbar>
      </div>
    </Fixed>
  </div>
</template>

<script lang="ts">
import type { ComputedRef } from 'vue'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import { Toast, Dialog } from 'vant'
import Icon, { icons } from '../Icon'
import MyNavbarIcon from './MyNavbarItem.vue'
import Fixed from '../Fixed'

import { user, device, order } from '@/store'
import * as api from '@/api'
import * as weapp from '@/utils/weapp'
import { setLogInfo } from '@/utils/log'

export type INavbarItemKey = 'home' | 'order' | 'scan' | 'analysis' | 'mine'

export interface INavbarItemData {
  key: INavbarItemKey
  label: string
  icon: keyof typeof icons
  to?:
    | RouteLocationRaw
    | { to: RouteLocationRaw; text: string; dot?: ComputedRef<boolean> }[]
  dot?: ComputedRef<boolean>
  onClick?: Function
}

let currentToken: typeof user.token

export default defineComponent({
  name: 'MyNavbar',
  components: {
    Icon,
    MyNavbarIcon,
    Fixed,
  },
  props: {
    /* 生成等高的占位符 */
    holder: {
      type: Boolean,
      default: true,
    },

    zIndex: {
      type: Number,
      default: 1500,
    },

    hiddenNavbar: Boolean,
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    // 导航内容列表
    const items = ref<INavbarItemData[]>([
      {
        key: 'home',
        label: '首页',
        icon: 'home',
        to: {
          name: 'Home',
        },
      },
      {
        key: 'order',
        label: '订单',
        icon: 'order',
        to: {
          name: 'OrderList',
        },
      },
      ...(device.isWeChatMini
        ? <INavbarItemData[]>[
            {
              key: 'scan',
              label: '扫一扫',
              icon: 'scan',
              onClick: () => {
                weapp.scanQRCode({
                  scanType: ['qrCode', 'barCode'],
                  async success({ resultStr }: { resultStr: string }) {
                    console.log('scanQRCode:resultStr', resultStr)
                    if (!resultStr) {
                      Toast('没有扫到有效的条形码/二维码')
                      return
                    }

                    let code = resultStr.replace(/^.+?,/, '')
                    if (code.includes('_')) {
                      const { data, error } = await api.goods.getGoodsArtNo({
                        goods_art_no: code,
                        fair_id: user.selectMeeting,
                        shop_id: user.selectShop,
                      })
                      api.errorProcessor({ error })
                      code = data.goods_art_no
                    }

                    order.setTargetGoodsArtNo(code)

                    if (route.name !== 'OrderList') {
                      router.push({
                        name: 'OrderList',
                      })
                    }
                  },
                })
              },
            },
          ]
        : [
            {
              key: 'scan',
              label: '扫一扫',
              icon: 'scan',
              onClick: () => {
                device.setScanner(true)
                /*        weapp.scanQRCode({
                scanType: ['qrCode', 'barCode'],
                async success({ resultStr }: { resultStr: string }) {
                  console.log('scanQRCode:resultStr', resultStr)
                  if (!resultStr) {
                    Toast('没有扫到有效的条形码/二维码')
                    return
                  }

                  let  code = resultStr.replace(/^.+?,/, '')
                  if(code.includes('_')){
                    const { data, error } =  await api.goods.getGoodsArtNo({
                      goods_art_no:code,
                      fair_id: user.selectMeeting,
                      shop_id: user.selectShop,
                    })
                    api.errorProcessor({ error })
                    code = data.goods_art_no
                  }


                  order.setTargetGoodsArtNo(code)

                  if (route.name !== 'OrderList') {
                    router.push({
                      name: 'OrderList',
                    })
                  }
                },
              })*/
              },
            },
          ]),
      {
        key: 'analysis',
        label: '分析',
        icon: 'analysis',
        to: {
          name: 'Analysis',
        },
      },
      {
        key: 'mine',
        label: '我的',
        icon: 'mine',
        to: {
          name: 'Mine',
        },
        // dot: computed(() => !!user.unread.refund),
      },
    ])
    if (order.pass || !user.fairData?.is_white) {
      items.value = [
        {
          key: 'order',
          label: '订单',
          icon: 'order',
          to: {
            name: 'OrderList',
          },
        },
        {
          key: 'mine',
          label: '我的',
          icon: 'mine',
          to: {
            name: 'Mine',
          },
        },
      ]
    }

    const navigation = computed(() => {
      return router.currentRoute.value.meta.navigation as INavbarItemKey
    })
    const active = computed(() => {
      return items.value.findIndex((item) => {
        return item.key === navigation.value
      })
    })
    watch(
      () => user.fairData,
      async (value) => {
        if (value.is_white) {
          items.value = [
            {
              key: 'home',
              label: '首页',
              icon: 'home',
              to: {
                name: 'Home',
              },
            },
            {
              key: 'order',
              label: '订单',
              icon: 'order',
              to: {
                name: 'OrderList',
              },
            },
            ...(device.isWeChatMini
              ? <INavbarItemData[]>[
                  {
                    key: 'scan',
                    label: '扫一扫',
                    icon: 'scan',
                    onClick: () => {
                      weapp.scanQRCode({
                        scanType: ['qrCode', 'barCode'],
                        async success({ resultStr }: { resultStr: string }) {
                          console.log('scanQRCode:resultStr', resultStr)
                          if (!resultStr) {
                            Toast('没有扫到有效的条形码/二维码')
                            return
                          }

                          let code = resultStr.replace(/^.+?,/, '')
                          if (code.includes('_')) {
                            const { data, error } =
                              await api.goods.getGoodsArtNo({
                                goods_art_no: code,
                                fair_id: user.selectMeeting,
                                shop_id: user.selectShop,
                              })
                            api.errorProcessor({ error })
                            code = data.goods_art_no
                          }

                          order.setTargetGoodsArtNo(code)

                          if (route.name !== 'OrderList') {
                            router.push({
                              name: 'OrderList',
                            })
                          }
                        },
                      })
                    },
                  },
                ]
              : [
                  {
                    key: 'scan',
                    label: '扫一扫',
                    icon: 'scan',
                    onClick: () => {
                      device.setScanner(true)
                      /*        weapp.scanQRCode({
                scanType: ['qrCode', 'barCode'],
                async success({ resultStr }: { resultStr: string }) {
                  console.log('scanQRCode:resultStr', resultStr)
                  if (!resultStr) {
                    Toast('没有扫到有效的条形码/二维码')
                    return
                  }

                  let  code = resultStr.replace(/^.+?,/, '')
                  if(code.includes('_')){
                    const { data, error } =  await api.goods.getGoodsArtNo({
                      goods_art_no:code,
                      fair_id: user.selectMeeting,
                      shop_id: user.selectShop,
                    })
                    api.errorProcessor({ error })
                    code = data.goods_art_no
                  }


                  order.setTargetGoodsArtNo(code)

                  if (route.name !== 'OrderList') {
                    router.push({
                      name: 'OrderList',
                    })
                  }
                },
              })*/
                    },
                  },
                ]),
            {
              key: 'analysis',
              label: '分析',
              icon: 'analysis',
              to: {
                name: 'Analysis',
              },
            },
            {
              key: 'mine',
              label: '我的',
              icon: 'mine',
              to: {
                name: 'Mine',
              },
              // dot: computed(() => !!user.unread.refund),
            },
          ]
        } else {
          items.value = [
            {
              key: 'order',
              label: '订单',
              icon: 'order',
              to: {
                name: 'OrderList',
              },
            },
            {
              key: 'mine',
              label: '我的',
              icon: 'mine',
              to: {
                name: 'Mine',
              },
            },
          ]
        }
      },
      {
        immediate: true,
      }
    )
    watch(
      () => user.token,
      async (token) => {
        if (token === currentToken) return
        currentToken = token

        if (!token) {
          // user.setUnread('refund', undefined)
          return
        }

        // const { data, error } = await api.user.unread()
        // api.errorProcessor({ error })

        // if (data) {
        //   const { unread_refund_count } = data
        //   user.setUnread('refund', unread_refund_count)
        // }
      },
      {
        immediate: true,
      }
    )

    return {
      ...toRefs(props),
      device,
      active,
      items,
    }
  },
})
</script>
<style lang="less">
.pc-app {
  .mynavbar-main {
    .fixed-holder {
      display: none;
    }
    .mynavbar-appendix {
      display: none;
    }
    .fixed {
      bottom: auto;
      right: 0;
      left: auto;
      top: 50vh;
      margin-top: rem(-120);
      .mynavbar {
        border-top-left-radius: rem(10);
        border-bottom-left-radius: rem(10);
        overflow: hidden;
      }
      .van-tabbar {
        display: block;
        background: #fff;
        height: rem(240);
      }
      .van-tabbar-item {
        padding: rem(10);
      }
    }
  }
}
</style>
<style lang="less" scoped>
.mynavbar {
  background-color: white;

  :deep(.van-tabbar-item) {
    font-size: rem(10);
  }
  :deep(.van-tabbar-item--active) {
    color: @c-primary;
  }

  :deep(.van-tabbar) {
    .safe-area-inset-bottom();
    background-color: transparent;
  }
}
</style>
