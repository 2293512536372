<template>
  <div class="custom-layout-base" :class="className">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'

export interface IBaseProps {
  page_padding?: boolean
  margin_top?: boolean
  margin_bottom?: boolean
}

const props = defineProps({
  props: {
    type: Object as PropType<IBaseProps>,
    default: {},
  },
})

const className = computed(() => {
  const _props = props.props || {}
  const className = []
  if (_props.page_padding) {
    className.push('padding-left', 'padding-right')
  }
  if (_props.margin_top) {
    className.push('margin-top')
  }
  if (_props.margin_bottom) {
    className.push('margin-bottom')
  }
  return className
})
</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomLayoutBase',
})
</script>

<style lang="less" scoped>
@space: rem(12);

.custom-layout-base {
  &.padding-left {
    padding-left: @space;
  }
  &.padding-right {
    padding-right: @space;
  }
  &.margin-top {
    padding-top: @space;
  }
  &.margin-bottom {
    padding-bottom: @space;
  }
}
</style>
