import {
  boolean,
  number,
  string,
  object,
  array,
  date,
  setLocale,
  addMethod,
} from 'yup'
import {
  MixedLocale,
  BooleanLocale,
  NumberLocale,
  StringLocale,
  ObjectLocale,
  ArrayLocale,
  DateLocale,
} from 'yup/lib/locale'

setLocale({
  mixed: <MixedLocale>{
    default: '${path}数据格式错误',
    required: '${path}是必须的',
    oneOf: '${path}必须在以下的值中: ${values}',
    notOneOf: '${path}必须不在以下的值中: ${values}',
    // notType: '',
    defined: '${path} 必须定义',
  },
  boolean: <BooleanLocale>{
    isValue: '${path}必须是${value}',
  },
  number: <NumberLocale>{
    min: '${path}必须大于或等于${min}',
    max: '${path}必须小于或等于${max}',
    lessThan: '${path}必须小于${less}',
    moreThan: '${path}必须大于${more}',
    positive: '${path}必须是一个正数',
    negative: '${path}必须是一个负数',
    integer: '${path}必须是一个整数',
  },
  string: <StringLocale>{
    length: '${path} 必须是 ${length} 个字',
    min: '${path}至少${min}个字',
    max: '${path}最多${max}个字',
    matches: '${path}数据格式错误',
    email: '${path}不是有效的E-Mail',
    url: '${path不是有效的URL',
    uuid: '${path}不是有效的UUID',
    // trim: '${path} must be a trimmed string',
    // lowercase: '${path} must be a lowercase string',
    // uppercase: '${path} must be a upper case string',
  },
  object: <ObjectLocale>{
    noUnknown: '${path}存在未指定的键: ${unknown}',
  },
  array: <ArrayLocale>{
    min: '${path}至少要包含${min}项',
    max: '${path}最多只能包含${max}项',
    length: '${path}必须是${length}项',
  },
  date: <DateLocale>{
    min: '${path}晚于${min}',
    max: '${path}早于${max}',
  },
})

/* ============= 自定义规则 ============= */

/* 密码校验 */
addMethod(
  string,
  'password',
  function (options: { repect?: boolean; continuous?: boolean } = {}) {
    const { repect, continuous } = options

    return this.test({
      message: '${path}不能是重复的数字',
      name: 'passwordRepeatNumber',
      test(value) {
        if (repect) return true
        if (!value || value.length < 2) return true
        if (/[^0-9]/g.test(value)) return true

        return !/^(.)\1+$/g.test(value)
      },
    }).test({
      message: '${path}不能是连续的数字',
      name: 'passwordContinuousNumber',
      test(value) {
        if (continuous) return true
        if (!value || value.length < 2) return true
        if (/[^0-9]/g.test(value)) return true

        let last = +value[0]
        for (let i = 1; i < value.length; i += 1) {
          const now = +value[i]
          if (last + 1 !== now) return true
          last = now
        }
        return false
      },
    })
  }
)

/* 身份证校验 */
addMethod(string, 'idcard', function () {
  return this.test({
    message: '${path}不是有效的身份证号',
    name: 'idcard',
    test(value) {
      if (!value) return false

      const length = value.length
      if (!(length === 15 || length === 18)) return false

      return /^(([0-9]{15,15})|([0-9]{17,17}[0-9X]))$/.test(value)
    },
  })
})

export const yup = {
  boolean,
  date,
  number,
  array,
  object,
  string,
}
