export function throttle<T extends (...args: any[]) => void>(
  fn: T,
  options?: {
    immediate?: boolean
    interval?: number
  }
) {
  const { immediate = false, interval = 100 } = options ?? {}
  let timer: number | undefined
  let lastTime = 0

  return function (this: any) {
    const context = this
    const args = arguments as unknown as Parameters<T>

    if (timer === undefined) {
      const delay = immediate
        ? Math.max(interval - (Date.now() - lastTime), 0)
        : interval

      timer = setTimeout(() => {
        fn.apply(context, args)
        timer = undefined
        lastTime = Date.now()
      }, delay)
    }
  }
}
