import { readonly, reactive, watch, Ref } from 'vue'
import {
  persistedstate,
  isWeChatMini as persistedstateIsWeChatMini,
} from '@/utils'

export interface IRightTextButtonOptions {
  text: string
  callback: Function
}
const ua = navigator.userAgent
const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
const isIos = /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(ua)
const isWeChatMini = navigator.userAgent.toLowerCase().includes('miniprogram')
persistedstateIsWeChatMini.value = isWeChatMini

const isMobile = 'ontouchstart' in document || isWeChatMini
const isPC = !isMobile
const isWeixin =
  navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1
const isWeixinLook = isWeixin
const state = persistedstate(
  'device',
  {
    isMobile,
    isPC,
    isIos,
    isAndroid,
    /* 页面标题 */
    title: '__title__',
    setTitle(value?: string) {
      state.title = value ? value : '__title__'
    },

    /*小程序*/
    isWeChatMini,
    setIsWeChatMini(value: boolean) {
      state.isWeChatMini = value
      persistedstateIsWeChatMini.value = value
    },
    /* 页面背景色 */
    backgroundColor: undefined as string | undefined,
    setBackgroundColor(value?: string) {
      state.backgroundColor = value
    },

    /* 禁用 页面宽度限制 */
    disabledLimitMaxWidth: false,
    setDisabledLimitMaxWidth(value: boolean) {
      state.disabledLimitMaxWidth = value
    },

    /* 右侧按钮 */
    rightTextButton: undefined as IRightTextButtonOptions | undefined,
    setRightTextButton(options?: IRightTextButtonOptions) {
      state.rightTextButton = options
    },

    /* 顶部加载进度条 */
    progress: false,
    setProgress(value: boolean) {
      state.progress = value
    },

    /* 是否微信 */
    isWeixin,
    isWeixinLook,
    setIsWeixinLook(value: boolean) {
      state.isWeixinLook = value
    },

    /* 线上平台政策说明弹窗版本号 */
    version: '',
    setVersion(value: string) {
      state.version = value
    },

    /* 零售好卖榜 提示 */
    hotsaleNotice: true,
    setHotsaleNotice(value: boolean) {
      state.hotsaleNotice = value
    },

    /* 首次访问 */
    firstVisit: true,
    setFirstVisit(value: boolean) {
      state.firstVisit = value
    },

    /* 通用加载状态 */
    loading: false,
    setLoading(value: boolean) {
      state.loading = value
    },

    /* 显示扫一扫 */
    scanner: false,
    setScanner(value: boolean) {
      state.scanner = value
    }
  },
  (state) => {
    const { isWeixinLook, version, hotsaleNotice } = state
    return { isWeixinLook, version, hotsaleNotice }
  }
)

watch(
  () => state.title,
  (title) => {
    document.title = title
  },
  {
    immediate: true,
    flush: 'sync',
  }
)

const setRightTextButtonQueue = reactive<(() => Promise<any>)[]>([])
watch(
  () => setRightTextButtonQueue.length,
  (length) => {
    if (!length) return
    const fn = setRightTextButtonQueue[0]
    fn().then(() => {
      setRightTextButtonQueue.shift()
    })
  }
)

watch(
  () => state.backgroundColor,
  (color) => {
    const body = document.body
    body.style.backgroundColor = color ?? (null as unknown as string)
  }
)

watch(
  () => state.disabledLimitMaxWidth,
  (disabled) => {
    const app = document.getElementById('app')!
    if (disabled) {
      app.classList.add('disabled-limit-max-width')
    } else {
      app.classList.remove('disabled-limit-max-width')
    }
  }
)

export const device = readonly(state)

export function useBackgroundColor(color?: string | Ref<string>) {
  const unrefColor = computed(() => unref(color))
  watch(unrefColor, (color) => device.setBackgroundColor(color))
  onActivated(() => device.setBackgroundColor(unrefColor.value))
  onMounted(() => device.setBackgroundColor(unrefColor.value))
  onDeactivated(() => device.setBackgroundColor())
  onUnmounted(() => device.setBackgroundColor())
}

export function useDisabledLimitMaxWidth() {
  onActivated(() => device.setDisabledLimitMaxWidth(true))
  onMounted(() => device.setDisabledLimitMaxWidth(true))
  onDeactivated(() => device.setDisabledLimitMaxWidth(false))
  onUnmounted(() => device.setDisabledLimitMaxWidth(false))
}
