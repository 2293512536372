import { Router, RouterScrollBehavior } from 'vue-router'

/**
 * 保持 keepAlive 页面的滚动条位置
 * 目前以路由 path（区分大小写） 作为判断依据，保存滚动条位置
 * 后续增加 query 的判断
 *
 *
 * const router = createRouter({
 *   scrollBehavior: getScrollPosition
 * })
 *
 * saveScrollPosition(router)
 */

const positions = new Map<
  string,
  {
    top?: number
    left?: number
  }
>()

export function saveScrollPosition(router: Router) {
  router.beforeEach((to, from) => {
    if (from.meta.keepAlive) {
      positions.set(from.path, { top: window.pageYOffset })
    }
  })
}

/**
 * 保持 keepAlive 页面的滚动条位置
 * @param router
 */
export const getScrollPosition: RouterScrollBehavior = function (
  to,
  from,
  savedPosition
) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(savedPosition ??
        positions.get(to.path) ?? {
          top: 0,
        }
        )
    }, 10)
  })
}
