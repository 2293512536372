<template>
  <Base :props="props.props">
    <div
      class="custom-layout-quick-link"
      :class="className"
      :style="{
        backgroundImage: `url(${backgroundImage})`,
        color: textColor,
      }"
    >
      <van-swipe
        class="custom-layout-quick-link__swiper"
        :duration="500"
        indicator-color="rgba(255,255,255)"
      >
        <van-swipe-item
          v-for="(row, index) in list"
          :key="index"
          class="custom-layout-quick-link__swiper-item"
        >
          <div
            class="custom-layout-quick-link__link"
            v-for="(item, index) in row"
            :key="index"
            @click="navigateTo(item.url)"
          >
            <Image class="custom-layout-quick-link__image" :src="item.src" />
            <div class="custom-layout-quick-link__text">{{ item.title }}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </Base>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'

import Image from '@/components/Image'
import Base, { IBaseProps } from './Base.vue'

import { ossResize, useCustomRouter, ICustomRoute } from '@/utils'

export interface IQuickLinkProps extends IBaseProps {
  list: {
    image: string
    title: string
    weapp_href?: string
  }[]
  color: string
  cover: {
    image: string
  }
  column: 4 | 5
}

const props = defineProps({
  props: {
    type: Object as PropType<IQuickLinkProps>,
    default: {},
  },
})

const column = computed(() => {
  return Math.max(4, Math.min(5, props.props?.column ?? 4))
})

const originList = computed(() => {
  return (props.props?.list ?? [])
    .filter((v) => v.image || v.title || v.weapp_href)
    .map((v) => ({
      ...v,
      src: ossResize(v.image, {
        m: 'pad',
        w: 100,
        h: 100,
      }),
      url: v.weapp_href,
    }))
    .slice(0, column.value * 2 * 2)
})

const list = computed(() => {
  const res: any[] = []

  originList.value.forEach((item, i) => {
    const index = Math.floor(i / (column.value * 2))
    const arr = res[index] ?? []
    res[index] = arr
    arr.push(item)
  })

  return res
})

const className = computed(() => {
  const className: string[] = []

  if ((props.props?.list?.length ?? 0) > 4) {
    className.push('custom-layout-quick-link--two-row')
  } else {
    className.push('custom-layout-quick-link--one-row')
  }

  className.push(`custom-layout-quick-link--column-${column.value}`)

  return className
})

const backgroundImage = computed(() => {
  if (!props.props?.cover?.image) return ''
  const h = (props.props?.list?.length ?? 0) > 4 ? 440 : 240

  return ossResize(props.props.cover.image, {
    m: 'fixed',
    w: 750,
    h,
  })
})

const textColor = computed(() => {
  return props.props?.color ?? '#666'
})

const customRouter = useCustomRouter()
function navigateTo(to?: ICustomRoute) {
  customRouter.push(to)
}
</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomLayoutQuickLink',
})
</script>

<style lang="less" scoped>
.custom-layout-quick-link {
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &.border-round {
    .custom-layout-quick-link__swiper {
      border-radius: rem(10);
      overflow: hidden;
    }
  }

  &--column-4 {
    .custom-layout-quick-link__link {
      flex-basis: 25%;
    }

    .custom-layout-quick-link__image {
      width: rem(54);
      height: rem(54);
    }
  }

  &--column-5 {
    .custom-layout-quick-link__link {
      flex-basis: 20%;
    }

    .custom-layout-quick-link__image {
      width: rem(48);
      height: rem(48);
    }
  }

  &--one-row {
    .custom-layout-quick-link__swiper {
      height: rem(100);
    }
  }

  &--two-row {
    .custom-layout-quick-link__swiper {
      height: rem(180);
    }
  }

  &__swiper {
    padding-top: rem(15);
    padding-left: rem(4);
    padding-right: rem(4);
  }

  &__swiper-item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-basis: 25%;
    overflow: hidden;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;

    font-size: rem(12);
    margin-top: rem(6);
  }
}
</style>
