<template>
  <Base :props="props.props">
    <div
      class="custom-layout-video"
      :class="{
        'custom-layout-video--loaded': loaded,
      }"
      :style="{
        paddingBottom: `${aspect}%`,
      }"
    >
      <video
        ref="video"
        class="custom-layout-video__video"
        :src="src"
        :poster="poster"
        :controls="controls"
        :muted="muted"
        playsinline
        loop
        preload="metadata"
      />
      <div
        class="custom-layout-video__pause"
        v-if="!controls && !isPlaying"
      ></div>
    </div>
  </Base>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue'
import { useVisibilityChange } from '@/utils/useVisibilityChange'

import Base, { IBaseProps } from './Base.vue'

export interface IVideoProps extends IBaseProps {
  src: string
  cover: {
    image: string
    width: number
    height: number
  }
  muted: boolean
}

const props = defineProps({
  props: {
    type: Object as PropType<IVideoProps>,
    default: {},
  },
})

const autoplayState = ref<null | boolean>(null)

const src = computed(() => props.props?.src ?? '')
const muted = computed(() => {
  // 默认静音
  // 若静音状态可以自动播放，则保持静音
  // 否则使用配置的 muted

  if (autoplayState.value === null) return true

  if (autoplayState.value) {
    return true
  } else {
    return props.props?.muted ?? true
  }
})
const poster = computed(() => props.props?.cover?.image ?? '')
const aspect = computed(() => {
  const cover = props.props?.cover
  return ((cover?.height / cover?.width) * 100).toFixed(2)
})

/* Video Ref */
const video = ref<HTMLVideoElement>()
const isPlaying = ref(false) // 在 controls 不显示时，显示播放图标

function play() {
  const promise = video.value?.play()

  if (autoplayState.value === null) {
    promise
      ?.then(() => (autoplayState.value = true))
      .catch(() => (autoplayState.value = false))
  }

  promise?.then(() => {
    isPlaying.value = true
  })

  return promise
}
function pause() {
  video.value?.pause()
  isPlaying.value = false
}

/* controls */
const controls = ref(false)
onMounted(() => {
  video.value?.addEventListener(
    'touchstart',
    () => {
      controls.value = true
    },
    { once: true }
  )
})

/* 解决自动播放的问题 */
const TRIGGER_EVENTS = ['touchstart', 'mousedown', 'mousewheel']
function trigger() {
  play()?.finally(unbindEvent)
}
function bindEvent() {
  TRIGGER_EVENTS.forEach((event) => {
    addEventListener(event, trigger, {
      once: true,
      capture: true,
    })
  })
}
function unbindEvent() {
  TRIGGER_EVENTS.forEach((event) => {
    removeEventListener(event, trigger, {
      capture: true,
    })
  })
}
onUnmounted(unbindEvent)

/* 是否完成初次加载 */
const loaded = ref(false)
onMounted(() => {
  video.value?.addEventListener(
    'loadedmetadata',
    () => {
      bindEvent()
      loaded.value = true
    },
    { once: true }
  )
})

/* 自动暂停与播放 */
let isFirst = true
let isPaused = false
function onEnter() {
  if (!isPaused) play()
}
function onLeave() {
  if (isFirst) {
    isFirst = false
    return
  }
  isPaused = !!video.value?.paused
  pause()
}

// KeepAlive
onActivated(onEnter)
onDeactivated(onLeave)

// 进入视口/离开视口
useVisibilityChange(video, (visible) => {
  if (visible) {
    onEnter()
  } else {
    onLeave()
  }
})
</script>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
} from 'vue'

export default defineComponent({
  name: 'CustomLayoutVideo',
})
</script>

<style lang="less" scoped>
.custom-layout-video {
  position: relative;
  width: 100%;
  height: 0;

  .custom-layout-video__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .custom-layout-video__pause {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem(40);
    height: rem(40);
    background-color: rgba(black, 0.5);
    border-radius: 50%;

    &::before {
      content: '';
      display: block;
      border: rem(14) solid transparent;
      border-left-color: rgba(white, 0.7);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-20%, -50%) scaleY(0.7);
    }
  }

  /* 完成视频首帧加载后 用视频自身撑开高度 */
  &.custom-layout-video--loaded {
    height: auto;
    padding-bottom: 0 !important;

    .custom-layout-video__video {
      position: initial;
      height: auto;
    }
  }
}
</style>
