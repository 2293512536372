<template>
  <div class="not-found">
    <Empty title="请使用小程序访问" description="该功能仅支持小程序中使用">
      <div class="not-found__buttons">
        <!-- <Button type="primary" round @click="router.back()">打开小程序</Button> -->
        <Button type="default" round @click="router.back()">返回</Button>
      </div>
    </Empty>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'

import Empty from '@/components/Empty'
import Button from '@/components/Button'

const router = useRouter()
</script>

<style lang="less" scoped>
.not-found {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .not-found__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    :deep(.van-button) {
      & + .van-button {
        margin-top: rem(10);
      }
    }
  }
}
</style>
