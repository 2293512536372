<template>
  <Base :props="props.props" v-if="list?.length">
    <div class="custom-layout-banner" :class="className">
      <van-swipe
        :autoplay="4000"
        :duration="400"
        indicator-color="rgba(255,255,255)"
        :stop-propagation="false"
      >
        <van-swipe-item
          v-for="{ src, url } in list"
          :key="src"
          @click="navigateTo(url)"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
        >
          <div
            class="custom-layout-banner__swipe"
            :style="{
              paddingBottom: `${aspect}%`,
            }"
          >
            <Image :src="src" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </Base>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'

import Image from '@/components/Image'
import Base, { IBaseProps } from './Base.vue'

import { ossResize, useCustomRouter, ICustomRoute } from '@/utils'

export interface IBannerProps extends IBaseProps {
  list: {
    image: string
    weapp_href?: ICustomRoute
  }[]
  size: 1 | 2 | 3
  border_round: boolean
}

const IMAGE_WIDTH_BASE = 1080

const props = defineProps({
  props: {
    type: Object as PropType<IBannerProps>,
    default: {},
  },
})

const aspect = computed(() => {
  const map = {
    1: 9 / 16,
    2: 3 / 2,
    3: 7 / 5,
  }
  const ratio = map[props.props.size] ?? map[1]
  return Number((ratio * 100).toFixed(2))
})

const list = computed(() => {
  const ossOptions = {
    m: 'fill' as 'fill',
    w: IMAGE_WIDTH_BASE,
    h: Math.round((IMAGE_WIDTH_BASE * aspect.value) / 100),
  }

  return (props.props?.list ?? []).map((v) => {
    let src = v.image
    if (src && aspect) {
      src = ossResize(src, ossOptions)
    }

    return {
      url: v.weapp_href,
      src,
    }
  })
})

const className = computed(() => {
  const className: string[] = []

  if (props.props.border_round) className.push('border-round')

  return className
})

const customRouter = useCustomRouter()
let touching = false
function navigateTo(to?: ICustomRoute) {
  if (device.isPC && touching) return
  customRouter.push(to)
}

function onTouchStart() {
  touching = false
}
function onTouchMove() {
  touching = true
}
function onTouchEnd() {
  setTimeout(() => {
    touching = false
  })
}
</script>

<script lang="ts">
import { defineComponent } from 'vue'
import { device } from '@/store'

export default defineComponent({
  name: 'CustomLayoutBanner',
})
</script>

<style lang="less" scoped>
.custom-layout-banner {
  position: relative;

  &.border-round {
    :deep(.van-swipe) {
      border-radius: rem(10);
      overflow: hidden;
    }
  }

  &__swipe {
    width: 100%;
    position: relative;
  }

  :deep(.van-swipe-item) {
    .myimage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
