import axios, { Canceler, CancelToken } from 'axios'
import { IAxiosRequestConfig } from './types'

const CancelToken = axios.CancelToken

/* 取消重复请求 */
export class Pending {
  map = new Map<
    string,
    {
      config: IAxiosRequestConfig
      cancel: Canceler
    }
  >()

  create(config: IAxiosRequestConfig): CancelToken {
    const key = this.createKey(config)

    this.cancel(key)

    const cancelToken = new CancelToken((cancel) => {
      this.map.set(key, {
        config,
        cancel,
      })
    })
    return cancelToken
  }

  finish(config: IAxiosRequestConfig) {
    const key = this.createKey(config)
    if (this.map.has(key)) this.map.delete(key)
  }

  private cancel(key: string) {
    const target = this.map.get(key)
    if (!target) return
    const { cancel, config } = target

    this.map.delete(key)
    cancel({ config } as any)
    // cancel 的参数本应是 字符串 message，此处用了非法传值
    // 以解决捕获的错误拿不到 config 的问题
  }

  private createKey(config: IAxiosRequestConfig) {
    const { url, method, cancelRepeatKey } = config
    return `${method}~${url}~${cancelRepeatKey}`
  }
}
