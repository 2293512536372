import { persistedstate } from '@/utils'

const state = persistedstate(
  'settings',
  {
    abnormalStatus: true, // 刷新订单数据
    abnormalNum: 10,
    setAbnormalStatus(value?: boolean) {
      state.abnormalStatus = value
    },
    setAbnormalNum(value: string | number) {
      state.abnormalNum = value
    },
  },
  (state) => {
    const { abnormalStatus, abnormalNum } = state
    return { abnormalStatus, abnormalNum }
  }
)

export const settings = readonly(state)
