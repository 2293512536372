import { createRouter, createWebHistory } from 'vue-router'
import qs from 'qs'

import { versionUpdate } from './plugins/versionUpdate'
import { authGuard } from './plugins/authGuard'
import { loginRedirect } from './plugins/loginRedirect'
import { progressBar } from './plugins/progressBar'
// import { firstVisit } from './plugins/firstVisit'
import { weixinGuard } from './plugins/weixinGuard'
import { pcGuard } from './plugins/pcGuard'
import { saveScrollPosition, getScrollPosition } from './plugins/scrollPosition'
import { log } from '@/utils/log'
import { setTitle } from './plugins/setTitle'
import { device } from '@/store'

import { routes } from './routes'
export { routes }

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: getScrollPosition,

  stringifyQuery(query) {
    return qs.stringify(query)
  },
  parseQuery(query) {
    return qs.parse(query) as any
  },
})
saveScrollPosition(router)
/* plugins */
versionUpdate(router)
weixinGuard(router)
authGuard(router)
pcGuard(router)
loginRedirect(router)
progressBar(router)
setTitle(router)
// firstVisit(router)

router.afterEach((to, from) => {
  let title = to.meta.title ? (to.meta.title as string) : '好订宝'
  if (!device.isWeChatMini) {
    title += '-好订宝'
  }
  document.title = title
  if (to.name == 'ClassRank' && to.query.goods_son_class) {
    to.meta.title = to.query.goods_son_class + '排行榜'
    document.title = to.query.goods_son_class + '排行榜'
  }
})

/*埋点*/
log(router)

export default router
