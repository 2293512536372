import { Router } from 'vue-router'
import { device, user } from '@/store'

/**
 * 除了注册页，当没有 token 则跳转至注册页
 * @param router
 */
export function weixinGuard(router: Router) {
  router.beforeEach((to, from, next) => {
    if (!device.isWeChatMini) return next()

    if (!to.meta?.weapp) return next()

    const query = new URLSearchParams()
    Object.entries({
      token: user.token,
      fair_id: user.selectMeeting,
      shop_id: user.selectShop,
      ...to.query,
    }).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        query.append(key, String(value))
      }
    })

    const queryString = query.toString()
    let url = to.meta.weapp

    if (queryString) url += `?${queryString}`

    if (to.name === 'Login') {
      wx.miniProgram.reLaunch({ url })
    } else {
      wx.miniProgram.navigateTo({ url })
    }
  })
}
