import { reactive, ref } from 'vue'

export function useDelayLoading(minDelay: number = 200) {
  const loading = ref(false)

  let loadingChangeTime = 0
  let loadingChangeTimer: number

  function set(value: boolean) {
    if (loadingChangeTimer) clearTimeout(loadingChangeTimer)

    const now = Date.now()

    if (value) {
      loading.value = true
    } else {
      const delay = Math.max(0, minDelay - (now - loadingChangeTime))
      loadingChangeTimer = setTimeout(() => {
        loading.value = false
      }, delay)
    }

    loadingChangeTime = now
  }

  return reactive({
    loading: loading,
    set,
  })
}
