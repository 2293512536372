<template>
  <div
    :id="id"
    :key="id"
    :style="{
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
    }"
    class="scan"
  ></div>

  <div
    :id="readerId"
    :style="{
      display: 'none',
    }"
  ></div>
</template>

<script lang="ts" setup>
import {
  Html5Qrcode,
  Html5QrcodeSupportedFormats,
  Html5QrcodeScannerState,
} from 'html5-qrcode'
import { Toast } from 'vant'

const props = defineProps<{
  width: number
  height: number
  aspectRatio: number
  onSuccess?: (decodedText: string) => Promise<unknown> | unknown
  onError?: (error: any) => void
  onState?: (state: Html5QrcodeScannerState) => void
}>()

const id = `qr-code-scanner-${props.width}-${props.height}-${props.aspectRatio}`
const readerId = `qr-code-reader-${props.width}-${props.height}-${props.aspectRatio}`

let scanner: Html5Qrcode

function updateState() {
  let state = scanner?.getState() ?? Html5QrcodeScannerState.UNKNOWN
  props.onState?.(state)
}

async function onSuccess(decodedText: string) {
  try {
    scanner?.pause(true)
    updateState()
    await props.onSuccess?.(decodedText)
  } catch (error) {
  } finally {
    scanner?.resume()
    updateState()
  }
}

function onError(error: any) {
  props.onError?.(error)
  console.error(error)
}

async function runScanner() {
  scanner = new Html5Qrcode(id, {
    formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    verbose: false,
  })

  updateState()

  const { width: w, height: h, aspectRatio } = props

  await start()
  updateState()

  async function start() {
    await scanner
      ?.start(
        {
          facingMode: 'environment',
        },
        {
          fps: 20,
          qrbox: {
            width: 400,
            height: 400,
          }
        },
        onSuccess,
        () => {}
      )
      .catch(onError)
  }
}

onMounted(runScanner)

onBeforeUnmount(() => {
  try {
    scanner?.stop?.()
    updateState()
    scanner?.clear?.()
  } catch (error) {
    console.error(error)
  }
})

defineExpose({
  toggleTorch: () => {
    scanner?.applyVideoConstraints({
      advanced: [{ torch: true } as any],
    })
  },

  scanFile(file: File) {
    const scanner = new Html5Qrcode(readerId)

    scanner
      .scanFile(file, true)
      .then(onSuccess, () => {
        Toast('没有识别到二维码')
      })
      .catch((error) => {
        Toast('初始化失败')
        console.error(error)
      })
      .finally(() => {
        scanner?.stop()
        scanner?.clear()
      })
  },
})
</script>

<style lang="less" scoped>
.scan {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
