import { throttle } from '../utils'

export type IOrientation = 'portrait' | 'landscape'

const state = reactive({
  w: 1,
  h: 1,
  orientation: 'portrait' as IOrientation,
})

export const viewport = readonly(state)

const resize = () => {
  const w = window.innerWidth
  const h = window.innerHeight

  state.w = (w >> 1) << 1
  state.h = (h >> 1) << 1
  state.orientation = h >= w ? 'portrait' : 'landscape'
}
resize()
addEventListener('resize', throttle(resize, { interval: 40 }))
