import { UnwrapNestedRefs } from '@vue/reactivity'
import merge from 'deepmerge'

import axios, { AxiosError, AxiosResponse } from 'axios'
import { IAxiosInstance, IAxiosRequestConfig } from '@/api/http/types'
import {
  isAxiosCancelError,
  isAxiosTimeoutError,
  TimeoutError,
} from '@/api/http/error'
import { config } from '@/config'

interface IStorage {
  getItem: (key: string) => any
  setItem: (key: string, value: any) => void
}

export interface IPersistedstateOptions {
  storage?: IStorage
  baseKey?: string
}

export const storage = window.localStorage
export const storageKeys: string[] = []
export const ready = ref(false)
export const uuid = ref<string | null>('')
export const token = ref<string | null>('')
export const isWeChatMini = ref(false)

function createHttp(axiosConfig: IAxiosRequestConfig) {
  const http = axios.create({
    timeout: config.http.timeout,
    loading: true,
    ...axiosConfig,
  }) as IAxiosInstance

  http.interceptors.request.use(
    /* 请求发送之前 */
    (config) => {
      if (!config.headers['Authorization']) {
        config.headers['Authorization'] = token.value
          ? 'Bearer ' + token.value
          : undefined
      }

      return config
    },

    /* 发送请求错误时 */
    (error) => {
      return Promise.reject(error)
    }
  )

  http.interceptors.response.use(
    /* 收到请求时 */
    (response) => {
      const data = response.data

      if (data) {
        switch (data.code) {
          case 0:
            return {
              error: null,
              data: data.data,
              response,
            } as any
        }
      }

      const error = new Error(data?.message ?? '接口数据异常')

      return {
        error,
        data: null,
        response,
      }
    },

    /* 收到请求错误时 */
    (error) => {
      const response = error.response as AxiosResponse<any> | undefined

      // 当请求被取消时 拿到的错误会丢失 config
      // 在 peding 里进行了处理，将 config 放在了 message 中进行传递

      if (!response) {
        if (isAxiosCancelError(error)) {
          throw error
        } else if (isAxiosTimeoutError(error as AxiosError)) {
          return {
            error: new TimeoutError('后端程序响应超时'),
            data: null,
            response,
          }
        }
      }

      return {
        error,
        data: null,
        response,
      }
    }
  )

  return { http }
}

const { http } = createHttp({
  baseURL: '__API__',
})

export function getCloudCache() {
  return http.get('/fair/get_cache', {
    params: {
      uuid: uuid.value,
    },
  })
}

export const queue: (() => Promise<any>)[] = []
let promise: Promise<any> | null = null
export function runQueue() {
  if (promise) return

  const fn = queue.shift()
  if (!fn) {
    promise = null
    return
  }
  promise = fn()
  promise
    .then(
      () => {},
      (error) => {
        console.error(`缓存设置失败`)
        console.error(error)
      }
    )
    .finally(() => {
      promise = null
      runQueue()
    })
}
export function setCloudCache(params: any) {
  const _token = token.value
  queue.push(() =>
    http.post(
      '/fair/set_cache',
      {
        uuid: uuid.value,
        ...params,
      },
      {
        headers: {
          Authorization: _token ? 'Bearer ' + _token : undefined,
        },
      }
    )
  )

  runQueue()
}

function persistedstateFactory(options: IPersistedstateOptions = {}) {
  const { baseKey = '' } = options

  function createKey(key: string) {
    return baseKey + key
  }

  return function <T extends object>(
    key: string,
    target: T,
    sources: (state: UnwrapNestedRefs<T>) => object
  ): UnwrapNestedRefs<T> {
    storageKeys.push(createKey(key))

    const reactiveTarget = (
      isReactive(target) ? target : reactive(target)
    ) as UnwrapNestedRefs<T>

    /* 监听对象变化，并存入 storage */
    watch(
      () => sources(reactiveTarget),
      (value) => {
        const _key = createKey(key)
        setState(_key, value, storage)

        if (key === 'user') {
          // 更新 token、uuid 时 会因为缺失值导致没法调用接口
          // 故此处取了 旧值||新值 其中存在的值
          token.value = token.value || (value as any).token
          uuid.value = uuid.value || (value as any).uuid
        }

        if (isWeChatMini.value && ready.value && uuid.value && token.value) {
          setCloudCache({
            [_key]: value,
          })
        }
      }
    )

    /* 获取 storage 的值，进行合并 */
    const rawTarget = toRaw(target)
    const savedState = getState(createKey(key), storage)
    if (typeof savedState === 'object' && savedState !== null) {
      const merged = merge(rawTarget, savedState, {
        arrayMerge: function (store, saved) {
          return saved
        },
        clone: false,
      })

      // FIXME: 期望合并进原始对象，暂时用这种糟糕的方式处理，有空再更换 merge 方法。
      Object.assign(rawTarget, merged)
    }

    return reactiveTarget
  }
}

/**
 * 创建一个可持久化的对象
 * @param {string} key - 存入 storage 的键值
 * @param {object} target 目标对象
 * @param {fuction} source 返回需要存入 storage 的数据，亲按原来的层级返回
 * @return {object} reactive(target)
 */
export const persistedstate = persistedstateFactory({
  baseKey: 'hdb',
})

export function getState(key: string, storage: IStorage) {
  const value = storage.getItem(key)

  try {
    return typeof value !== 'undefined' ? JSON.parse(value) : undefined
  } catch (err) {}

  return undefined
}

export function setState(key: string, state: object, storage: IStorage) {
  return storage.setItem(key, JSON.stringify(state))
}
