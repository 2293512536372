import { createWaterMarkText, splitText, persistedstate } from '../utils'
import { IUser } from '@/api'
import { LOGIN_MODE } from '@/views/User/Login/config'

export type IUnread = Record<'refund', number | undefined>

const state = persistedstate(
  'user',
  {
    token: null as string | null,
    setToken(value?: string) {
      if (!value) {
        state.setSelectMeeting()
        state.setSelectShop()
      }
      state.token = value || null
    },

    userData: null as IUser | null,
    setUserData(value?: IUser) {
      state.userData = value || null
    },
    fairData: {},
    setFairData(value?: any) {
      state.fairData = value || {}
    },

    selectCompany: false as boolean,
    setSelectCompany(value: boolean) {
      state.selectCompany = value
    },
    toOrder: false as boolean,
    setToOrder(value: boolean) {
      state.toOrder = value
    },


    selectMeeting: null as number | null,
    setSelectMeeting(value?: number) {
      state.selectMeeting = value || null
    },

    selectShop: null as number | null,
    setSelectShop(value?: number) {
      state.selectShop = value || null
    },

    needFillInfo: false,
    setNeedFillInfo(value: boolean) {
      state.needFillInfo = value
    },

    uuid: null as string | null,
    setUuid(value?: string) {
      state.uuid = value || null
    },

    lastLoginMode: LOGIN_MODE.SMS,
    setLastLoginMode(value: LOGIN_MODE) {
      state.lastLoginMode = value
    },

    liveEnterReaded: false,
    setLiveEnterReaded(value: boolean) {
      state.liveEnterReaded = value
    },
    cateSelect: [],
    setCateSelect(value: any) {
      state.cateSelect = value
    },
    is_buy: 1 as number | undefined,
    setIsbuy(value?: number) {
      state.is_buy = value
    },
  },
  (state) => {
    const {
      token,
      uuid,
      selectCompany,
      selectMeeting,
      selectShop,
      lastLoginMode,
      fairData
    } = state

    return {
      token,
      uuid,
      selectCompany,
      selectMeeting,
      selectShop,
      lastLoginMode,
      fairData
    }
  }
)

export const userWatermark = computed(() => {
  const data = state.userData as any
  const values = [
    data?.agent_user?.phone ||
    data?.phone ||
    data?.agent_user?.account_code ||
    data?.account_code,
    data?.agent_user?.real_name ?? data?.real_name,
  ]
    .filter((v) => !!v)
    .join(' ')

  return createWaterMarkText(splitText(values || '__title__'))
})

watch(
  () => state.token,
  (token) => {
    if (token) return
    state.setSelectCompany(false)
  },
  {
    immediate: true,
  }
)

export const user = readonly(state)
