import { Router } from 'vue-router'
import { user, device } from '@/store'
import { REDIRECT_KEY, createRedirectTo } from './loginRedirect'
import { SKUBUS } from '@/views/components/Sku/Sku.vue'

/**
 * PC访问 商品详情  打开SKU弹出框
 * @param router
 */
export function pcGuard(router: Router) {
  router.beforeEach((to, from, next) => {
    if( device.isPC && to.name == 'GoodsDetail'){
          if(to.query.goods_art_no){
            SKUBUS.emit('openSkuPC', to.query.goods_art_no)
          }
         return
    }
     return next()

  })
}
