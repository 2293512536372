<template>
  <div class="com-empty">
    <div class="icon" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>
    <EmptySvg  v-else class="icon" />
    <div class="title" v-if="title">{{ title }}</div>
    <div class="description" v-if="description">{{ description }}</div>
    <div class="append" v-if="$slots.default">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'Empty',
})
</script>

<script lang="ts" setup>
import EmptySvg from '@/assets/empty.svg?component'

defineProps<{
  title?: string
  description?: string
}>()
</script>

<style lang="less" scoped>
.com-empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: rem(20) rem(20);

  .icon {
    width: rem(163);
    height: rem(163);
  }

  .title {
    margin-top: rem(12);

    font-size: rem(15);
    color: @c-black-heavy;
  }
  .description {
    margin-top: rem(10);

    font-size: rem(12);
    color: @c-grey;
  }

  .append {
    margin-top: rem(10);
  }
}
</style>
