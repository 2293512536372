import { Router } from 'vue-router'
import { device } from '@/store'

/**
 * 路由跳转 加载进度条
 * @param router
 */
export function progressBar(router: Router) {


  router.beforeEach((to, from, next) => {
/*    if(!device.isWeChatMini){
      device.setProgress(false)
      device.setProgress(true)
    }*/
    next()
  })
  router.beforeResolve((to, from, next) => {
/*    if(!device.isWeChatMini){
      device.setProgress(false)
    }*/
    next()
  })
}
