<template>
  <div class="goods-card" @click="toDetail">
    <div class="goods-card__image-container">
      <Image class="goods-card__image" :src="coverOss" />

      <div class="goods-card__tag" v-if='tag'>
        {{ tag }}
      </div>

      <div class="goods-card__sampling" v-if='isSampling'>
        已订样
      </div>
      <div
        class="goods-card__index"
        v-if="index !== undefined && index !== null"
      >
        {{ index }}
      </div>
    </div>

    <div class="goods-card__info">
      <div class="goods-card__title-container">
        <div
          class="goods-card__rank"
          v-if="rank !== undefined && rank !== null"
        >
          <img class="goods-card__rank-icon" :src="rankIcon" />
          {{ rank }}
        </div>

        <div class="goods-card__title">
          {{ title }}
        </div>
      </div>

      <div class="goods-card__row">
        <div class="goods-card__prices">
          <Prices :value="price" size="l" bold diffSize rangeStyle="min" />

          <Prices
            v-if="listPrice"
            class="goods-card__list-prices"
            :value="listPrice"
            size="inherit"
            color="inherit"
            rangeStyle="min"
          />
        </div>

        <div class="goods-card__color" v-if="color">{{ color }}</div>
      </div>

      <div class="goods-card__row">
        <div class="goods-card__count">我已订{{ saveCount >= 0 ? saveCount : count || 0 }}</div>
        <div class="goods-card__other">{{ other }}</div>
      </div>
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import  { ref } from  'vue'
import { RouteLocationRaw,useRouter } from 'vue-router'
import { ossResize } from '@/utils'
import { userWatermark,device } from '@/store'
import { SKUBUS } from '@/views/components/Sku/Sku.vue'
import Image from '@/components/Image'
import Prices from '@/components/Prices'
import rankIcon from '@/assets/rank.svg'

const props = defineProps<{
  to?: RouteLocationRaw
  cover?: string
  tag?: string
  title?: string
  price?: number | number[] | string | string[]
  listPrice?: number | number[] | string | string[]
  other?: string
  color?: string
  count?: number | string
  number?: string
  index?: number
  rank?: number
  isSampling?: Boolean | Number
}>()

const title = computed(() => {
  return [props.number, props.title].filter((v) => !!v).join('-')
})
const router = useRouter()

const toDetail = ()=>{
  if (device.isPC && props.number) {
    SKUBUS.emit('openSkuPC', props.number)
  } else if (props.to) {
    router.push(props.to)
  }
}

let saveCount = ref(-1)

SKUBUS.on('ListSaveSku',(order)=>{
  if(order.hasOwnProperty(props.number)){
    saveCount.value = order[props.number!]
  }
})

const coverOss = computed(() => {
  return props.cover
    ? ossResize(
        props.cover,
        {
          preset: 'pad',
        },
        {
          watermark: {
            text: userWatermark.value,
          },
        }
      )
    : ''
})
</script>

<style lang="less" scoped>
.goods-card {
  background-color: white;
  border-radius: rem(2);

  .goods-card__image-container {
    height: 0;
    padding-bottom: 100%;
    position: relative;

    .goods-card__image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .goods-card__index {
      position: absolute;
      left: rem(5);
      top: rem(5);
      min-width: 2em;
      text-align: center;

      padding: rem(1) rem(4);
      border-radius: rem(8);
      background-color: rgba(black, 0.3);
      font-size: rem(10);
      color: white;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 40%;
    }

    .goods-card__tag {
      position: absolute;
      right: rem(5);
      top: rem(5);
      min-width: 4em;
      text-align: center;

      padding: rem(1) rem(4);
      border-radius: rem(4);
      background-color: #f55f5f;
      font-size: rem(10);
      color: white;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 40%;
    }

    .goods-card__sampling {
      position: absolute;
      right: rem(5);
      top: rem(5);
      min-width: 4em;
      text-align: center;

      padding: rem(1) rem(4);
      border-radius: rem(4);
      background: linear-gradient(318deg, #AF8FFF 0%, #84A3F0 100%);
      font-size: rem(10);
      color: white;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 40%;
    }
    .goods-card__tag~.goods-card__sampling {
      top:rem(30)
    }
  }

  .goods-card__info {
    padding: rem(10) rem(6);
  }

  .goods-card__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + .goods-card__row {
      margin-top: rem(2);
    }
  }

  .goods-card__title-container {
    display: flex;
    align-items: center;

    margin-top: rem(-4);
    margin-bottom: rem(4);
  }
  .goods-card__title {
    font-size: rem(16);
    color: @c-black-heavy;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .goods-card__rank {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    background-color: #eb4a4a;
    color: white;
    line-height: rem(18);
    font-size: rem(15);
    padding-left: rem(5);
    padding-right: rem(3);
    border-radius: rem(9) 0 0 rem(9);
    margin-right: rem(6);

    .goods-card__rank-icon {
      width: rem(10);
      height: rem(10);
      object-fit: contain;
      margin-right: rem(2);
    }
  }

  .goods-card__prices {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .goods-card__list-prices {
      margin-left: rem(4);
      color: @c-grey;
      font-size: rem(10);

      &::before {
        content: '原价';
      }
    }
  }

  .goods-card__color {
    flex-shrink: 0;
    margin-left: rem(4);

    font-size: rem(12);
    color: @c-black-light;
    background-color: #f2f2f2;
    border-radius: rem(2);
    padding: rem(1) rem(3);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40%;
  }

  .goods-card__count {
    flex-shrink: 0;

    font-size: rem(12);
    color: @c-grey;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .goods-card__other {
    font-size: rem(12);
    color: @c-grey;

    margin-left: rem(4);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
