<template>
  <van-list
    class="com-list"
    ref="listRef"
    :loading="loading"
    :error="error"
    :finished="finished"
    @update:error="onUpdateError"
  >
    <template #default v-if="$slots.default">
      <slot name="default" />
    </template>
    <!-- 底部加载中提示 -->
    <template #loading v-if="$slots.loading">
      <slot name="loading" />
    </template>
    <!-- 加载完成后的提示文案 -->
    <template #finished>
      <slot name="finished" v-if="$slots.finished" />
      <template v-else>
        <span class="text-line"></span>
        {{ finishedText }}
        <span class="text-line"></span>
      </template>
    </template>
    <!-- 加载失败后的提示文案 -->
    <template #error>
      <slot name="error" v-if="$slots.error" />
      <span v-else>
        <span class="text-line"></span>
        {{ errorText }}
        <span class="text-line"></span>
      </span>
    </template>
  </van-list>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'List',
  props: {
    error: Boolean,
    loading: Boolean,
    finished: Boolean,

    ...({} as {
      loadingText: StringConstructor
      offset: {
        type: (NumberConstructor | StringConstructor)[]
        default: number
      }
      direction: {
        type: import('vue').PropType<'up' | 'down'>
        default: string
      }
      immediateCheck: {
        type: BooleanConstructor
        default: boolean
      }
    }),

    finishedText: {
      type: String,
      default: '没有更多了',
    },
    errorText: {
      type: String,
      default: '请求失败，点击重新加载',
    },
  },
  emits: {
    ...({} as { [key in 'load']: null }),
  },
  setup(props, { attrs, emit }) {
    const listRef = ref<any>()
    function check() {
      listRef.value?.check?.()
    }

    const error = computed(() => {
      return !props.loading && props.error
    })

    const finished = computed(() => {
      return !error.value && props.finished
    })

    function onUpdateError() {
      // 如果不通过组件控制 error 会造成点击 slots.error 无法触发
      // 仅有点击 slots.error 才会触发 update:error
      // 故 van-list 触发 update:error 时
      // 且 List 组件没有监听 update:error，则直接触发 load 事件
      if (attrs?.['onUpdate:error']) return
      emit('load')
    }

    return {
      check,
      listRef,

      error,
      finished,
      onUpdateError,
    }
  },
})
</script>

<style lang="less" scoped>
.com-list {
  .text-line {
    display: inline-block;
    width: 1.14em;
    height: 1px;
    transform: scaleY(0.5);
    background-color: @c-grey-light;
    vertical-align: 0.3em;
    margin: 0 0.3em;
  }

  :deep(.van-list__loading) {
    user-select: none;
  }

  :deep(.van-list__error-text) {
    cursor: pointer;
    user-select: none;
  }

  :deep(.van-list__finished-text) {
    user-select: none;
  }
}
</style>
