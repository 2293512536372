<template>
  <van-pull-refresh
    class="pull-refresh"
    v-bind="attrs"
    :headHeight="headHeight"
    :pullDistance="pullDistance"
  >
    <template #default v-if="$slots.default">
      <slot name="default" />
    </template>

    <!-- 下拉 -->
    <template #pulling="{ distance }">
      <SpinnerPull :percent="getPercent(distance)" /> 下拉即可刷新...
    </template>

    <!-- 释放 -->
    <template #loosing="{ distance }">
      <SpinnerPull :percent="getPercent(distance)" /> 释放即可刷新...
    </template>

    <!-- 加载中 -->
    <template #loading>
      <SpinnerPull :percent="1" :loading="true" /> 正在获取数据...
    </template>

    <!-- 其他未使用 slot -->
    <template #normal v-if="$slots.normal">
      <slot name="normal" />
    </template>
    <template #success v-if="$slots.success">
      <slot name="success" />
    </template>
  </van-pull-refresh>
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { SpinnerPull } from '@/components/Spinner'

export default defineComponent({
  name: 'PullRefresh',
  components: {
    SpinnerPull,
  },
  props: {
    headHeight: {
      type: Number,
      default: 50,
    },
    pullDistance: {
      type: Number,
      default: 50,
    },
  },
  setup(props, { attrs }) {
    function getPercent(distance: number) {
      const start = 25
      return (distance - start) / (props.pullDistance - start)
    }

    return {
      ...toRefs(props),
      attrs,
      getPercent,
    }
  },
})
</script>

<style lang="less" scoped>
.pull-refresh {
  :deep(.spinner-pull) {
    font-size: 20px;
    color: @c-grey;
    margin-right: 8px;
  }
  :deep(.van-pull-refresh__head) {
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
