import { reactive } from 'vue'
import { persistedstate } from '@/utils'

export type IShepherdOptions = {
  name: string
  version: number
}

const readed = persistedstate(
  'shepherd',
  {
    value: <Record<string, number>>{},
  },
  ({ value }) => ({
    value: { ...value },
  })
)
const queue = reactive<string[]>([])

function isReaded(options: IShepherdOptions) {
  const { name, version } = options

  const theVersion = readed.value[name]
  if (!theVersion) return false

  return theVersion >= version
}

export function register(options: IShepherdOptions) {
  const { name } = options

  if (isReaded(options)) {
    return
  }

  if (queue.includes(name)) {
    console.warn(`Shepherd name 重复， '${name}' 已存在。`)
  } else {
    queue.push(name)
  }
}

export function unregister(options: IShepherdOptions) {
  const { name } = options

  const index = queue.indexOf(name)
  if (~index) queue.splice(index, 1)
}

export function setReaded(options: IShepherdOptions) {
  unregister(options)

  const { name, version } = options
  readed.value = {
    ...readed.value,
    [name]: version,
  }
}

export function canShow(options: IShepherdOptions) {
  const { name } = options

  return queue[0] === name
}
