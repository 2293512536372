<template>
  <Overlay
    :show="show && lockClick"
    :duration="0"
    :z-index="zIndex"
    class="loading-toast__overlay"
  />
  <transition name="fade" appear>
    <div class="loading-toast" v-bind="attrs" :style="{ zIndex }" v-show="show">
      <SpinnerPoint />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { Overlay } from 'vant'
import { SpinnerPoint } from '@/components/Spinner'

export default defineComponent({
  name: 'LoadingToast',
  components: {
    Overlay,
    SpinnerPoint,
  },
  props: {
    /* 显示 */
    show: {
      type: Boolean,
      default: false,
    },

    /* 层级 */
    zIndex: {
      type: Number,
      default: 1100,
    },

    /* 不允许用户操作 */
    lockClick: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { attrs }) {
    return {
      ...toRefs(props),
      attrs,
    }
  },
})
</script>

<style lang="less" scoped>
.loading-toast__overlay {
  background-color: transparent;
}

.loading-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(@c-black, 0.9);
  font-size: rem(11);
  padding:  rem(35)  rem(35)  rem(30) ;
  border-radius:rem(5);

  :deep(.spinner-point__item + .spinner-point__item) {
    margin-left: rem(10);
  }
}

.fade-enter-active {
  transition: opacity 0.3s ease-out;
}

.fade {
  &-enter-active {
    animation: 0.3s fade-in both ease-out;
  }

  &-leave-active {
    animation: 0.3s fade-out both ease-in;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
