<template>
  <transition name="fade">
    <div class="myprogressbar" v-show="loading">
      <div :style="{ transform: `scaleX(${percentage / 100})` }"></div>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, watchEffect } from 'vue'
import { useCountdown } from '@/utils'
import { device } from '@/store'

export default defineComponent({
  name: 'MyProgressBar',
  props: {
    /* 预期持续时间 */
    duration: {
      type: Number,
      default: 8000,
    },

    /* 进度条开始位置 */
    start: {
      type: Number,
      default: 10,
    },

    /* 完成加载之前的等待位置 */
    end: {
      type: Number,
      default: 80,
    },
  },
  setup(props) {
    const loading = computed(() => device.progress)

    const countdown = useCountdown({
      interval: 30,
    })

    const start = computed(() => Math.max(0, props.start))
    const end = computed(() => Math.min(100, props.end))
    const long = computed(() => {
      return end.value - start.value
    })
    const step = computed(() => {
      return long.value / props.duration
    })
    const percentage = computed(() => {
      if (loading.value) {
        return long.value - countdown.value * step.value + start.value
      } else {
        return 100
      }
    })

    watchEffect(() => {
      if (loading.value) {
        countdown.run(props.duration)
      } else {
        countdown.stop()
      }
    })

    return {
      loading,
      percentage,
    }
  },
})
</script>

<style lang="less" scoped>
@import '../../styles/index.less';

.myprogressbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  z-index: 5000;

  > div {
    background-color: @c-primary;
    height: 100%;
    transform-origin: left;
  }
}

.fade-enter-active {
  transition: opacity 1s ease;
}
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
