<template>
  <div v-if="type === 'banner'" class="custom-layout-skeleton-item banner">
    <Image src="" />
  </div>

  <div
    v-if="type === 'image-group'"
    class="custom-layout-skeleton-item image-group"
  >
    <Image src="" />
    <Image src="" />
    <Image src="" />
    <Image src="" />
  </div>

  <div v-if="type === 'text'" class="custom-layout-skeleton-item text">
    <div>
      <div class="skeleton" style="width: 70%"></div>
    </div>
    <div>
      <div class="skeleton" style="width: 20%"></div>
    </div>
    <div>
      <div class="skeleton" style="width: 80%"></div>
    </div>
    <div>
      <div class="skeleton" style="width: 30%"></div>
    </div>
    <div>
      <div class="skeleton" style="width: 60%"></div>
    </div>
    <div>
      <div class="skeleton" style="width: 50%"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import Image from '@/components/Image'

export default defineComponent({
  components: {
    Image,
  },
  props: {
    type: String as PropType<'banner' | 'image-group' | 'text'>,
  },
  setup(props) {},
})
</script>

<style lang="less" scoped>
.custom-layout-skeleton-item {
  font-size: rem(16);
  .skeleton {
    background-color: #efedee;
    height: 1em;
    display: inline-block;
  }

  &.banner {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;

    :deep(.van-image) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.image-group {
    font-size: rem(20);
    line-height: 1.4;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :deep(.van-image) {
      width: rem(60);
      height: rem(60);
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &.text {
    line-height: 1.8;
  }

  .info-bar {
    background-color: white;
    padding: 0 rem(16);
    font-size: rem(20);
    margin-top: rem(10);
    .title {
      margin-bottom: rem(15);
      line-height: rem(45);
    }

    .content {
      line-height: 1.5;
    }
  }
}
</style>
