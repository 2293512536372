import { AxiosResponse } from 'axios'
import type { DeepReadonly, UnwrapRef } from 'vue'
import { IAxiosResponse, isAxiosCancelError } from './http'

export interface IUseQueryOptions<T> {
  immediate?: boolean
  updateQuery?: (prev: T | null, current: T) => T | Promise<T>
  onError?: (error: Error) => void
  keepDataOnRefetch?: boolean
}

const DEFAULT_UPDATE_QUERY = <T>(prev: T, current: T) => current

export function useQuery<T = object>(
  fetch: () => Promise<IAxiosResponse<T>>,
  options: IUseQueryOptions<T> = {}
) {
  const { immediate = true, updateQuery, onError, keepDataOnRefetch } = options

  const state = reactive<{
    data: T | null
    response: AxiosResponse<T> | null
    error: any | null
    loading: boolean
  }>({
    data: null,
    response: null,
    error: null,
    loading: false,
  })

  function clearState(keepData: boolean) {
    if (!keepData) state.data = null
    state.response = null
    state.error = null
  }

  async function fetchMore() {
    state.loading = true

    try {
      const res = await fetch()
      state.response = res.response as UnwrapRef<AxiosResponse<T>>

      if (res.data) {
        const updated = (updateQuery ?? DEFAULT_UPDATE_QUERY)(
          state.data as T,
          res.data
        )
        state.data = (
          updated instanceof Promise ? await updated : updated
        ) as UnwrapRef<T>
      }

      if ('error' in res) state.error = res.error

      state.loading = false
    } catch (error) {
      if (isAxiosCancelError(error as Error)) {
        state.error = null
      } else {
        state.error = error
        state.loading = false
      }
    } finally {
      if (state.error) {
        onError?.(state.error)
      }
    }
  }

  async function refetch() {
    clearState(!!keepDataOnRefetch)
    await fetchMore()
  }

  if (immediate) {
    refetch()
  }

  const res = reactive({
    ...toRefs(state),
    clearState,
    refetch,
    fetchMore,
  })

  return readonly(res) as unknown as typeof res
}

export interface IUseMutationOptions {
  onError?: (error: Error) => void
}

interface IUseMutationState<T> {
  data: T | null
  error: any | null
  loading: boolean
  called: boolean
}

export function useMutation<T = object>(
  mutation: () => Promise<IAxiosResponse<T>>,
  options: IUseMutationOptions = {}
): DeepReadonly<[() => Promise<IAxiosResponse<T>>, IUseMutationState<T>]> {
  const { onError } = options

  const state = reactive<IUseMutationState<T>>({
    data: null,
    error: null,
    loading: false,
    called: false,
  })

  async function mutate() {
    state.loading = true
    state.called = true
    state.data = null
    state.error = null

    try {
      const res = await mutation()

      if (res.data) {
        state.data = res.data as UnwrapRef<T>
      }

      if ('error' in res) state.error = res.error

      return res
    } catch (error) {
      if (isAxiosCancelError(error as Error)) {
        state.error = null
      } else {
        state.error = error
      }
    } finally {
      state.loading = false
      if (state.error) {
        onError?.(state.error)
      }
    }
  }

  return readonly([mutate, state]) as DeepReadonly<
    [() => Promise<IAxiosResponse<T>>, IUseMutationState<T>]
  >
}
