const state = reactive({
  orderKey: 0, // 刷新订单数据
  bank: {} as Object,
  pass:false,
  updateOrderKey() {
    if (state.orderKey < Number.MAX_SAFE_INTEGER) {
      state.orderKey += 1
    } else {
      state.orderKey = 0
    }
  },

  /* 扫一扫 或 PC端订单页添加商品 */
  targetGoodsArtNo: void 0 as string | undefined,
  setTargetGoodsArtNo(value?: string) {
    state.targetGoodsArtNo = value
  },
  setbank(value: string | number) {
    state.bank = value
  },
  setPass(value?: Boolean) {
    state.pass = value || false
  },
})

export const order = readonly(state)
