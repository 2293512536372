import { http } from './http'
import { device } from '@/store'
import { user } from '@/store/user'

const pubParams = {
  channel: device.isPC
    ? 'hdb-pc'
    : device.isWeChatMini
    ? 'hdb-weapp'
    : 'hdb-h5',
  uuid: user.uuid,
}

/*
 * 获取uuid
 * */
export function getUUid() {
  return http.get('/uuid', {
    loading: false,
  })
}

/*
 * 埋点接口
 *
 *
 *
 * */
export function setLog(params: {
  page: unknown
  page_url: string
  describe: any
}) {
  //返回埋点接口
  function setLogFun(params: {
    page: unknown
    page_url: string
    describe: any
  }) {
    http
      .post(
        '/record/point',
        {
          ...pubParams,
          ...params,
          type: 0,
        },
        {
          loading: false,
          cancelRepeatKey: JSON.stringify(params),
        }
      )
      .then()
  }

  if (pubParams.uuid) {
    setLogFun(params)
  } else {
    //uuid 不存在
    getUUid().then((res) => {
      user.setUuid(res.data.uuid)
      pubParams.uuid = user.uuid
      setLogFun(params)
    })
  }
}
