import { onUnmounted, ref, watch } from 'vue'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

import { mountComponent } from '@/utils'
import template from './PhotoSwipe.vue'

let el: HTMLElement
function mountElement() {
  const component = mountComponent(template).instance
  el = component.$el
  return el
}

function getElement() {
  return el ?? mountElement()
}

function createInstance(items: PhotoSwipe.Item[], options: PhotoSwipe.Options) {
  const el = getElement()
  const pswp = new PhotoSwipe(el, PhotoSwipeUI_Default, items, {
    history: false,
    shareEl: false,
    zoomEl: false,
    fullscreenEl: false,
    tapToClose: true,
    closeElClasses: [],
    pinchToClose: false,
    maxSpreadZoom: 2,
    getDoubleTapZoom: function (isMouseClick, item) {
      if (isMouseClick) {
        return 1
      } else {
        return item.initialZoomLevel! === 1 ? item.fitRatio ?? 1 : 1
      }
    },
    ...options,
  })

  /* 更新图片实际尺寸 */
  pswp.listen('imageLoadComplete', (index, item) => {
    const img = new Image()
    img.src = item.src!
    img.onload = () => {
      item.w = img.width
      item.h = img.height
      pswp.updateSize(true)
    }
  })

  return pswp
}

export function photoSwipe(
  items: PhotoSwipe.Item[],
  options: PhotoSwipe.Options = {}
) {
  const pswp = createInstance(items, options)
  pswp.init()

  return pswp
}

export function usePhotoSwipe() {
  const pswp = ref<ReturnType<typeof photoSwipe>>()

  function open(items: PhotoSwipe.Item[], options: PhotoSwipe.Options = {}) {
    pswp.value = photoSwipe(items, options)
  }

  const listenQueue: any[] = []

  const useListen: ReturnType<typeof photoSwipe>['listen'] = (
    eventName,
    callback
  ) => {
    listenQueue.push({ eventName, callback })
  }

  watch(
    () => pswp.value,
    (pswp) => {
      if (!pswp) return

      listenQueue.forEach(({ eventName, callback }) => {
        pswp.listen(eventName, callback)
      })
    }
  )

  function close() {
    if (!pswp.value) return
    pswp.value.close()
  }

  onUnmounted(() => pswp.value?.close())
  useListen('close', () => {
    pswp.value = undefined
  })

  return {
    open,
    close,
    useListen,
    instance: pswp,
  }
}
