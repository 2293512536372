<template>
  <Login
    v-show="page === LOGIN_PAGE.LOGIN"
    v-model:username="username"
    :countdown="countdown.value"
    @page="updatePage"
    @sendSMS="sendSMS"
  />

  <ForgotPassword
    v-if="page === LOGIN_PAGE.FORGOT"
    v-model:username="username"
    :countdown="countdown.value"
    @page="updatePage"
    @sendSMS="sendSMS"
  />
</template>

<script lang="ts" setup>
import { Toast } from 'vant'

import { useBackgroundColor } from '@/store'
import { useCountdown, yup } from '@/utils'
import * as api from '@/api'
import { SMS_TIME_INTERVAL, LOGIN_PAGE } from './config'

import Login from './components/Login.vue'
import ForgotPassword from './components/ForgotPassword.vue'
import { useRoute } from 'vue-router'

useBackgroundColor('#fff')

const route = useRoute()

const page = ref(
  (() => {
    const key = (route.query?.login_page as keyof typeof LOGIN_PAGE) || 'LOGIN'

    return LOGIN_PAGE[key] ?? LOGIN_PAGE.LOGIN
  })()
)
function updatePage(value: LOGIN_PAGE) {
  page.value = value
}

const username = ref('')

/* 短信 */
const countdown = useCountdown({
  format(value) {
    return Math.floor(value / 1000)
  },
})

async function sendSMS() {
  try {
    await yup
      .string()
      .label('手机号')
      .required()
      .length(11, '请输入有效的手机号')
      .validate(username.value)
  } catch (error: any) {
    Toast(error?.message || '数据校验错误')
    return
  }

  if (countdown.value <= 0) {
    const { error } = await api.user.sendCode({
      phone: username.value,
    })
    api.errorProcessor({ error })
    countdown.run(SMS_TIME_INTERVAL)
  }
}
</script>

<style lang="less" scoped>
.login {
  margin-bottom: rem(20);
  .safe-area-inset-bottom();

  .login-logo {
    width: rem(295);
    height: rem(104);
    display: block;
    margin: 0 auto;
    margin-top: rem(60);
  }

  .login-form {
    padding: 0 rem(20);
    margin-top: rem(20);

    .login-form__input {
      font-size: rem(16);
      padding: rem(8) 0;

      & + .login-form__input {
        margin-top: rem(10);
      }

      &::after {
        display: block !important;
        border-bottom-color: @c-border;
        transform: scaleY(0.5);
        left: 0;
        right: 0;
      }

      &.login-form__input--focus::after {
        border-bottom-color: @c-primary;
      }

      :deep(.van-field__control) {
        height: rem(40);
      }
    }

    .login-form__mainbutton {
      margin-top: rem(20);
      font-size: rem(17);
      font-weight: bold;
    }
    .login-form__send-sms {
      background-color: rgba(@c-grey-light, 0.3);
      padding-left: rem(14);
      padding-right: rem(14);
    }
  }

  .login-forgot {
    text-align: right;
    margin-top: rem(8);
    .login-forgot__link {
      color: @c-black;
      font-size: rem(14);

      &:active {
        opacity: 0.8;
      }
    }
  }

  .login-type {
    margin-top: rem(16);
    padding: 0 rem(10);

    .login-type__title {
      font-size: rem(14);
      color: @c-grey;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before,
      &::after {
        content: '';
        display: block;
        height: rem(1);
        background-color: @c-border;
        flex-grow: 1;
        margin: 0 rem(12);
        transform: scaleY(0.5);
      }
    }

    .login-type__list {
      display: flex;
      justify-content: space-around;
      margin-top: rem(16);
      padding: 0 rem(33);
    }

    .login-type__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.8;
      }
    }

    .login-type__icon {
      width: rem(44);
      height: rem(44);
    }

    .login-type__text {
      font-size: rem(11);
      color: @c-black;
      margin-top: rem(20);
    }
  }

  .login-contact {
    .login-contact__list {
      display: flex;
      justify-content: space-around;
      margin: 0 rem(20);
      margin-top: rem(50);
    }

    :deep(.login-contact__item) {
      position: relative;
      padding-right: rem(23);
      padding-left: rem(50);
      line-height: rem(46);
      background-color: #f4f4f4;
      border-radius: rem(23);
      font-size: rem(16);
      color: @c-black-light;
      user-select: none;

      &:active {
        opacity: 0.8;
      }

      @icon-margin: rem(4);
      > .icon {
        border-radius: 50%;
        background-color: @c-primary;
        color: white;
        position: absolute;
        font-size: rem(46) - @icon-margin * 2;
        left: @icon-margin;
        top: @icon-margin;
      }
    }

    .login-contact__tips {
      font-size: rem(11);
      color: @c-grey;
      text-align: center;
      margin-top: rem(10);
      padding: 0 rem(20);
    }
  }
}
</style>
