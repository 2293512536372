import { onMounted, onUnmounted, reactive, Ref, watch } from '@vue/runtime-core'
import ResizeObserver from 'resize-observer-polyfill'
import { getContentRect } from 'resize-observer-polyfill/src/utils/geometry'
getContentRect

/**
 * 监听目标对象的宽高变化，运行回调
 * @return 解除监听
 */
export function resizeObserver(
  element: HTMLElement,
  callback: (rect: { width: number; height: number }) => void
) {
  let ob: ResizeObserver
  let { width, height } = getContentRect(element)
  ob = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const entry = entries[0]
    const rect = entry.contentRect

    /* 不同浏览器存在精度问题 */
    if (
      Math.round(width) !== Math.round(rect.width) ||
      Math.round(height) !== Math.round(rect.height)
    ) {
      callback({ width: rect.width, height: rect.height })
    }

    width = rect.width
    height = rect.height
  })
  ob.observe(element)

  return () => ob.disconnect()
}

export function useResizeRect(ref: Ref<HTMLElement | undefined>) {
  const state = reactive({
    width: 0,
    height: 0,
  })

  let clearObserver: Function
  watch(
    () => ref.value,
    (el) => {
      if (!el) return

      const { width, height } = getContentRect(el)
      state.width = width
      state.height = height

      clearObserver = resizeObserver(el, ({ width, height }) => {
        state.width = width
        state.height = height
      })
    },
    {
      immediate: true,
      flush: 'post',
    }
  )
  onUnmounted(() => clearObserver?.())

  return state
}
