import { watch } from 'vue'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { user } from '@/store/user'
import { config } from '@/config'

import { Pending } from './peding'
import { loading } from './loading'
import {
  AuthError,
  isAxiosCancelError,
  isAxiosTimeoutError,
  TimeoutError,
} from './error'
import { IAxiosInstance, IAxiosRequestConfig } from './types'

export function createHttp(axiosConfig: IAxiosRequestConfig) {
  const http = axios.create({
    timeout: config.http.timeout,
    loading: true,
    ...axiosConfig,
  }) as IAxiosInstance

  const pending = new Pending()

  http.interceptors.request.use(
    /* 请求发送之前 */
    (config) => {
      /* 取消重复请求 - 取消未完成的 pending，添加新的 pending*/
      config.cancelToken = pending.create(config)

      /* 加载状态 */
      loading.show(config)

      return config
    },

    /* 发送请求错误时 */
    (error) => {
      return Promise.reject(error)
    }
  )

  http.interceptors.response.use(
    /* 收到请求时 */
    (response) => {
      const config = response.config as IAxiosRequestConfig

      /* 加载状态 */
      loading.hide(config)

      /* 取消重复请求 - 移除已完成的 pending */
      pending.finish(config)

      const data = response.data

      if (data) {
        switch (data.code) {
          case 0:
            return {
              error: null,
              data: data.data,
              response,
            } as any
          case 40006:
            user.setSelectCompany(false)
            return {
              error: new Error('未选择组织'),
              data: null,
              response,
            } as any
          case 40007:
            user.setToken()
            return {
              error: new AuthError('当前没有所属组织'),
              data: null,
              response,
            } as any
          case 40008:
            user.setToken()
            return {
              error: new AuthError('当前没有所属组织'),
              data: null,
              response,
            } as any
          case 400911:
            user.setToOrder(true)
            return {
              error: new AuthError('当前不在白名单，且不在ip范围内'),
              data: null,
              response,
            } as any
          case 400997:
            user.setSelectMeeting()
            return {
              error: new AuthError('当前订货会暂不对您开放，请等待运营通知'),
              data: null,
              response,
            } as any
          case 400998:
            user.setSelectShop()
            return {
              error: new AuthError(''),
              data: null,
              response,
            } as any
          case 400999:
            user.setSelectMeeting()
            return {
              error: new AuthError('当前订货会有变更，请重新选择订货会'),
              data: null,
              response,
            } as any
        }
      }

      const error = new Error(data?.message ?? '接口数据异常')

      return {
        error,
        data: null,
        response,
      }
    },

    /* 收到请求错误时 */
    (error) => {
      const response = error.response as AxiosResponse<any> | undefined

      // 当请求被取消时 拿到的错误会丢失 config
      // 在 peding 里进行了处理，将 config 放在了 message 中进行传递
      if (response?.config || error?.config || error?.message?.config) {
        /* 加载状态 */
        loading.hide(
          response?.config ?? (error?.config || error?.message?.config)
        )
      }

      if (!response) {
        if (isAxiosCancelError(error)) {
          throw error
        } else if (isAxiosTimeoutError(error as AxiosError)) {
          return {
            error: new TimeoutError('后端程序响应超时'),
            data: null,
            response,
          }
        }
      }

      switch (response?.status) {
        case 401:
          // token 失效
          user.setToken()

          return {
            error: new AuthError('登录状态已失效，请重新登录'),
            data: null,
            response,
          }
      }

      return {
        error,
        data: null,
        response,
      }
    }
  )

  /* 添加 Authorization */
  watch(
    () => user.token,
    (token) => {
      const newToken = token ? 'Bearer ' + token : undefined
      http.defaults.headers['Authorization'] = newToken
    },
    {
      immediate: true,
    }
  )

  return { http }
}
