<template>
  <div class="com-scanner">
    <Result
      class="com-scanner__placeholder"
      v-if="
        !error &&
        ![
          Html5QrcodeScannerState.SCANNING,
          Html5QrcodeScannerState.PAUSED,
        ].includes(state)
      "
      title="初始化中"
    />
    <Result
      v-if="error"
      icon="failure"
      title="摄像头初始化失败"
      :description="error?.message"
    >
      <Button type="primary" @click="error = null" round>重试</Button>
    </Result>

    <ScannerBase
      v-else
      ref="baseRef"
      :width="size.w"
      :height="size.h"
      :aspectRatio="aspectRatio"
      :key="[size.w, size.h, aspectRatio].join('-')"
      @success="onSuccess"
      @error="onError"
      @state="onState"
    />

    <div
      v-show="state === Html5QrcodeScannerState.SCANNING"
      class="com-scanner__animation"
      :style="{
        width: 400 + 'px',
      }"
    >
      <div class="com-scanner__animation-radial"></div>
    </div>

    <div class="com-scanner__close" @click="onClose">
      <Icon use="back" />
    </div>

    <!-- <div class="com-scanner__torch" @click="baseRef?.toggleTorch()">
      手电筒
    </div> -->

    <div class="com-scanner__album">
      <input
        class="com-scanner__album-input"
        type="file"
        accept="image/*"
        @change="onFile"
      />
      照片
    </div>
  </div>
</template>

<script lang="ts" setup>
import { viewport } from '@/store'
import { Html5QrcodeScannerState } from 'html5-qrcode'
import Button from '../Button'
import Icon from '../Icon'
import Result from '../Result'

import ScannerBase from './ScannerBase.vue'

const emit = defineEmits<{
  (event: 'close'): void
}>()

const props = defineProps<{
  onSuccess?: (decodedText: string) => Promise<unknown> | unknown
}>()

const baseRef = ref()

const aspectRatio = computed(() => {
  const aspect = viewport.w / viewport.h
  const max = 16 / 9
  const min = 1
  return aspect
})

const size = computed(() => {
  if (viewport.orientation === 'portrait') {
    // 竖屏
    return {
      w: viewport.w,
      h: viewport.h,
    }
  } else {
    // 横屏
    return {
      w: viewport.w,
      h: viewport.h,
    }
  }
})

async function onSuccess(decodedText: string) {
  await props.onSuccess?.(decodedText)
}

const state = ref(Html5QrcodeScannerState.UNKNOWN)
function onState(newState: Html5QrcodeScannerState) {
  state.value = newState
}

function onClose() {
  emit('close')
}

const error = ref<any>()
function onError(err: any) {
  error.value = err
  console.log(111111111, err)
}

// function toggleTorch() {}

async function onFile(e: Event) {
  const file = (e.target as any)?.files?.[0]
  if (!file) {
    return
  }

  await baseRef.value?.scanFile(file)
}
</script>

<style lang="less" scoped>
.com-scanner {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  overscroll-behavior: contain;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  touch-action: none;
  user-select: none;

  .com-scanner__placeholder {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }

  .com-scanner__close {
    position: absolute;
    z-index: 1;
    top: rem(30);
    left: rem(15);

    width: rem(24);
    height: rem(24);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: @c-black-light;
    background-color: white;
    font-size: rem(14);

    :deep(.icon) {
      transform: translateX(-10%);
    }
  }

  .com-scanner__torch {
    position: absolute;
    z-index: 1;
    bottom: rem(30);
    left: rem(15);

    width: rem(48);
    height: rem(48);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: @c-black-light;
    background-color: white;
    font-size: rem(14);

    :deep(.icon) {
      transform: translateX(-10%);
    }
  }
  .com-scanner__album {
    position: absolute;
    z-index: 1;
    bottom: rem(30);
    right: rem(15);

    width: rem(48);
    height: rem(48);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: @c-black-light;
    background-color: white;
    font-size: rem(14);

    :deep(.icon) {
      transform: translateX(-10%);
    }

    .com-scanner__album-input {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }

  .com-scanner__animation {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    padding-bottom: 80%;

    background-image: radial-gradient(
      at bottom,
      rgba(@c-primary, 0.7) 0%,
      rgba(@c-primary, 0) 70%,
      rgba(@c-primary, 0) 100%
    );

    animation: scanner-move 1.5s linear infinite;
    transform-origin: 0 0;
    @keyframes scanner-move {
      0% {
        transform: translate(-50%, -20%) scaleY(0);
      }
      60% {
        transform: translate(-50%, 0%) scaleY(0.02);
      }
      100% {
        transform: translate(-50%, 20%) scaleY(0);
      }
    }
  }
}
</style>
