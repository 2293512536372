<template>
  <MyHeader back-button @clickBack="emit('page', LOGIN_PAGE.LOGIN)" />

  <template v-if="!userInfo.ticket">
    <Input
      v-model="codeForm.phone"
      label="手机号"
      placeholder="请输入手机号"
      tabindex="0"
      autofocus
      clearable
    />

    <Input
      v-model="codeForm.code"
      label="验证码"
      placeholder="请输入短信验证码"
      maxlength="6"
      tabindex="1"
      clearable
      @keypress.enter="submitCode"
    >
      <template #right-icon>
        <Button
          class="login-form__send-sms"
          size="small"
          :disabled="countdown > 0"
          @click="emit('sendSMS')"
          type="default"
          round
        >
          {{ countdown > 0 ? `已发送 (${countdown})` : '获取验证码' }}
        </Button>
      </template>
    </Input>

    <div class="main-button">
      <Button round block @click="submitCode" :disabled="disabledNextButton"
        >下一步</Button
      >
    </div>
  </template>

  <template v-else>
    <NoticeBar
      text="为了您的账号安全，您修改密码后会将其他已登录该账号的设备进行踢出"
    />

    <Input :model-value="userInfo.account_name" label="用户名" readonly />

    <Input
      v-model="form.password"
      label="新密码"
      placeholder="请输入新密码"
      :type="visiblePassword ? 'text' : 'password'"
      @click-right-icon="visiblePassword = !visiblePassword"
      maxlength="30"
      tabindex="2"
      clearable
    >
      <template #right-icon>
        <Icon class="eye" :use="visiblePassword ? 'eyeopen' : 'eyeclose'" />
      </template>
    </Input>

    <Input
      v-model="form.confirm_password"
      label="确认密码"
      placeholder="请输入确认密码"
      :type="visiblePassword ? 'text' : 'password'"
      @click-right-icon="visiblePassword = !visiblePassword"
      maxlength="30"
      tabindex="2"
      clearable
    >
      <template #right-icon>
        <Icon class="eye" :use="visiblePassword ? 'eyeopen' : 'eyeclose'" />
      </template>
    </Input>

    <div class="login-forgot__description">
      长度为8-20位字符，包含数字+字母+特殊字符
    </div>

    <div class="main-button">
      <Button round block @click="submit">下一步</Button>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { yup } from '@/utils'
import { device } from '@/store'
import * as api from '@/api'

import MyHeader from '@/components/MyHeader'
import Input from '@/components/Input'
import Button from '@/components/Button'
import NoticeBar from '@/components/NoticeBar'

import { LOGIN_PAGE } from '../config'
import { Toast } from 'vant'
import Icon from '@/components/Icon'

const emit = defineEmits<{
  (e: 'update:username', value: string): void
  (e: 'page', page: LOGIN_PAGE): void
  (e: 'sendSMS'): void
  (e: 'forget', value: any): void
}>()

const props = defineProps<{
  username: string
  countdown: number | string
}>()

onMounted(() => device.setTitle('忘记密码'))
onUnmounted(() => device.setTitle())

/* 修改密码 */
const form = ref({
  password: '',
  confirm_password: '',
})
const userInfo = ref({
  ticket: '',
  account_id: '',
  account_name: '',
})

async function submit() {
  const _form = unref(form)
  if (!_form.password) {
    Toast('请输入密码')
    return
  }

  const { error } = await api.user.submitEdit({
    ..._form,
    ...unref(userInfo),
  })

  api.errorProcessor({ error })
  Toast('修改密码成功')

  emit('page', LOGIN_PAGE.LOGIN)
}

/* 短信验证 */
const codeForm = ref({
  phone: computed({
    get() {
      return props.username
    },
    set(value: string) {
      emit('update:username', value)
    },
  }),
  code: '',
})

const codeRules = yup.object({
  phone: yup
    .string()
    .label('手机号')
    .required()
    .length(11, '请输入有效的手机号'),
  code: yup.string().label('验证码').required(),
})

const disabledNextButton = computed(() => {
  const { phone, code } = unref(codeForm)
  return !(phone && code && code.length >= 6)
})

async function submitCode() {
  const { data, error } = await api.user.codeSubmit(unref(codeForm))
  api.errorProcessor({ error })
  Object.assign(userInfo.value, {
    ticket: data.ticket,
    account_id: data.account_id,
    account_name: data.account_name,
  })
}

const visiblePassword = ref(false)
</script>

<style lang="less" scoped>
.login-forgot__description {
  font-size: rem(14);
  color: @c-grey;
  padding: rem(16);
}

.login-form__send-sms {
  background-color: rgba(@c-grey-light, 0.3);
  padding-left: rem(14);
  padding-right: rem(14);
}

.main-button {
  margin-top: rem(20);
  padding: 0 rem(20);
}

.eye {
  font-size: rem(18);
}
</style>
