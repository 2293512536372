<template>
  <Base :props="props.props">
    <div
      class="custom-layout-imagearea"
      :style="{ paddingBottom: `${image.paddingBottom}%` }"
    >
      <Image
        class="custom-layout-imagearea__image"
        :src="image.src"
        @click="navigateTo(image.weapp_href)"
      />

      <div
        class="custom-layout-imagearea__area"
        v-for="item in areaList"
        :style="{
          top: `${item.top}%`,
          left: `${item.left}%`,
          width: `${item.width}%`,
          height: `${item.height}%`,
        }"
        @click="navigateTo(item.weapp_href)"
      ></div>
    </div>
  </Base>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'

import Image from '@/components/Image'
import Base, { IBaseProps } from './Base.vue'

import { ossResize, useCustomRouter, ICustomRoute } from '@/utils'

export interface IImageAreaProps extends IBaseProps {
  warp: {
    image: string
    width: number
    height: number
    weapp_href?: ICustomRoute
    hotZone: {
      height: number
      width: number
      left: number
      top: number
      weapp_href?: ICustomRoute
    }[]
  }
}

const props = defineProps({
  props: {
    type: Object as PropType<IImageAreaProps>,
    default: {},
  },
})

const image = computed(() => {
  const v = props.props?.warp ?? {}
  return {
    ...v,
    paddingBottom: (v.height / v.width) * 100,
    src: v.image
      ? ossResize(v.image, {
          m: 'mfit',
          w: 750,
        })
      : v.image,
  }
})

const areaList = computed(() => {
  return props.props?.warp?.hotZone ?? []
})

const customRouter = useCustomRouter()
function navigateTo(to?: ICustomRoute) {
  customRouter.push(to)
}
</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomLayoutImageArea',
})
</script>

<style lang="less" scoped>
.custom-layout-imagearea {
  display: flex;
  font-size: 0;
  position: relative;
  width: 100%;
  cursor: pointer;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__area {
    position: absolute;
  }
}
</style>
